import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus"; 


const getVisitStatusData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_VISIT_STATUS, params);
        } else {
          response = await agent.post(API.GET_VISIT_STATUS, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
};

const addEditVisiteStatus = (params) => {
    return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
        const response = await agent.post(API.CREATE_VISIT_STATUS, params);
        dispatch(toggleNetworkRequestStatus(false));
        return response;
      } catch (error) {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      } 
    };
}

const addEditVisitePlan = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.CREATE_BEAT_PLAN, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const getBeatPlanData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_BEAT_PLAN, params);
      } else {
        response = await agent.post(API.GET_BEAT_PLAN, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getTodayBeatPlanData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_TODAY_BEAT_PLAN, params);
      } else {
        response = await agent.post(API.GET_TODAY_BEAT_PLAN, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getUserLocationsData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_USER_LOCATION, params);
      } else {
        response = await agent.post(API.GET_USER_LOCATION, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const setBeatPlanStatus = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API. SET_BEAT_PLAN_STATUS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const getUserBeatPlanData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_USER_BEAT_PLAN, params);
      } else {
        response = await agent.post(API.GET_USER_BEAT_PLAN, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getVisitPlanData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_VISIT_PLAN, params);
      } else {
        response = await agent.post(API.GET_VISIT_PLAN, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getBeaPlanReportData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.BEAT_PLAN_REPORT, params);
      } else {
        response = await agent.post(API.BEAT_PLAN_REPORT, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getBeatPlanReportClickOnCount = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_BEAT_PLAN_REPORT_CLICK_ON_COUNT, params);
      } else {
        response = await agent.post(API.GET_BEAT_PLAN_REPORT_CLICK_ON_COUNT, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getTatReportData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_TAT_REPORT, params);
      } else {
        response = await agent.post(API.GET_TAT_REPORT, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getTatReportByUserData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_TAT_REPORT_BY_USER, params);
      } else {
        response = await agent.post(API.GET_TAT_REPORT_BY_USER, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

const getHabileLoanApplicationsData = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_HABILE_LOAN_APPLICATIONS, params);
      } else {
        response = await agent.post(API.GET_HABILE_LOAN_APPLICATIONS, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
};

export default {
  addEditVisiteStatus,
  getVisitStatusData,
  addEditVisitePlan,
  getBeatPlanData,
  getTodayBeatPlanData,
  getUserLocationsData,
  setBeatPlanStatus,
  getUserBeatPlanData,
  getVisitPlanData,
  getBeaPlanReportData,
  getBeatPlanReportClickOnCount,
  getTatReportData,
  getTatReportByUserData,
  getHabileLoanApplicationsData,
}