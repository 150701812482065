import React, { useState, useEffect, memo } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Link as RouterLink, useNavigate, BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import { toast } from "react-toastify";
// @mui  
 import { encryptData } from '../../utils/cryptoUtils';
import * as leadActions from "../../actions/leadActions";

const LeadQuestionnaire = ({ questions, checklistInfo, props, setOpen, fetchLeads, updateParentState, userRoleType, leadStatusFlag }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const { leadInfo, leadData, actions } = props;
    const [currentChecklistId, setCurrentChecklistId] = useState(checklistInfo.id);
    const [leadId, setLeadId] = useState(checklistInfo.lead_id);
    const [questionnaireData, setQuestionnaireData] = useState(questions);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answers, setAnswers] = useState(Array(questionnaireData.length).fill(''));

    console.log("leadStatusFlag : ", leadStatusFlag);
    

    useEffect(() => {
        // set answers of each questions in array

        console.log("questions, ", questions);

        const userAnswersArray = questions.map((item) => (item.userAnswers ? item.userAnswers[0] : ''));
        setAnswers(userAnswersArray);
    }, [actions, leadInfo]);

    const handleNext = () => {
        handleSave('nextBtnClickHandler');
        if (currentIndex < questionnaireData.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleAnswer = (questionIndex, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[questionIndex] = value;
        // console.log("updatedAnswers :", updatedAnswers);
        setAnswers(updatedAnswers);
    };

    const handleSave = (actionHandler) => {
        //    console.log("Selected Answers:", answers);

        // Save answers to the database (convert to JSON if needed)
        const answersJSON = JSON.stringify(answers);
        if (currentChecklistId === '') {
            toast.error("Checklist id in null   ", {
                position: "top-right",
            });
            return;
        }

        const params = { 'id': currentChecklistId, 'answers': answersJSON, 'userRoleType':userRoleType, "lead_id":leadId };
        console.log("leadId :", leadId);

        const requestData = encryptData(params);
        const response = actions.updateLeadChecklistQuestionAnswer(requestData);

        response.then((result) => {
            // console.log("Promise resolved - add/edit question answer form:", result); 
            if (result.data.success === 1) {
                toast.success(result.data.message, {
                    position: "top-right",
                });
                // setShowLeadDetails(false);
                setTimeout(() => {
                    if (actionHandler !== 'nextBtnClickHandler') {
                        updateParentState(false);
                        fetchLeads();
                        setOpen(false);
                        backToChecklistsHandler();
                    }
                }, 1000);
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
            //  console.error("Promise rejected - add/edit question answer form:", error);
            toast.error(error, {
                position: "top-right",
            });
        });
    };

    const currentQuestion = questionnaireData[currentIndex];
    const backToChecklistsHandler = () => {
        setOpen(false);
        updateParentState(false);
    }

    return (
        <div>
            <div className='d-flex flex-md-row flex-column align-md-items-center align-items-start justify-content-between mt-3'>
                <h5 className='fw-bold mb-3 order-md-1 mb-0 order-2'>Checklist : {checklistInfo.check_list_name}</h5>
                <button onClick={backToChecklistsHandler} onKeyDown={backToChecklistsHandler} className='btn fw-bold mb-3 bg-info text-white py-1 px-2 rounded order-md-2 order-1'> Back to List</button>
            </div>

            <div>
                {questionnaireData.map((currentQuestion, questionIndex) => (
                    <div key={questionIndex} className='question-wrap'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <h6 className='d-flex lh-base mb-2'><span className='h6 lh-base fw-bold opacity-100 mb-0'>Q{questionIndex + 1}.&nbsp;</span> {currentQuestion.questionText}</h6>
                                </div>
                            </div>
                            <div className='col-md-12 questions'>
                                <span className='mb-1'>Answer Option</span>
                                <div className='row q-row'>
                                        {currentQuestion.answerType === "checkbox" ? (
                                            currentQuestion.answerOptions.map((option, optionIndex) => (
                                                <div className="col-md-3" key={optionIndex}>
                                                    <div className="question-options mb-md-0 mb-2">
                                                        <label htmlFor="check" className='d-flex align-items-center'>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            value={option}
                                                            checked={(answers[questionIndex] || []).includes(option)}
                                                            onChange={(e) => {
                                                                const selectedOptions = answers[questionIndex] || [];
                                                                if (e.target.checked) {
                                                                    selectedOptions.push(option);
                                                                } else {
                                                                    const indexToRemove = selectedOptions.indexOf(option);
                                                                    if (indexToRemove !== -1) {
                                                                        selectedOptions.splice(indexToRemove, 1);
                                                                    }
                                                                }
                                                                handleAnswer(questionIndex, selectedOptions);
                                                            }}
                                                        />
                                                        <span className='ps-2 mb-0'>{option}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            ))
                                        ) : currentQuestion.answerType === "input" ? (
                                            <div className='px-2'>
                                                <input
                                                type="text"
                                                className="form-control"
                                                value={answers[questionIndex]}
                                                onChange={(e) => handleAnswer(questionIndex, e.target.value)}
                                            />
                                            </div>
                                        ) : currentQuestion.answerType === "radio" ? (
                                            currentQuestion.answerOptions.map((option, optionIndex) => (
                                                <div className='col-md-3' key={optionIndex}>
                                                    <div className="question-options mb-md-0 mb-2">
                                                        <label htmlFor="radio" className='d-flex align-items-center'>
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={option}
                                                                checked={answers[questionIndex] === option}
                                                                onChange={() => handleAnswer(questionIndex, option)}
                                                            />
                                                            <span className='ps-2 mb-0'>{option}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className='px-2'>
                                                <textarea
                                                    value={answers[questionIndex]}
                                                    onChange={(e) => handleAnswer(questionIndex, e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className='col-12 text-end question-btns-'>
                    <button disabled={leadStatusFlag === true} className="btn btn-success" onClick={handleSave}>Complete</button>
                </div>
            </div>

            
            
        </div>
    );
}

function mapStateToProps(state) {
    return {
        leadsData: state.leadsData,
        leadInfo: state.leadInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default), dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadQuestionnaire);