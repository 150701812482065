import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import * as leadActions from "../../actions/leadActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import { setToaster, showToaster } from "../../utils/helpers";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LeadCommunication = (props) => {
    const { actions, allInteractions, allInteractionTypeData,fetchInteractions } = props;
    const [leadId, setLeadId] = useState('');
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [interactionType, setInteractionType] = useState('');
    const [interactionDate, setInteractionDate] = useState(new Date());
    const [interactionNextDate, setInteractionNextDate] = useState(new Date());
    const [notes, setNotes] = useState('');
    const [isNextInteractionEnable, setIsNextInteractionEnable] = useState(false);
    const [iNoBtnLabel, setINoBtnLabel] = useState('Call did not happen');
   // const [iYesBtnLabel, setIYesBtnLabel] = useState('Call happened');
    const [iYesBtnLabel, setIYesBtnLabel] = useState('Update Status');
    const [happen, setHappen] = useState('0');
    const [submitting, setSubmitting] = useState(false);
    const [firstIntercationType, setfFirstIntercationType] = useState('');
    const [allLeadStatusData, setAllLeadStatusData] = useState([]);
    const [allSubLeadStatus, setAllSubLeadStatus] = useState([]);
    const [selectedLeadStatus, setSelectedLeadStatus] = useState('');
    const [selectedSubLeadStatus, setSelectedSubLeadStatus] = useState([]);
    const [numbersArray, setNumbersArray] = useState([]);
    const isSelected = true;


    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS,'brief' : 'lead_communication' };
        const requestData = encryptData(obj); 
        const response = actions.leadStatusData(requestData);

        response.then((result) => {
            // console.log("Promise resolved -  lead status data :", result); 
            if (result.data.success === 1) {
                setAllLeadStatusData(true);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                const filteredResultArr = resultArr.filter((item) => item.status === "1");
                const sortedResultArr = filteredResultArr.sort((a, b) =>
                    a.lead_status.localeCompare(b.lead_status)
                );
                setAllLeadStatusData(sortedResultArr);
            }
        }).catch((error) => {
           //  console.error("Promise rejected -  lead status data :", error); 
        }); 

    }, [setAllLeadStatusData]);

    useEffect(() => {
        console.log("LeadCommunication =  ", props);
        setLeadId(props.leadId); 
        if(props.allInteractions.length > 0){ 

            const dateOfInteraction = props.allInteractions[0].date_of_interaction;
            const dateOfInteractionFormattedDate = new Date(dateOfInteraction).toLocaleDateString(); 
            
            const nextInteractionDate = props.allInteractions[0].date_of_interaction;
            const nextInteractionDateFormattedDate = new Date(nextInteractionDate).toLocaleDateString();
          //  setINoBtnLabel(`${props.allInteractions[0].lead_status} did not happen`);
          //  setIYesBtnLabel(`${props.allInteractions[0].lead_status} happened`);
            setfFirstIntercationType(props.allInteractions[0].type_of_interaction);
            setValue("lead_communication_id",props.allInteractions[0].id);
         //   setValue("interaction_type",props.allInteractions[0].type_of_interaction);
             setValue("date_of_interaction", dateOfInteractionFormattedDate);
            if (dateOfInteraction !== "0000-00-00 00:00:00") {
                const date = new Date(dateOfInteraction);
                if (!Number.isNaN(date.getTime())) {
                  setInteractionDate(date);
                }
              }
           // setValue("interaction_note", props.allInteractions[0].interaction_note);
           // setValue("next_interaction_type", props.allInteractions[0].next_interaction_type);
           // setValue("next_interaction_date", nextInteractionDateFormattedDate);
            
        } else{
           // setValue("interaction_type",1);
           setValue("interaction_type",'');
        }

    }, [setValue,leadId,allInteractions,setINoBtnLabel,setIYesBtnLabel]);

    
    const handleYesNoBtnChange =(action) => {
       // setIsNextInteractionEnable(true);
       setIsNextInteractionEnable(false);
        if(action === 'yes'){
            setHappen('1');
        }else{
            setHappen('0');
        }
         
    }
    const handleInteractionDateChange = (date) => {
        setInteractionDate(date);
    };

    const handleInteractionNextDateChange = (date) => {
         setInteractionNextDate(date);
    };

    const handleSubmitCommunication = (data) => {  
        setHappen('1');
         console.log("Form data = ",data);  
         if(leadId === ''){
            toast.error("Lead id is blank.", {
                position: "top-right",
              }); 
              setSubmitting(false); 
        }else if (data.interaction_type.trim() ===''){

            toast.error("Please select status.", {
                position: "top-right",
              }); 
              setSubmitting(false);  
      /*  } 
        else if(selectedLeadStatus.trim() ===''){
             toast.error("Please select next interaction type.", {
              position: "top-right",
            }); 
            setSubmitting(false); 
        } else if(interactionNextDate ===''){
            toast.error("Please select next interaction date.", {
             position: "top-right",
           }); 
           setSubmitting(false);  */ 
       } else if(allSubLeadStatus.length > 0 && selectedSubLeadStatus.length === 0){
            toast.error("Please select sub interaction type.", {
                position: "top-right",
            }); 
            setSubmitting(false); 
       }else if(data.interaction_note.trim() ===''){
            toast.error("Notes is required.", {
                position: "top-right",
            }); 
            setSubmitting(false); 
   }else {
        
            const subLeadStatus = JSON.stringify(selectedSubLeadStatus);

           /* const params = { 'lead_communication_id': data.lead_communication_id, 'lead_id': leadId, 'type_of_interaction': data.interaction_type, 'date_of_interaction': interactionDate, 'interaction_note': data.interaction_note, 'next_interaction_type': data.next_interaction_type, 'next_interaction_date': interactionNextDate,'happen' : happen, 'sub_lead_status' : subLeadStatus }; */

           const params = { 'lead_communication_id': '', 'lead_id': leadId, 'type_of_interaction': data.interaction_type, 'date_of_interaction': interactionDate, 'interaction_note': data.interaction_note, 'next_interaction_type': data.next_interaction_type, 'next_interaction_date': interactionNextDate,'happen' : happen, 'sub_lead_status' : subLeadStatus };

            const requestData = encryptData(params);
            const response =  actions.createLeadCommunication(requestData, navigate);

            response.then((result) => {
                // alert(result.data.success);
                // console.log("Promise resolved create intercation : ", result);
                if (result.data.success === 1) {
                    // Handle success case
                    props.updateParentState(false);
                    fetchInteractions();
                    setIsNextInteractionEnable(false);
                    setInteractionNextDate('');
                    setValue("interaction_note", '');
                    setValue("next_interaction_type", '');
                    setValue("next_interaction_date", '');
                    setValue("interaction_type",'');

                    setAllSubLeadStatus([])
                    setSelectedLeadStatus('');
                    setSelectedSubLeadStatus([]);
                    setNumbersArray([]);

                    toast.success(result.data.message, {
                        position: "top-right",
                      }); 
                } else {
                    // Handle error case  
                    toast.error('An error occurred. Try again!', {
                        position: "top-right",
                      }); 
                }  
            
            }).catch((error) => { 
                toast.error(error, {
                    position: "top-right",
                  }); 
            });
        }
        
    };

    const handleChangeIntraction = (event, actionName = '') => {
        const selectedValue = event.target.value;
        setSelectedSubLeadStatus([]);
        setNumbersArray([]);

        if (selectedValue !== "") {
            setSelectedLeadStatus(selectedValue);

            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'lead_status_id': selectedValue };
                const requestData = encryptData(obj); 
                const response = actions.leadStatusData(requestData);
        
                response.then((result) => {
                    // console.log("Promise resolved -  lead status data :", result); 
                    if (result.data.success === 1) {
                        const results = result.data.data.data;
                        const resultArr = Object.keys(results).map((key) => results[key]);
                        const filteredResultArr = resultArr.filter((item) => item.status === "1");
                        const sortedResultArr = filteredResultArr.sort((a, b) =>
                            a.lead_status.localeCompare(b.lead_status)
                        );
                        const presentSelectedLeadStatus = sortedResultArr[0].selected_lead_status;
                        if(presentSelectedLeadStatus !== ""){
                            setAllSubLeadStatus(presentSelectedLeadStatus);
                        } else {
                            setAllSubLeadStatus([]);
                        }
                        setSelectedLeadStatus(selectedValue);
                    }
                }).catch((error) => {
                   //  console.error("Promise rejected -  lead status data :", error); 
                });
        } else {
            setAllSubLeadStatus([]);
        }
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-7'>
                    <h5 className='fw-bold mb-3'>Activity</h5>
                    <form onSubmit={handleSubmit(handleSubmitCommunication)} className="row">

                        <input hidden type='text' className='form-control' id='lead_communication_id' name='lead_communication_id' {...register('lead_communication_id')} />

                        <div className='col-md-6 d-none'>&nbsp;</div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor="interaction-type">
                                    { /* <span>Type of Interaction</span> */ }
                                    <span>Status</span>
                                   { /* <select id='interaction_type' name='interaction_type' className="form-select" {...register('interaction_type')} disabled={firstIntercationType !==''}> */ }
                                   <select id='interaction_type' name='interaction_type' className="form-select" {...register('interaction_type')}  
                                    onChange={handleChangeIntraction}
                                   >
                                        <option selected>Select Interaction Type</option>
                                        {allLeadStatusData.length > 0 && allLeadStatusData.map((option) => (
                                            <option value={option.id}>
                                                {option.lead_status}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                        </div>

                        
                        {allSubLeadStatus.length > 0 && (
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="sub_lead_status">
                                            <span>Sub Interaction Type</span>

                                            <div className="text-dark">
                                                <Multiselect
                                                    id="lead_profile"
                                                    options={allSubLeadStatus.map((option) => ({
                                                        key: option.key,
                                                        value: option.value,
                                                    }))}
                                                    selectedValues={isSelected ? numbersArray : []} // Use isSelected to conditionally set selected values
                                                    displayValue="value"
                                                    onSelect={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedSubLeadStatus(selectedValues);
                                                    }}
                                                    onRemove={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedSubLeadStatus(selectedValues);
                                                    }}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            )}
                            
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label htmlFor="inteaction-notes">
                                        <span>Interaction Notes</span>
                                        <textarea id='interaction_note' name='interaction_note' className='form-control' {...register('interaction_note')}>&nbsp;</textarea>
                                    </label>
                                </div>
                            </div>        
                        <div className='col-md-6 d-none'>
                            <div className='form-group'>
                                <label htmlFor="date-interaction">
                                    <span>Date of Interaction</span>
                                  { /* <input type='date' className='form-control' id='date_of_interaction' name='date_of_interaction' {...register('date_of_interaction')} /> */ }

                                  <DatePicker selected={interactionDate} className='form-control'  onChange={handleInteractionDateChange} showTimeSelect dateFormat="Pp" id='date_of_interaction' name='date_of_interaction'  readOnly/>

                                </label> 
                                
                            </div> 
                        </div>

                        <div className='col-md-6 d-none'>
                            <div className='form-group'>
                                    <button type="button" 
                                    className= {`btn btn-danger iNoBtn w-100 btn-danger-bg ${happen === '0' && isNextInteractionEnable ? 'active' : ''}`} 
                                    id="did_not_happen" onClick={()=> handleYesNoBtnChange('no')}>{iNoBtnLabel}</button>
                            </div>
                        </div> 
                        <div className='col-md-6'>
                            <div className='form-group'>                   
                                   { /* <button type="button" 
                                    className= {`btn btn-success iYesBtn w-100 btn-success-bg ${happen === '1' && isNextInteractionEnable ? 'active' : ''}`}  
                                        
                                    id="happen_btn" onClick={()=> handleYesNoBtnChange('yes')}>{iYesBtnLabel}</button> */ }

                         <button className= {`btn btn-success iYesBtn w-100 btn-success-bg ${happen === '1' && isNextInteractionEnable ? 'active' : ''}`}  
                                        
                                    id="happen_btn" >{iYesBtnLabel}</button>
                            </div>
                        </div>   

                        <div className={isNextInteractionEnable ? "row" :" row hide"}  id="next_action_div"> 
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="next_interaction_type">
                                        <span>Next Interaction Type</span>
                                        <select id='next_interaction_type' className="form-select" name='next_interaction_type' {...register('next_interaction_type')} onChange={handleChangeIntraction} value={selectedLeadStatus}>
                                            <option value=''>Select Next Interaction Type</option>
                                            {allLeadStatusData.length > 0 && allLeadStatusData.map((option) => (
                                                <option value={option.id}>
                                                    {option.lead_status}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            
                            {/*                
                            {allSubLeadStatus.length > 0 && (
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor="sub_lead_status">
                                            <span>Sub Interaction Type</span>

                                            <div className="text-dark">
                                                <Multiselect
                                                    id="lead_profile"
                                                    options={allSubLeadStatus.map((option) => ({
                                                        key: option.key,
                                                        value: option.value,
                                                    }))}
                                                    selectedValues={isSelected ? numbersArray : []} // Use isSelected to conditionally set selected values
                                                    displayValue="value"
                                                    onSelect={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedSubLeadStatus(selectedValues);
                                                    }}
                                                    onRemove={(selectedItems) => {
                                                        const selectedValues = selectedItems.map(item => item.key);
                                                        setSelectedSubLeadStatus(selectedValues);
                                                    }}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            )}
                                */}
                            
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor="next_interaction_date">
                                        <span>Next Interaction Date</span>
                                        
                                        { /* <input type='date' id='next_interaction_date' name='next_interaction_date' className='form-control' {...register('next_interaction_date')} /> */ }

                                            <DatePicker selected={interactionNextDate} className='form-control'  
                                            onChange={handleInteractionNextDateChange} 
                                            showTimeSelect 
                                            dateFormat="Pp" 
                                            minDate={new Date()} 
                                            id='next_interaction_date' name='next_interaction_date' />
                                    </label>
                                </div>
                            </div>

                          {/*  <div className='col-md-12'>
                                <div className='form-group'>
                                    <label htmlFor="inteaction-notes">
                                        <span>Interaction Notes</span>
                                        <textarea id='interaction_note' name='interaction_note' className='form-control' {...register('interaction_note')}>&nbsp;</textarea>
                                    </label>
                                </div>
                            </div>
                            */ }

                            <div className='col-md-12'>
                                <div className='form-group mb-2'>
                                    <button className="btn btn-primary"> Submit </button>
                                </div>
                            </div>
                        </div>

                      
                                            
                    </form>
                </div>


                <div className='col-md-5'>
                    <h5 className='fw-bold mb-3 mt-md-0 mt-3 ms-md-3'>All Interactions</h5>
                    <div className="history-tl-container rounded ms-md-3">
                        <ul className="tl">
                            {allInteractions.length > 0 && allInteractions.map(idata => (
                                <li className="tl-item">
                                    {idata.happen === '1' ? (
                                        <div className="timestamp yes d-none">Yes<i className="bi bi-caret-right-fill">&nbsp;</i></div>
                                    ) : (
                                        idata.happen === '0' ? (<div className="timestamp no d-none">No<i className="bi bi-caret-right-fill">&nbsp;</i></div>) : (<></>)
                                    )}

                                    <div className="item-title">{idata.lead_status}</div>
                                    <div className="item-date">{idata.DateOfInteraction}</div>
                                    <div className="item-user">{idata.createdByName}</div>
                                    <div className="item-user">{idata.subLeadStatus}</div>
                                    <div className="item-detail">{idata.interaction_note}</div>
                                </li>
                            ))}
                            {allInteractions.length < 1 ? (
                                <li className="tl-item">
                                    <div className="item-title">No interaction</div>
                                </li>
                            ) : (
                                <></>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}


function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        leadStatusData : state.leadStatusData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(leadActions.default, userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadCommunication);