
import react, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Link as RouterLink, NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Typography } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as guarantorActions from "../../actions/guarantorActions";
import * as leadActions from "../../actions/leadActions";


const Guarantor = (props) => {
  const { id } = useParams();
  const { leadId, guarantorId } = useParams();
  const location = useLocation();
  const { actions, addEditGuarantor, guarantorData } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [propLeadId, setPropLeadId] = useState('');
  const [showListDetails, setShowListDetails] = useState(false);
  const [allApplicantData, setAllApplicantData] = useState([]);
  const [getLeadId, setLeadId] = useState('');
  const [getGuarantorId, setGuarantorId] = useState('');
  const [getAllGuarantorData, setAllGuarantorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const formRef = useRef(null);
  
  const [leadStatusFlag, setLeadStatusFlag] = useState(props.leadStatusFlag);

  const [formValues, setFormValues] = useState({
    created_by: '',
    status: '',
    mobile: '',
    email: '',
    first_name: '',
    last_name: '',
    dob: '',
    pan: '',
    adhar: '',
    full_address: '',
    city: '',
    state: '',
    pin: ''
  });

  useEffect(() => {
    if (id !== undefined) {
      setPropLeadId(atob(id));
      const decodedId = atob(id);
      // alert( `${propLeadId} - ${decodedId}`); 
      setLeadId(propLeadId);
      const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id": atob(id) };
      const requestData = encryptData(obj);
      const response = actions.guarantorData(requestData);

      response.then((result) => {
        // console.log("Promise resolved -  source data:", result); 
        if (result.data.success === 1) {
          const results = result.data.data.data;
          const resultArr = Object.keys(results).map((key) => results[key]);
          setAllGuarantorData(resultArr);
        }
      }).catch((error) => {
        //  console.error("Promise rejected -  source data :", error); 
      });
    }
  }, [setAllGuarantorData]);

  const onSubmit = (data) => {
    // console.log("Co-Appicant =  ", props);
    const leadId = props.leadId;
    setLeadId(leadId);
    let params = {
      "lead_id": leadId,
      "first_name": formValues.first_name,
      "last_name": formValues.last_name,
      "dob": formValues.dob,
      "mobile": formValues.mobile,
      "email": formValues.email,
      "pan": formValues.pan,
      "adhar": formValues.adhar,
      "full_address": formValues.full_address,
      "city": formValues.city,
      "state": formValues.state,
      "pin": formValues.pin
    };

    if (getGuarantorId !== '') {
      params = {
        "id": getGuarantorId,
        "lead_id": leadId,
        "first_name": formValues.first_name,
        "last_name": formValues.last_name,
        "dob": formValues.dob,
        "mobile": formValues.mobile,
        "email": formValues.email,
        "pan": formValues.pan,
        "adhar": formValues.adhar,
        "full_address": formValues.full_address,
        "city": formValues.city,
        "state": formValues.state,
        "pin": formValues.pin
      };
    }

    const requestData = encryptData(params);
    const response = actions.addEditGuarantor(requestData, navigate);

    response.then((result) => {
      if (result.data.success === 1) {
        toast.success(result.data.message, {
          position: "top-right",
        });
        const guarantorId = result.data.id;
        setGuarantorId("");
        setFormValues({
          created_by: '',
          status: '',
          mobile: '',
          email: '',
          first_name: '',
          last_name: '',
          dob: '',
          pan: '',
          adhar: '',
          full_address: '',
          city: '',
          state: '',
          pin: '',
        });
        
        setLeadId(propLeadId);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id": propLeadId };
        const requestData = encryptData(obj);
        const response = actions.guarantorData(requestData);
        response.then((result) => {
          // console.log("Promise resolved -  source data:", result); 
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            setAllGuarantorData(resultArr);
            props.updateParentState(false);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  source data :", error); 
        });
      } else {
        toast.error(result.data.message, {
          position: "top-right",
        });
      }
    }).catch((error) => {
      toast.error(error, {
        position: "top-right",
      });
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });

  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const filteredItems = getAllGuarantorData.filter(item => {
    const firstName = item.first_name?.toLowerCase() || '';
    const formattedID = item.formatetdID?.toLowerCase() || '';
    const mobile = item.mobile?.toLowerCase() || '';
    const email = item.email?.toLowerCase() || '';
    const pan = item.pan_number?.toLowerCase() || '';
    const adhar = item.aadhaar_number?.toLowerCase() || '';

    return (
      firstName.includes(searchQuery.toLowerCase()) ||
      formattedID.includes(searchQuery.toLowerCase()) ||
      mobile.includes(searchQuery.toLowerCase()) ||
      email.includes(searchQuery.toLowerCase()) ||
      pan.includes(searchQuery.toLowerCase()) ||
      adhar.includes(searchQuery.toLowerCase())
    );
  }
  );

  const startIndex = (currentPage - 1) * config.ITEMS_PER_PAGE;
  const endIndex = startIndex + config.ITEMS_PER_PAGE;
  const paginatedItems = filteredItems.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredItems.length / config.ITEMS_PER_PAGE);

  const onRowClickAction = (itemObj, btnAction) => {
    if (btnAction === 'edit') {

      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      setGuarantorId(itemObj.id);

      let panNumber = '';
      if (itemObj.pan_number !== '' && itemObj.pan_number !== null) {
          panNumber = itemObj.pan_number.replace(/"/g, '');
      }

      let adharNumber = '';
      if (itemObj.aadhaar_number !== '' && itemObj.aadhaar_number !== null) {
          adharNumber = itemObj.aadhaar_number.replace(/"/g, '');
      }

      const initialFormValues = {
        status: itemObj.status,
        mobile: itemObj.mobile,
        email: itemObj.email,
        first_name: itemObj.first_name,
        last_name: itemObj.last_name,
        dob: itemObj.dob,
        pan: panNumber,
        adhar: adharNumber,
        full_address: itemObj.full_address,
        city: itemObj.city,
        state: itemObj.state,
        pin: itemObj.pin,
      };

      setFormValues(initialFormValues);
    } else if (btnAction === "delete") {
      const leadId = props.leadId;

      const obj = { "lead_id": leadId, "id": itemObj.id, "basic_info_type": "guarantor" };
      const requestData = encryptData(obj);
      const response = actions.deleteLeadBasicInfo(requestData);
      response.then((result) => {
        // console.log("Promise resolved - deleteLeadBasicInfo result:", result);
        if (result.data.success === 1) {
          toast.success(result.data.data.message, {
            position: "top-right",
          });
          setLeadId(propLeadId);
          const obj = { 'start': '0', 'length': config.MAX_RECORDS, "lead_id": propLeadId };
          const requestData = encryptData(obj);
          const response = actions.guarantorData(requestData);
          response.then((result) => {
            // console.log("Promise resolved -  source data:", result); 
            if (result.data.success === 1) {
              const results = result.data.data.data;
              const resultArr = Object.keys(results).map((key) => results[key]);
              setAllGuarantorData(resultArr);
              props.updateParentState(false);
            }
          }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
          });
  
        } else {
          toast.error(result.data.data.message, {
            position: "top-right",
          });
        }
      }).catch((error) => {
        toast.error(error, {
          position: "top-right",
        });
      });
    }
  }

  const handleNewButton = () => {
      setGuarantorId("");
      setFormValues({
        created_by: '',
        status: '',
        mobile: '',
        email: '',
        first_name: '',
        last_name: '',
        dob: '',
        pan: '',
        adhar: '',
        full_address: '',
        city: '',
        state: '',
        pin: '',
      });
  }

  return (
    <>
      <h5 className="fw-bold">Guarantor</h5>


      <div id="main-wrap" className='mt-4'>
        <div className='row justify-content-between'>
          <div className='col-md-8'>
            <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
              List of Guarantors
            </Typography>
          </div>
          <div className='col-md-4 pe-0 ps-lg-5'>
            <div className="form-group">
              <label htmlFor="search">
                {/* Search: */}
                <input readOnly={props.leadStatusFlag === true}
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  id="search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="table-wrap">
          <table className="table table-hover">
            <thead>
              <tr>
                {/* <th>Guarantor ID</th> */}
                <th>UID</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Email Address</th>
                <th>Pan Number</th>
                {!props.showActionLeadInfo && (
                  <th>Action</th>
                )}
              </tr>
            </thead>

            {paginatedItems.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center' }}>
                    <h5>No Guarantor</h5>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {paginatedItems.map((item, index) => (
                  <tr key={index}>
                    {/* <td>{item.formatetdID}</td> */}
                    <td>{item.uid}</td>
                    <td>{item.first_name}</td>
                    <td>{item.mobile}</td>
                    <td>{item.email}</td>
                    <td>{item.pan_number}</td>
                    {!props.showActionLeadInfo && (
                      <td>
                          <div className="btn-group dropstart">
                              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i className="bi bi-three-dots-vertical">&nbsp;</i>
                              </button>
                              <ul className="dropdown-menu">
                                  <li>
                                    <RouterLink onClick={() => onRowClickAction(item, 'edit')} ><i className="bi bi-pen">&nbsp;</i>Edit</RouterLink>
                                  </li>

                                  <li>
                                      <RouterLink onClick={() => onRowClickAction(item, 'delete')} ><i className="bi bi-trash">&nbsp;</i>Delete</RouterLink>
                                  </li>
                              </ul>
                          </div>
                      </td>
                    )}
                    
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>

        <div>
          <nav className='mb-1'>
            <ul className="pagination pagination-md justify-content-end">
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                <li className="page-item">
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    disabled={currentPage === page}
                    className="page-link"
                  >
                    {page}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      <div id="main-wrap" className='mt-4'>
        <div className='row justify-content-between'>

          <div className="row">
            <div className='col-md-9'>
              <h5> {getGuarantorId === '' ? 'Add' : "Edit"} Guarantor</h5>
            </div>
            {getGuarantorId !== '' && (
              <div className='col-md-3'>
                <div className='form-group text-end mb-2'>
                  <button className="btn btn-primary" onClick={() => handleNewButton()}>Add New</button>
                </div>
              </div>
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" ref={formRef}>
            <div className="row guarantor" id="guarantor-1">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="first_name">
                    <span>First Name <a style={{ color: "red" }}>*</a></span>
                    <input readOnly={props.leadStatusFlag === true} type="text" id="first_name" name="first_name" className="form-control"
                      {...register('first_name')}
                      value={formValues.first_name}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="last_name">
                    <span>Last Name <a style={{ color: "red" }}>*</a></span>
                    <input readOnly={props.leadStatusFlag === true} type="text" id="last_name" name="last_name" className="form-control"
                      {...register('last_name')}
                      value={formValues.last_name}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="dob">
                    <span>Date Of Birth <a style={{ color: "red" }}>*</a></span>
                    <input readOnly={props.leadStatusFlag === true} type="date" id="dob" name="dob" className="form-control"
                      {...register('dob')}
                      value={formValues.dob}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="mobile">
                    <span>Mobile Number <a style={{ color: "red" }}>*</a></span>
                    <input readOnly={props.leadStatusFlag === true} type="tel" id="mobile" name="mobile" className="form-control"
                      {...register('mobile')}
                      value={formValues.mobile}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">
                    <span>Email Address</span>
                    <input readOnly={props.leadStatusFlag === true} type="email" id="email" name="email" className="form-control"
                      {...register('email')}
                      value={formValues.email}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="pan">
                    <span>Pan Number </span>
                    <input readOnly={props.leadStatusFlag === true} type="text" id="pan" name="pan" className="form-control"
                      {...register('pan')}
                      value={formValues.pan}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="adhar">
                    <span>Aadhaar Number </span>
                    <input readOnly={props.leadStatusFlag === true} type="text" id="adhar" name="adhar" className="form-control"
                      {...register('adhar')}
                      value={formValues.adhar}
                      onChange={handleChange}
                      // disabled={getGuarantorId !== '' && formValues.adhar !== ""}
                    />
                  </label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="full_address">
                    <span>Address</span>
                    <input readOnly={props.leadStatusFlag === true}
                      type='text'
                      id='full_address'
                      name='full_address'
                      className='form-control'
                      {...register('full_address')}
                      value={formValues.full_address}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="city">
                    <span>City</span>
                    <input readOnly={props.leadStatusFlag === true}
                      type='text'
                      id='city'
                      name='city'
                      className='form-control'
                      {...register('city')}
                      value={formValues.city}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>

              <div className="col-md-6" >
                <div className='form-group'>
                  <label htmlFor="lead-profile">
                    <span>State</span>
                    <select disabled={props.leadStatusFlag === true}
                      id='state'
                      name='state'
                      className="form-select"
                      {...register('state')}
                      value={formValues.state}
                      onChange={handleChange}
                    >
                      <option value=''>Select State</option>
                      {props.allStates.length > 0 && props.allStates.map((option) => (
                        <option value={option.state_code}>
                          {option.state_name}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>


              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="pin">
                    <span>Pin</span>
                    <input readOnly={props.leadStatusFlag === true}
                      type='text'
                      id='pin'
                      name='pin'
                      className='form-control'
                      {...register('pin')}
                      value={formValues.pin}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-0">
                  <button disabled={props.leadStatusFlag === true} className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>


    </>
  );
}


function mapStateToProps(state) {
  return {
    getUserModuleData: state.getUserModuleData,
    addEditGuarantor: state.addEditGuarantor,
    guarantorData: state.guarantorData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(guarantorActions.default, leadActions.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Guarantor);
