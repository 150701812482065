import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";

import AccessDenied from '../common/AccessDenied';
import * as beatPlanActions from "../../actions/beatPlanActions";
import * as campaignsActions from "../../actions/campaignsActions";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const VisitPlanPage = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allBeatPlans, setAllBeatPlans] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);

    const [beatPlanLocation, setBeatPlanLocation] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [selectedVisitType, setSelectedVisitType] = useState([]);
    const [totalDistance, setTotalDistance] = useState(0);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Beat Plan')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;

                const extractedData = results.map(item => {
                    return { id: item.user_id, name: item.name };
                });
                const resultArr = Object.values(extractedData);
                setAllLoggedinUserChild(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllLoggedinUserChild]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.campaignData(requestData, false);
        response.then((result) => {
        //   console.log("Promise resolved -  campaignData:", result);
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.campaign_name.localeCompare(b.campaign_name)
            );
            const extractedData = sortedResultArr.map(item => {
              return { id: item.id, name: item.campaign_name };
            });
            const resultArrObj = Object.values(extractedData);
            setAllCampaigns(resultArrObj);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  campaignData :", error); 
        });
    
    }, [setAllCampaigns]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        setPreloaderStatus(true);

        const today = new Date();
        const todayFormatted = formatDate(today);
       // setTotalDistance(0); 
        setStartDate(todayFormatted);
        setEndDate(todayFormatted);

        setValue("start_date", todayFormatted);
        setValue("end_date", todayFormatted);

        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted };
        const requestData = encryptData(params);
        const response = actions.getVisitPlanData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getVisitPlanData:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.beat_plan_data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllBeatPlans(resultArr);
                const totalDistance = resultArr.reduce((total, item) => {
                    return total + parseFloat(item.distance);
                 }, 0);
                setTotalDistance(totalDistance.toFixed(4));
            } else {
                setPreloaderStatus(false);
                setAllBeatPlans([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getVisitPlanData:", error);
        });
    }, [setAllBeatPlans]);

    const onSubmit = (data) => {
        setPreloaderStatus(true);
        const params = {
            'start': '0',
            'length': config.MAX_RECORDS,
            'assign_to': selectedUsers,
            'start_date': data.start_date,
            'end_date': data.end_date,
            "visit_type":selectedVisitType
        };
        setTotalDistance(0);   
        setStartDate(data.start_date);
        setEndDate(data.end_date);

        const requestData = encryptData(params);
        const response = actions.getVisitPlanData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.beat_plan_data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllBeatPlans(resultArr);
                const totalDistance = resultArr.reduce((total, item) => {
                     return total + parseFloat(item.distance);
                  }, 0);
                setTotalDistance(totalDistance.toFixed(4));
            } else {
                setPreloaderStatus(false);
                setAllBeatPlans([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  lead list:", error);
        });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allBeatPlans.filter(item => {
        const companyName = item.companyName?.toLowerCase() || '';
        const assignedUser = item.assignedUser?.toLowerCase() || '';
        const createdByName = item.createdByName?.toLowerCase() || '';
        return (
            companyName.includes(searchQuery.toLowerCase()) ||
            assignedUser.includes(searchQuery.toLowerCase()) ||
            createdByName.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    // const exportVisitPlan = async () => {
    //     const table = document.getElementById('exportVisitPlanExcelFile');
        
    //     const headersRow = table.querySelector('thead tr');
    //     const headers = Array.from(headersRow.querySelectorAll('th'));
    //     const actionColumnIndex = headers.findIndex(th => th.innerText.trim() === 'Action');

    //     headersRow.removeChild(headers[actionColumnIndex]);
    
    //     TableToExcel.convert(table, {
    //         name: 'Visit Plan.xlsx',
    //         sheet: {
    //             name: 'Visit Plan',
    //         },
    //     });

    //     headersRow.appendChild(headers[actionColumnIndex]);
    // };

    const exportVisitPlan = async () => {
        const table = document.getElementById('exportVisitPlanExcelFile');
        
        // Debugging log: Check if table is correctly selected
        console.log('Table:', table);
        
        const headersRow = table.querySelector('thead tr');
        const headers = Array.from(headersRow.querySelectorAll('th'));
        const actionColumnIndex = headers.findIndex(th => th.innerText.trim() === 'Action');
        
        // Debugging logs: Check headersRow and actionColumnIndex
        console.log('Headers Row:', headersRow);
        console.log('Action Column Index:', actionColumnIndex);
        
        if (actionColumnIndex !== -1) {
            // Debugging log: Check the header element to remove
            console.log('Header to Remove:', headers[actionColumnIndex]);
            
            headersRow.removeChild(headers[actionColumnIndex]);
        } else {
            console.log('Action column not found.');
        }
    
        TableToExcel.convert(table, {
            name: 'Visit Plan.xlsx',
            sheet: {
                name: 'Visit Plan',
            },
        });
    
        // Re-append the action column header if it was removed
        if (actionColumnIndex !== -1) {
            headersRow.appendChild(headers[actionColumnIndex]);
        }
    };    

    const getLocation = (beatPlanId, btnAction, id) => {

        setPreloaderStatus(true);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {

                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                if(latitude !== "" && longitude !== ""){
            
                    const params = {
                        'id': id,
                        'beat_plan_id': beatPlanId,
                        'btnAction': btnAction,
                        'latitude': latitude,
                        'longitude': longitude
                    };
                  //  setTotalDistance(0); 
                    const requestData = encryptData(params);
                    const response = actions.setBeatPlanStatus(requestData, false);
                    response.then((result) => {
                        if (result.data.success === 1) {
                            
                            toast.success(result.data.message, {
                                position: "top-right",
                            });
                            // setPreloaderStatus(false);
                            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': startDate, 'end_date': endDate };
                            const requestData = encryptData(params);
                            const response = actions.getVisitPlanData(requestData, false);
                            response.then((result) => {
                                // console.log("Promise resolved -  getVisitPlanData:", result);
                                if (result.data.success === 1) {
                                    setPreloaderStatus(false);
                                    const results = result.data.data.beat_plan_data;
                                    const resultArr = Object.keys(results).map((key) => results[key]);
                                    setAllBeatPlans(resultArr);
                                    const totalDistance = resultArr.reduce((total, item) => {
                                        return total + parseFloat(item.distance);
                                     }, 0); 
                                     setTotalDistance(totalDistance.toFixed(4));
                                } else {
                                    setPreloaderStatus(false);
                                    setAllBeatPlans([]);
                                }
                            }).catch((error) => {
                                setPreloaderStatus(false);
                                console.error("Promise rejected -  getVisitPlanData:", error);
                            });
                        } else {
                            setPreloaderStatus(false);
                            toast.error(result.data.message, {
                                position: "top-right",
                            });
                        }
                    }).catch((error) => {
                        setPreloaderStatus(false);
                        console.error("Promise rejected -  setBeatPlanStatus: ", error);
                    });
                }

                setBeatPlanLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            }, (error) => {
                toast.error('Error getting location:', error, {
                    position: "top-right",
                });
            });
        } else {
            toast.error("Geolocation is not supported by this browser.", {
                position: "top-right",
            });
        }
    }

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>Visit Plans | Ckers</title>
            </Helmet>

            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-4 filter'>
                        <div className='row mx-0'>
                            <div className='col'>
                                <div className='form-group'>
                                    <span>User Name</span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="created_by"
                                            options={allLoggedinUserChild.map((option) => ({
                                                key: option.id,
                                                value: option.name,
                                            }))}
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group'>
                                    <span>Visit Type</span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="visit_type"
                                            options={allCampaigns.map((option) => ({
                                                key: option.id,
                                                value: option.name,
                                            }))}
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedVisitType(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedVisitType(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Select Start Date </span>
                                        <input type='date' name='start_date' className='form-control'{...register('start_date')} />
                                    </label>
                                    {errors.start_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="end-date">
                                        <span>Select End Date</span>
                                        <input type='date' name='end_date' className='form-control'{...register('end_date')} />
                                    </label>
                                    {errors.end_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div id="main-wrap">
                    <div className="row justify-content-between">
                        <div className="col-md-7">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                                Visit Plans
                            </Typography>
                        </div>
                        <div className='col-md-5 text-end ps-lg-5'>
                            <div className="form-group d-flex justify-content-end align-items-center">
                                <label htmlFor="search" className='w-100'>
                                    {/* Search : */}
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Company name, Assigned to, Created by.. "
                                        id="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </label>
                                <button className="btn btn-info text-white ms-3" onClick={() => exportVisitPlan()}>
                                    Export
                                </button>
                            </div>

                            <div>Total Distance : {totalDistance} Km</div>
                        </div>
                    </div>
                    <div className="table-wrap table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                
                                    <th className="text-nowrap">User Name</th>
                                    <th className="text-nowrap">Company Name</th>
                                    <th className="text-nowrap">Contact Name</th>
                                    <th className="text-nowrap">Phone Number</th>
                                    <th className="text-nowrap">Address</th>
                                    <th className="text-nowrap">Remark</th>
                                    <th className="text-nowrap">Visit Type</th>
                                    <th className="text-nowrap">GPS Location</th>
                                    {/* <th className="text-nowrap">Visit Status</th> */}
                                    <th className="text-nowrap">Created On</th>
                                    <th className="text-nowrap">Created By</th>
                                    <th className="text-nowrap">Scheduled Time</th>
                                    <th className="text-nowrap">Start Time</th>
                                    <th className="text-nowrap">End Time</th>
                                    <th className="text-nowrap">Start Location</th>
                                    <th className="text-nowrap">End Location</th>
                                    <th className="text-nowrap">Start Lat Long</th>
                                    <th className="text-nowrap">End Lat Long</th>
                                    <th className="text-nowrap">Distance</th>
                                    <th className="text-nowrap">Meeting Duration(Minutes)</th>
                                    {/* <th className="text-nowrap">Visit Plan Action</th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.assignedUser}</td>
                                        <td>{item.companyName}</td>
                                        <td>{item.contact_name}</td>
                                        <td>{item.mobile}</td>
                                        <td>
                                            <p className='beat-plan-address'>{item.address}</p>
                                        </td>
                                        <td>
                                            <p className='beat-plan-remark'>{item.remarks}</p>
                                        </td>
                                        <td>{item.visitTypeName}</td>
                                        <td>
                                            <p className='beat-plan-remark'>{item.gpsLocation}</p>
                                            <p className='beat-plan-remark'>{item.gpsAddress}</p>
                                        </td>
                                        {/* <td>
                                            <span
                                            className={(item.visit_status === '2') ? "badge text-bg-success text-white" : (item.visit_status === '1') ? "badge text-bg-danger text-white" : "badge text-bg-warning text-white"}
                                            >{item.visitStatusName}</span>
                                        </td> */}
                                        <td>{item.created_date}</td>
                                        <td>{item.createdByName}</td>
                                        <td>{item.scheduledTime}</td>
                                        <td>{item.beatPlanStartTime}</td>
                                        <td>{item.beatPlanEndTime}</td>
                                        <td className='beat-plan-remark'>{item.startGpsAddress}</td>
                                        <td className='beat-plan-remark'>{item.endGpsAddress}</td>
                                        <td className='beat-plan-remark'>{item.startGpsLocation}</td>
                                        <td className='beat-plan-remark'>{item.endGpsLocation}</td>
                                        <td className='beat-plan-remark'>{item.distance} Km</td>
                                        <td>{item.meetingDuration}</td>
                                        {/* <td>
                                            {
                                                item.startGpsAddress !== null &&
                                                    item.startGpsAddress !== '' &&
                                                    item.startGpsLocation !== null &&
                                                    item.startGpsLocation !== '' &&
                                                    item.endGpsAddress !== null &&
                                                    item.endGpsAddress !== '' &&
                                                    item.endGpsLocation !== null &&
                                                    item.endGpsLocation !== '' &&
                                                    item.start_end_id !== null &&
                                                    item.start_end_id !== ''
                                                    ? (
                                                        <span className='badge text-bg-info text-white'>Completed</span>
                                                    ) : (
                                                        item.startGpsAddress !== null &&
                                                        item.startGpsAddress !== '' &&
                                                        item.startGpsLocation !== null &&
                                                        item.startGpsLocation !== '' &&
                                                        item.start_end_id !== null &&
                                                        item.start_end_id !== ''
                                                    ) ? (
                                                        <div className="btn-group dropstart">
                                                            <button className="btn btn-primary" onClick={() => getLocation(item.id, 'end', item.start_end_id)}>End</button>
                                                        </div>
                                                    ) : (
                                                        <div className="btn-group dropstart">
                                                            <button className="btn btn-primary" onClick={() => getLocation(item.id, 'start', null)}>Start</button>
                                                        </div>
                                                    )
                                            }
                                        </td> */}

                                    </tr>
                                ))}

                                {paginatedItems.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={11} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav className='m-2'>
                        <ul className="pagination pagination-md justify-content-end">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                <li className="page-item">
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        disabled={currentPage === page}
                                        className="page-link"
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>

                <div className="table-wrap table-responsive d-none">
                    <table className="table table-hover" id="exportVisitPlanExcelFile">
                        <thead>
                            <tr>

                                <th className="text-nowrap">User Name</th>
                                <th className="text-nowrap">Company Name</th>
                                <th className="text-nowrap">Contact Name</th>
                                <th className="text-nowrap">Phone Number</th>
                                <th className="text-nowrap">Address</th>
                                <th className="text-nowrap">Remark</th>
                                <th className="text-nowrap">Visit Type</th>
                                <th className="text-nowrap">GPS Location</th>
                                {/* <th className="text-nowrap">Visit Status</th> */}
                                <th className="text-nowrap">Created On</th>
                                <th className="text-nowrap">Created By</th>
                                <th className="text-nowrap">Scheduled Time</th>
                                <th className="text-nowrap">Start Time</th>
                                <th className="text-nowrap">End Time</th>
                                <th className="text-nowrap">Start Location</th>
                                <th className="text-nowrap">End Location</th>
                                <th className="text-nowrap">Start Lat Long</th>
                                <th className="text-nowrap">End Lat Long</th>
                                <th className="text-nowrap">Distance</th>
                                <th className="text-nowrap">Meeting Duration(Minutes)</th>
                                {/* <th className="text-nowrap">Visit Plan Action</th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {allBeatPlans.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.assignedUser}</td>
                                    <td>{item.companyName}</td>
                                    <td>{item.contact_name}</td>
                                    <td>{item.mobile}</td>
                                    <td>
                                        <p className='beat-plan-address'>{item.address}</p>
                                    </td>
                                    <td>
                                        <p className='beat-plan-remark'>{item.remarks}</p>
                                    </td>
                                    <td>{item.visitTypeName}</td>
                                    <td>
                                        <p className='beat-plan-remark'>{item.gpsLocation}</p>
                                        <p className='beat-plan-remark'>{item.gpsAddress}</p>
                                    </td>
                                    {/* <td>
                                            <span
                                            className={(item.visit_status === '2') ? "badge text-bg-success text-white" : (item.visit_status === '1') ? "badge text-bg-danger text-white" : "badge text-bg-warning text-white"}
                                            >{item.visitStatusName}</span>
                                        </td> */}
                                    <td>{item.created_date}</td>
                                    <td>{item.createdByName}</td>
                                    <td>{item.scheduledTime}</td>
                                    <td>{item.beatPlanStartTime}</td>
                                    <td>{item.beatPlanEndTime}</td>
                                    <td className='beat-plan-remark'>{item.startGpsAddress}</td>
                                    <td className='beat-plan-remark'>{item.endGpsAddress}</td>
                                    <td className='beat-plan-remark'>{item.startGpsLocation}</td>
                                    <td className='beat-plan-remark'>{item.endGpsLocation}</td>
                                    <td className='beat-plan-remark'>{item.distance} Km</td>
                                    <td>{item.meetingDuration}</td>
                                    {/* <td>
                                            {
                                                item.startGpsAddress !== null &&
                                                    item.startGpsAddress !== '' &&
                                                    item.startGpsLocation !== null &&
                                                    item.startGpsLocation !== '' &&
                                                    item.endGpsAddress !== null &&
                                                    item.endGpsAddress !== '' &&
                                                    item.endGpsLocation !== null &&
                                                    item.endGpsLocation !== '' &&
                                                    item.start_end_id !== null &&
                                                    item.start_end_id !== ''
                                                    ? (
                                                        <span className='badge text-bg-info text-white'>Completed</span>
                                                    ) : (
                                                        item.startGpsAddress !== null &&
                                                        item.startGpsAddress !== '' &&
                                                        item.startGpsLocation !== null &&
                                                        item.startGpsLocation !== '' &&
                                                        item.start_end_id !== null &&
                                                        item.start_end_id !== ''
                                                    ) ? (
                                                        <div className="btn-group dropstart">
                                                            <button className="btn btn-primary" onClick={() => getLocation(item.id, 'end', item.start_end_id)}>End</button>
                                                        </div>
                                                    ) : (
                                                        <div className="btn-group dropstart">
                                                            <button className="btn btn-primary" onClick={() => getLocation(item.id, 'start', null)}>Start</button>
                                                        </div>
                                                    )
                                            }
                                        </td> */}

                                </tr>
                            ))}

                            {allBeatPlans.length < 1 && (
                                <>
                                    <tr>
                                        <td colSpan={11} className='text-center py-3 fw-bold'>No record found</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>

                {preloaderStatus && <Loader />}
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getVisitPlanData: state.getVisitPlanData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, beatPlanActions.default, campaignsActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VisitPlanPage);