import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";
import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const LeadBreakupPage = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const location = useLocation();
    const { actions, getCrifsData, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');
    
    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allLeads, setAllLeads] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [crifUsagePrimary, setCrifUsagePrimary] = React.useState(true);
    const [crifUsageCoApplicant, setCrifUsageCoApplicant] = React.useState(true);
    const [crifUsageGuarantor, setCrifUsageGuarantor] = React.useState(true);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'CRIF Usage')?.view_permission;
                setPermissions(permissionsData);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;

                const extractedData = results.map(item => {
                    return { id: item.user_id, name: item.name };
                });
                const resultArr = Object.values(extractedData);
                setAllLoggedinUserChild(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllLoggedinUserChild]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        // Get the current date
        const today = new Date();

        // Calculate the previous day
        const previousDay = new Date(today);
        previousDay.setDate(today.getDate() - 1);

        // Format the dates in YYYY-MM-DD format
        const previousDayFormatted = formatDate(previousDay);
        const todayFormatted = formatDate(today);
        // Set the initial values in the form without triggering validation
        setValue('start_date', todayFormatted);
        setValue('end_date', todayFormatted);

        setStartDate(todayFormatted);
        setEndDate(todayFormatted);

        setPreloaderStatus(true);

        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted};
        const requestData = encryptData(params);
        const response = actions.getCrifsData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getCrifsData:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllLeads(resultArr);
            } else {
                setPreloaderStatus(false);
                setAllLeads([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getCrifsData:", error);
        });

    }, [setAllLeads]);

    const onSubmit = (data) => {
        const params = {
            'start': '0',
            'length': config.MAX_RECORDS,
            'created_by': selectedUsers,
            'start_date': data.start_date,
            'end_date': data.end_date,
        };

        setStartDate(data.start_date);
        setEndDate(data.end_date);

        setPreloaderStatus(true);

        const requestData = encryptData(params);
        const response = actions.getCrifsData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllLeads(resultArr);
            } else {
                setPreloaderStatus(false);
                setAllLeads([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  lead list:", error);
        });
    };

    const clickActionModal = (actionName, sectionId,otherInfo) => {
        setSendCrifOpenModal(true);
    }

    const handleSendCrifClose = () => { 
        setSendCrifOpenModal(false);
        const initialFormValues = {
            email_address: '',
            
        };
        setSendCrifFormData(initialFormValues);
    }
    
    const handleSendCrifForm = (event) => {
        const { name, value } = event.target;
        setSendCrifFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmitCrifSendForm = (event) => {
        event.preventDefault();

        sendCrifFormData.start_date = startDate;
        sendCrifFormData.end_date = endDate;
        sendCrifFormData.created_by = selectedUsers;
        sendCrifFormData.crifUsagePrimary = crifUsagePrimary;
        sendCrifFormData.crifUsageCoApplicant = crifUsageCoApplicant;
        sendCrifFormData.crifUsageGuarantor = crifUsageGuarantor;

        if (sendCrifFormData.email_address.trim() === "") {
            toast.error("Email is blank.", {
                position: "top-right",
            });
        } else {
            // console.log("sendCrifFormData :", sendCrifFormData);

            setPreloaderStatus(true);

            const requestData = encryptData(sendCrifFormData);
            const response = actions.crifUsageSendEmail(requestData);
            response.then((result) => {
                // console.log("Promise resolved - add/edit lead form:", result); 
                if (result.data.success === 1) {                    
                    setPreloaderStatus(false);
                    setSendCrifOpenModal(false);
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    const initialFormValues = {
                        email_address: '',
                    };
                    setSendCrifFormData(initialFormValues);
                } else {
                    setPreloaderStatus(false);
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    }

    const handleCrifUsagePrimary = (e) => {
        setCrifUsagePrimary(e.target.checked);
    };

    const handleCrifUsageCoApplicant = (e) => { 
        setCrifUsageCoApplicant(e.target.checked);
    };

    const handleCrifUsageGuarantor = (e) => { 
        setCrifUsageGuarantor(e.target.checked);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allLeads.filter(item => {
        const name = item.name?.toLowerCase() || '';
        const sectionName = item.section_name;
        const isCrifUsagePrimaryFalse = crifUsagePrimary === false;
        const isCrifUsageCoApplicantFalse = crifUsageCoApplicant === false;
        const isCrifUsageGuarantorFalse = crifUsageGuarantor === false;
    
        const isSectionNameLead = sectionName === "lead";
        const isSectionNameCoApplicant = sectionName === "coApplicant";
        const isSectionNameGuarantor = sectionName === "guarantor";
        const isNameMatched = name.includes(searchQuery.toLowerCase());
    
        if ((isCrifUsagePrimaryFalse && isSectionNameLead) || (isCrifUsageCoApplicantFalse && isSectionNameCoApplicant) || (isCrifUsageGuarantorFalse && isSectionNameGuarantor)) {
            return false;
        }

        return isNameMatched;
    });
    
    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });
    
    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>CRIF Usage</title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    CRIF Usage
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-4 filter'>
                        <div className='row mx-0'>
                            <div className='col'>
                                <div className='form-group'>
                                    <span>Created By</span>
                                    <div className="text-dark">
                                        <Multiselect
                                            id="created_by"
                                            options={allLoggedinUserChild.map((option) => ({
                                                key: option.id,
                                                value: option.name,
                                            }))}
                                            displayValue="value"
                                            onSelect={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                            onRemove={(selectedItems) => {
                                                const selectedValues = selectedItems.map(item => item.key);
                                                setSelectedUsers(selectedValues);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Select Start Date</span>
                                        <input type='date' name='start_date' className='form-control'{...register('start_date', { required: true })} />
                                    </label>
                                    {errors.start_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="end-date">
                                        <span>Select End Date</span>
                                        <input type='date' name='end_date' className='form-control'{...register('end_date', { required: true })} />
                                    </label>
                                    {errors.end_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>
                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div id='main-wrap' className='mb-4'>

                    <div className='row justify-content-between'>
                        <div className='col-md-10 row text-end'>

                            <div className='col-md-4 text-end'>
                                <input
                                    className="form-check-input-"
                                    type="checkbox" 
                                    id="crifUsagePrimaryStatus"
                                    name="crifUsagePrimaryStatus"
                                    checked={crifUsagePrimary} 
                                    onChange={handleCrifUsagePrimary}
                                /> &nbsp;<span>Primary Applicant</span>
                            </div>

                            <div className='col-md-4 text-end'>
                                <input
                                    className="form-check-input-"
                                    type="checkbox" 
                                    id="crifUsageCoApplicantStatus"
                                    name="crifUsageCoApplicantStatus"
                                    checked={crifUsageCoApplicant} 
                                    onChange={handleCrifUsageCoApplicant}
                                /> &nbsp;<span>Co-Applicant</span>
                            </div> 

                            <div className='col-md-4 text-end'>
                                <input
                                    className="form-check-input-"
                                    type="checkbox" 
                                    id="crifUsageGuarantorStatus"
                                    name="crifUsageGuarantorStatus"
                                    checked={crifUsageGuarantor} 
                                    onChange={handleCrifUsageGuarantor}
                                /> &nbsp;<span>Guarantor</span>
                            </div>
                        </div>
                        <div className='col-md-2 text-end'>
                            <div className='form-group mb-3'>
                                <button className='btn btn-info text-white' onClick={() => clickActionModal()}>Send Email</button>
                            </div>
                        </div>
                    </div>

                    <div className='table-wrap table-responsive'>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className='text-nowrap'>Name</th>
                                    <th className='text-nowrap'>Applicant Type</th>
                                    <th className='text-nowrap'>Phone Number</th>
                                    <th className='text-nowrap'>Created By</th>
                                    <th className='text-nowrap'>Created Date</th>
                                    <th className='text-nowrap'>Pancard Number</th>
                                    <th className='text-nowrap'>Score</th>
                                    <th className='text-nowrap'>Purpose and <br/>Deployment of Vehicle</th>
                                    <th className='text-nowrap'>Investor Profile</th>
                                    <th className='text-nowrap'>Remarks</th>
                                    <th className='text-nowrap'>Consent Details</th>
                                    <th className='text-nowrap'>Consent By</th>
                                    <th className='text-nowrap'>OEM</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="btn-group dropstart" title="Edit">
                                                <NavLink className="text-dark" to={`/dashboard/edit-lead/${btoa(String(item.lead_id))}/${btoa(String("basic"))}`}>{item.name}</NavLink>
                                            </div>
                                        </td>
                                        <td>{item.section_name === "lead" ? "Primary Applicant" :
                                                    item.section_name === "coApplicant" ? "Co-Applicant" :
                                                    item.section_name === "guarantor" ? "Guarantor" : ""}
                                                
                                        </td>
                                        <td>{item.mobile}</td>
                                        <td>{item.createdByName}</td>
                                        <td>{item.created_date}</td>
                                        <td>
                                            {item.pan_number ? `*****${item.pan_number.replace(/"/g, '').slice(-4)}` : ''}
                                        </td>
                                        <td>
                                            {
                                                Object.prototype.hasOwnProperty.call(item, 'crif_info') && item.crif_info.score !== '' && item.crif_info.score !== null
                                                ? item.crif_info.score
                                                : 'Score not found'
                                            }
                                        </td>
                                        <td>{item.purposeDeploymentVehicle},</td>
                                        <td>{item.investorProfile}</td>
                                        <td>
                                            <p className='crif-usage-remark'>{item.remarks}</p>
                                        </td>
                                        <td>
                                            <p className='crif-usage-remark'>{item.consentDetails}</p>
                                            {item.ipAddress && (
                                                <p className='crif-usage-remark'>IP - {item.ipAddress}</p>
                                            )}
                                            <p className='crif-usage-remark'>{item.formattedOtpSentDate}</p>
                                        </td>
                                        <td>{item.createdByName}</td>
                                        <td>{item.oemName}</td>
                                    </tr>
                                ))}

                                {paginatedItems.length < 1 && (
                                    <>
                                        <tr>
                                            <td colSpan={14} className='text-center py-3 fw-bold'>No record found</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <nav className='mb-1'>
                            <ul className="pagination pagination-md justify-content-end">
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                    <li className="page-item">
                                        <button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            disabled={currentPage === page}
                                            className="page-link"
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
                
                {preloaderStatus && <Loader />}

                <Modal
                    open={sendCrifOpenModal}
                    onClose={handleSendCrifClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className='modal-custom rounded'>
                        <div className='modal-head'>
                            <h4 id="modal-title">Send CRIF Usage Report</h4>
                            <Button onClick={handleSendCrifClose} color="primary" className=' btn'>
                                <i className="bi bi-x">&nbsp;</i>
                            </Button>
                        </div>
                        <form onSubmit={handleSubmitCrifSendForm} className="" encType="multipart/form-data">
                            <div className='modal-body'>
                                <div className='scroll-content'>
                                    <div className="row">
                                        <div className="col-md-12" >
                                            <div className='form-group'>
                                                <label htmlFor="email-address">
                                                    <span>Email <a style={{ color: "red" }}>*</a></span>
                                                    <input
                                                        type='text'
                                                        id='email_address'
                                                        name='email_address'
                                                        className='form-control'
                                                        value={sendCrifFormData.email_address}
                                                        onChange={handleSendCrifForm}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-0">
                                            <button className="btn btn-primary mt-2">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal>

                
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getCrifsData: state.getCrifsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadBreakupPage);