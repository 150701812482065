import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as departmentActions from "../../actions/departmentActions";
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as masterAction from "../../actions/masterAction";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const AddEditLeadStatusPage = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const { actions, usersData, leadStatusInfo, checklistsData } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [leadStatusId, setLeadStatusId] = useState('');
  const [showLeadStatusDetails, setShowLeadStatusDetails] = useState(false);

  const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
  const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
  const [userHasPermission, setUserHasPermission] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [isExternalStatus, setIsExternalStatus] = useState(false);

  const [allStatusData, setAllStatusData] = useState([]);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState([]);
  const [numbersArray, setNumbersArray] = useState([]);
  const isSelected = true;

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getUserModuleData(requestData);
    response.then((result) => {
      console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        const permissionsData = result.data.data;
        setPermissions(permissionsData);
        const leadPermissions = permissionsData.find(permission => permission.module_name === 'Lead Status');
        const hasAddPermissions = leadPermissions?.add_permission;
        const hasEditPermissions = leadPermissions?.edit_permission;

        setAddModulePermissionStatus(hasAddPermissions);
        setEditModulePermissionStatus(hasEditPermissions);
        setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
      }
    }).catch((error) => {
      console.error("Promise rejected -  lead list:", error);
    });
  }, [setPermissions, setUserHasPermission]);

  useEffect(() => {
    // check user is logged-in or not
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/login');
    }

    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    actions.usersData(requestData);

    if (usersData.success) {
      setAllUsers(usersData.data);
    }
  }, []);

useEffect(() => {
  const obj = { 'start': '0', 'length': config.MAX_RECORDS };
  const requestData = encryptData(obj);
  const response = actions.getSubLeadStatus(requestData);
  response.then((result) => {
      // console.log("Promise resolved -  leadStatusData:", result);
      if (result.data.success === 1) {
          const results = result.data.data.data;
          const resultArr = Object.keys(results).map((key) => results[key]);
          const filteredResultArr = resultArr.filter((item) => item.status === "1");
          const sortedResultArr = filteredResultArr.map(item => {
              return { id: item.id, name: item.name };
          });
          setAllStatusData(sortedResultArr);
      }
  }).catch((error) => {
      console.error("Promise rejected -  leadStatusData:", error);
  });
}, [setAllStatusData]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setShowLeadStatusDetails(false);
    if (id !== undefined && !showLeadStatusDetails) {
      const decodedId = atob(id);
      setLeadStatusId(decodedId);

      const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'lead_status_id': decodedId };

      const requestData = encryptData(obj);
      const response = actions.leadStatusData(requestData);

      response.then((result) => {
        // console.log("Promise resolved -  lead status data :", result); 
        if (result.data.success === 1) {
          const results = result.data.data.data;
          const leadStatusInfo = Object.keys(results).map((key) => results[key]);

          setShowLeadStatusDetails(true);
          setValue("lead_status_name", leadStatusInfo[0].lead_status);
          // setValue("action_user_id", leadStatusInfo[0].created_by);
          setValue("status", leadStatusInfo[0].status);

          const leadLeadStatusString = leadStatusInfo[0].sub_lead_status;

          if (leadLeadStatusString !== "") {
            const leadLeadStatusArray = leadLeadStatusString.split(',');
            setSelectedLeadStatus(leadLeadStatusArray);
          }
          setNumbersArray(leadStatusInfo[0].selected_lead_status);

        }
      }).catch((error) => {
        //  console.error("Promise rejected -  lead status data :", error); 
      });
    }
  }, [id, setValue, setShowLeadStatusDetails, setNumbersArray, setSelectedLeadStatus]);

  const onSubmit = (data) => {

    const subLeadStatus = JSON.stringify(selectedLeadStatus);

    let params = { 'lead_status_name': data.lead_status_name, 'status': data.status, 'is_external_status': (isExternalStatus ? '1' : '0'), "sub_lead_status": subLeadStatus};

    if (leadStatusId !== '') {
      params = { 'lead_status_name': data.lead_status_name, 'status': data.status, 'lead_status_id': leadStatusId, 'is_external_status': (isExternalStatus ? '1' : '0'), "sub_lead_status": subLeadStatus};
    }

    const requestData = encryptData(params);
    const response = actions.createLeadStatus(requestData, false);

    response.then((result) => {
      // console.log("Promise resolved - add/edit lead form:", result); 
      if (result.data.success === 1) {
        toast.success(result.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          navigate('/dashboard/lead-status');
        }, 1000);
      } else {
        toast.error(result.data.message, {
          position: "top-right",
        });
      }
    }).catch((error) => {
      // console.error("Promise rejected - add/edit lead form:", error);
      toast.error(error, {
        position: "top-right",
      });
    });

  };

  const handleSwitchChange = (e) => {
    setIsExternalStatus(e.target.checked);
  };

  if (!userHasPermission) {
    // Redirect or render an access denied message
    return <AccessDenied />;
  }
  return (
    <>
      <Helmet>
        <title>{showLeadStatusDetails ? "Edit Lead Status" : "Create Lead Status"}</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {showLeadStatusDetails ? "Edit Lead Status" : "Create Lead Status"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div id='main-wrap'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="name">
                    <span>Lead Status Name <a style={{ color: "red" }}>*</a></span>
                    <input type='text' name='lead_status_name' className='form-control'
                      {...register('lead_status_name', { required: true })} />
                  </label>
                  {errors.lead_status_name && <span className="required">This field is required.</span>}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor="reporting-user">
                    <span>Status <a style={{ color: "red" }}>*</a></span>
                    <select name='status' className="form-select" {...register('status', { required: true })}>
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </label>
                  {errors.status && <span className="required">This field is required.</span>}
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                    <span>Sub Lead Status</span>
                    <div className="text-dark">
                        <Multiselect
                            id="lead_profile"
                            options={allStatusData.map((option) => ({
                                key: option.id,
                                value: option.name,
                            }))}
                            selectedValues={isSelected ? numbersArray : []} // Use isSelected to conditionally set selected values
                            displayValue="value"
                            onSelect={(selectedItems) => {
                                const selectedValues = selectedItems.map(item => item.key);
                                setSelectedLeadStatus(selectedValues);
                            }}
                            onRemove={(selectedItems) => {
                                const selectedValues = selectedItems.map(item => item.key);
                                setSelectedLeadStatus(selectedValues);
                            }}
                        />
                    </div>
                </div>
            </div>

              <div className='col-md-6'>
                <div className='form-group'>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="is_external_status">

                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="is_external_status"
                                name="is_external_status"
                                checked={isExternalStatus}
                                onChange={handleSwitchChange}
                            />
                            <span>Is External Status</span>

                        </label>
                    </div>
                </div>
            </div>

              <div className='col-md-12'>
                <div className='form-group mb-2'>
                  <button className='btn btn-primary'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  return {
    usersData: state.usersData,
    leadStatusInfo: state.leadStatusInfo,
    getUserModuleData: state.getUserModuleData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, masterAction.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditLeadStatusPage);