import { toast } from "react-toastify";
import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

const getUserByRole = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
        if (preloader) {
            dispatch(toggleNetworkRequestStatus(true));
        }
        try {
            let response;

            if (returnVal) {
                response = await agent.post(API.GET_USER_BY_ROLE, params);
            } else {
                response = await agent.post(API.GET_USER_BY_ROLE, params);
            }

            if (preloader) {
                dispatch(toggleNetworkRequestStatus(false));
            }

            return response;
        } catch (error) {
            // Handle errors, e.g., log or dispatch an error action
            console.error("API Request Error:", error);

            if (preloader) {
                dispatch(toggleNetworkRequestStatus(false));
            }
            throw error;
        }
    };
}

const createApprovalMaster = (params) => {
    return async function (dispatch) {
        dispatch(toggleNetworkRequestStatus(true));
        try {
            const response = await agent.post(API.CREATE_APPROVAL_MASTER, params);
            dispatch(toggleNetworkRequestStatus(false));
            return response;
        } catch (error) {
            dispatch(toggleNetworkRequestStatus(false));
            throw error;
        }
    };
}

const getApprovalMaster = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
        if (preloader) {
            dispatch(toggleNetworkRequestStatus(true));
        }
        try {
            let response;

            if (returnVal) {
                response = await agent.post(API.GET_APPROVAL_MASTER, params);
            } else {
                response = await agent.post(API.GET_APPROVAL_MASTER, params);
            }

            if (preloader) {
                dispatch(toggleNetworkRequestStatus(false));
            }

            return response;
        } catch (error) {
            // Handle errors, e.g., log or dispatch an error action
            console.error("API Request Error:", error);

            if (preloader) {
                dispatch(toggleNetworkRequestStatus(false));
            }
            throw error;
        }
    };
}

const allCloudSchemesData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_ALLCLOUD_SCHEMES, params);
        } else {
          response = await agent.post(API.GET_ALLCLOUD_SCHEMES, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
  };


  const allCloudLoanCategoriesData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_ALLCLOUD_LOAN_CATEGORIES, params);
        } else {
          response = await agent.post(API.GET_ALLCLOUD_LOAN_CATEGORIES, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
  };

  const allCloudLeadSourcesData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_ALLCLOUD_LEAD_SOURCES, params);
        } else {
          response = await agent.post(API.GET_ALLCLOUD_LEAD_SOURCES, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
  };

  const allCloudOrganizationTypesData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_ALLCLOUD_ORGANIZATION_TYPES, params);
        } else {
          response = await agent.post(API.GET_ALLCLOUD_ORGANIZATION_TYPES, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
  };

  const allCloudProductTypesData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_ALLCLOUD_PRODUCT_TYPES, params);
        } else {
          response = await agent.post(API.GET_ALLCLOUD_PRODUCT_TYPES, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
  };

  const allCloudLpcTypesData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_ALLCLOUD_LPC_TYPES, params);
        } else {
          response = await agent.post(API.GET_ALLCLOUD_LPC_TYPES, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
  };

  const allCloudEmploymentTypesData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_ALLCLOUD_EMPLOYMENT_TYPES, params);
        } else {
          response = await agent.post(API.GET_ALLCLOUD_EMPLOYMENT_TYPES, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
  };

  const allCloudLoanSegmentsData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_ALLCLOUD_LOANSEGMENTS, params);
        } else {
          response = await agent.post(API.GET_ALLCLOUD_LOANSEGMENTS, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
       // console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
  };

  const getAllAdditionalMissingData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_ALL_ADDITIONAL_MISSING_DATA, params);
        } else {
          response = await agent.post(API.GET_ALL_ADDITIONAL_MISSING_DATA, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
       // console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
  };

const addEditSubLeadStatus = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.ADD_EDIT_SUB_LEAD_STATUS, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    }
  };
}

const getSubLeadStatus = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    }
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.GET_SUB_LEAD_STATUS, params);
      } else {
        response = await agent.post(API.GET_SUB_LEAD_STATUS, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      // console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }
      throw error;
    }
  };
};

export default {
    getUserByRole,
    createApprovalMaster,
    getApprovalMaster,
    allCloudSchemesData,
    allCloudLoanCategoriesData,
    allCloudLeadSourcesData,
    allCloudOrganizationTypesData,
    allCloudProductTypesData,
    allCloudLpcTypesData,
    allCloudEmploymentTypesData,
    allCloudLoanSegmentsData,
    getAllAdditionalMissingData,
    addEditSubLeadStatus,
    getSubLeadStatus
}