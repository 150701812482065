import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus"; 

export function leadBankStatementDataSuccess(leadBankStatementData) {
  return { type: types.LOAD_LEAD_BANK_STATEMENT_DATA_SUCCESS, leadBankStatementData };
}
export function leadBankStatementByLeadDataSuccess(leadBankStatementByLeadData) {
  return { type: types.LOAD_LEAD_BANK_STATEMENT_BY_LEAD_DATA_SUCCESS, leadBankStatementByLeadData };
}

const addEditLeadBankStatement = (params) => {
    return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
        const response = await agent.post(API.CREATE_LEAD_BANK_STATEMENT, params);
        dispatch(toggleNetworkRequestStatus(false));
        return response;
      } catch (error) {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
      } 
    };
}

const leadBankStatementData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_LEAD_BANK_STATEMENT, params);
        } else {
          response = await agent.post(API.GET_LEAD_BANK_STATEMENT, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
};

const leadBankStatementByLeadData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_LEAD_BANK_STATEMENT_BY_LEAD, params);
        } else {
          response = await agent.post(API.GET_LEAD_BANK_STATEMENT_BY_LEAD, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
};

export default {
    addEditLeadBankStatement,
    leadBankStatementData,
    leadBankStatementDataSuccess,
    leadBankStatementByLeadData,
    leadBankStatementByLeadDataSuccess 
}