import { toast } from "react-toastify";
import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import { setToaster, showToaster } from "../utils/helpers";
import * as API from "../utils/apiPath";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

const addEditModule = (params) => {
    return async function (dispatch) {
        dispatch(toggleNetworkRequestStatus(true));
        try {
        const response = await agent.post(API.CREATE_MODULE, params);
        dispatch(toggleNetworkRequestStatus(false));
        return response;
        } catch (error) {
        dispatch(toggleNetworkRequestStatus(false));
        throw error;
        } 
    };
}

const getModule = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
        if (preloader) {
            dispatch(toggleNetworkRequestStatus(true));
        }
        try {
            let response;

            if (returnVal) {
                response = await agent.post(API.GET_MODULE_BY_USER, params);
            } else {
                response = await agent.post(API.GET_MODULE_BY_USER, params);
            }

            if (preloader) {
                dispatch(toggleNetworkRequestStatus(false));
            }

            return response;
        } catch (error) {
            // Handle errors, e.g., log or dispatch an error action
            console.error("API Request Error:", error);

            if (preloader) {
                dispatch(toggleNetworkRequestStatus(false));
            }
            throw error;
        }
    };
}

const checkModuleCount = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
        if (preloader) {
            dispatch(toggleNetworkRequestStatus(true));
        }
        try {
            let response;

            if (returnVal) {
                response = await agent.post(API.CHECK_MODULE_COUNT, params);
            } else {
                response = await agent.post(API.CHECK_MODULE_COUNT, params);
            }

            if (preloader) {
                dispatch(toggleNetworkRequestStatus(false));
            }

            return response;
        } catch (error) {
            // Handle errors, e.g., log or dispatch an error action
            console.error("API Request Error:", error);

            if (preloader) {
                dispatch(toggleNetworkRequestStatus(false));
            }
            throw error;
        }
    };
}

const getModuleDataWithcomments = (params, preloader = true, returnVal = false) => {
  return async function (dispatch) {
    if (preloader) {
      dispatch(toggleNetworkRequestStatus(true));
    } 
    try {
      let response;

      if (returnVal) {
        response = await agent.post(API.CHECK_MODULE_DATA_WITH_COMMENT, params);
      } else {
        response = await agent.post(API.CHECK_MODULE_DATA_WITH_COMMENT, params);
      }

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      }

      return response;
    } catch (error) {
      // Handle errors, e.g., log or dispatch an error action
      console.error("API Request Error:", error);

      if (preloader) {
        dispatch(toggleNetworkRequestStatus(false));
      } 
      throw error;
    }
  };
}

const addCommentInModule = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.ADD_COMMENT_IN_MODULE, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const updateModuleChecklistQuestionAnswer = (params) => {
  return async function (dispatch) {
    dispatch(toggleNetworkRequestStatus(true));
    try {
      const response = await agent.post(API.UPDATE_MODULE_CHECKLIST_ANSWER, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
    } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
    } 
  };
}

const createModuleTask = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.CREATE_MODULE_TASK, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const moduleTaskDelete = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.MODULE_TASK_DELETE, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const incidentReporteData = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.GET_INCIDENT_REPORTE, params);
        } else {
          response = await agent.post(API.GET_INCIDENT_REPORTE, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
}

const moduleAssignedMe = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.MODULE_ASSIGNED_ME, params);
        } else {
          response = await agent.post(API.MODULE_ASSIGNED_ME, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
}

const moduleTaskAssignedMe = (params, preloader = true, returnVal = false) => {
    return async function (dispatch) {
      if (preloader) {
        dispatch(toggleNetworkRequestStatus(true));
      } 
      try {
        let response;
  
        if (returnVal) {
          response = await agent.post(API.MODULE_TASK_ASSIGNED_ME, params);
        } else {
          response = await agent.post(API.MODULE_TASK_ASSIGNED_ME, params);
        }
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        }
  
        return response;
      } catch (error) {
        // Handle errors, e.g., log or dispatch an error action
        console.error("API Request Error:", error);
  
        if (preloader) {
          dispatch(toggleNetworkRequestStatus(false));
        } 
        throw error;
      }
    };
}

const moduleTaskActivity = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.MODULE_TASK_ACTIVITY, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

const moduleDelete = (params) => {
  return async function (dispatch) {
      dispatch(toggleNetworkRequestStatus(true));
      try {
      const response = await agent.post(API.MODULE_DELETE, params);
      dispatch(toggleNetworkRequestStatus(false));
      return response;
      } catch (error) {
      dispatch(toggleNetworkRequestStatus(false));
      throw error;
      } 
  };
}

  

  export default {
    addEditModule,
    getModule,
    checkModuleCount,
    getModuleDataWithcomments,
    addCommentInModule,
    updateModuleChecklistQuestionAnswer,
    createModuleTask,
    moduleTaskDelete,
    moduleAssignedMe,
    moduleTaskAssignedMe,
    incidentReporteData,
    moduleTaskActivity,
    moduleDelete
  }