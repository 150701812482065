import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, NavLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';

import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as beatPlanActions from "../../actions/beatPlanActions";

import AccessDeniedWithoutLeftMenu from '../common/AccessDeniedWithoutLeftMenu';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const HabileApplications = (props) => {
    const { actions, masterPrefillTemplateNameData } = props;

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewModalInfo, setViewModalInfo] = useState([]);
    const [showListDetails, setShowListDetails] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [allVisitStatusData, setAllVisitStatusData] = useState([]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Visit Status')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                setUserHasPermission(true); // enabled to all
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);


    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getHabileLoanApplicationsData(requestData,false);
        response.then((result) => {
            // console.log("Promise resolved- getHabileLoanApplicationsData:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllVisitStatusData(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected- getHabileLoanApplicationsData:", error); 
        });
    }, [setAllVisitStatusData]);

    const handleClose = () => {
        setOpen(false);
    };

    const onRowClickAction = (itemObj, btnAction) => {
        const encodedId = btoa(String(itemObj.role_permission_id));
        if (btnAction === 'edit') {
            const url = `/dashboard/edit-role-permission/${encodedId}`;
            // navigate(url);
            window.open(url, '_self');
        } else {
            setViewModalInfo(itemObj);
            setOpen(true);
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = (Array.isArray(allVisitStatusData) ? allVisitStatusData : []).filter(item =>
        item.application_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.formatetdID.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.formId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.mobileNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.statusName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    return (
        <>
            <Helmet>
                <title> All Loan Applications | Ckers </title>
            </Helmet>

            <Container>
                <div className="d-flex align-items-center justify-content-between">
                    <span>&nbsp;</span>
                    
                </div>
                {!userHasPermission ? (
                    <>
                        <AccessDeniedWithoutLeftMenu />
                    </>
                ) : (
                    <>
                        <div id='main-wrap' className='mmt-7'>
                            <div className='row justify-content-between mb-3'>
                                <div className='col-md-8'> 
                                <Typography className='line-initial' variant="h4" sx={{ mb: 0 }}>
                                Loan Applications
                                </Typography>
                                </div>
                                <div className='col-md-4 pe-0 ps-lg-5'>
                                <div className="form-group">                                    
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        id="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />                                    
                                </div>
                                </div>
                            </div>
                            <div className='table-wrap'>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Mobile</th>
                                            <th>Form ID</th>
                                            <th>Client ID</th>
                                            <th>Loan Status</th>
                                            <th>Lead Name</th>
                                            <th width='150'>Created Date</th>
                                            <th>Status</th>
                                            <th className="text-right">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {paginatedItems.map((item, index) => {
                                            let AllCloudCustomerId = '';
                                            let AllCloudLeadId = '';
                                            let AllCloudLoanId = '';

                                             // AllCloud Customer details
                                            if (
                                                item.AllCloudCustomerInfo &&
                                                item.AllCloudCustomerInfo[0] &&
                                                Object.prototype.hasOwnProperty.call(item.AllCloudCustomerInfo[0], 'allcloud_api_response')
                                            ) {
                                                const apiResponse = item.AllCloudCustomerInfo[0].allcloud_api_response;
                                                if (apiResponse !== '') {
                                                    const allcloudApiData = JSON.parse(apiResponse);

                                                    if (Object.prototype.hasOwnProperty.call(allcloudApiData, 'CustomerId') && allcloudApiData.CustomerId !== '') {
                                                         AllCloudCustomerId = allcloudApiData.CustomerId;
                                                    }

                                                    if (Object.prototype.hasOwnProperty.call(allcloudApiData, 'CustomerDTO') && allcloudApiData.CustomerDTO !== '') {

                                                        if (Object.prototype.hasOwnProperty.call(allcloudApiData.CustomerDTO, 'CustomerId') && allcloudApiData.CustomerDTO.CustomerId !== '') {
                                                            AllCloudCustomerId = allcloudApiData.CustomerDTO.CustomerId;
                                                        }
                                                   }
                                                }
                                            }

                                             // AllCloud Lead details    
                                            if (
                                                item.AllCloudCustomerLeadInfo &&
                                                item.AllCloudCustomerLeadInfo[0] &&
                                                Object.prototype.hasOwnProperty.call(item.AllCloudCustomerLeadInfo[0], 'allcloud_api_response')
                                            ) {
                                             
                                                const apiResponse = item.AllCloudCustomerLeadInfo[0].allcloud_api_response;

                                                if (apiResponse !== '') {
                                                    const allcloudApiData = JSON.parse(apiResponse);

                                                    if (Object.prototype.hasOwnProperty.call(allcloudApiData, 'LeadDetailId') && allcloudApiData.LeadDetailId !== '') {
                                                        AllCloudLeadId = allcloudApiData.CustomerId;
                                                    }
                                                } 
                                            }
                    

                                            // AllCloud Loans details
                                            if (
                                                item.AllCloudCustomerLoanInfo &&
                                                item.AllCloudCustomerLoanInfo[0] &&
                                                Object.prototype.hasOwnProperty.call(item.AllCloudCustomerLoanInfo[0], 'allcloud_api_response')
                                            ) {    
                                                const apiResponse = item.AllCloudCustomerLoanInfo[0].allcloud_api_response;

                                                if (apiResponse !== '') {
                                                    const allcloudApiData = JSON.parse(apiResponse);

                                                    if (Object.prototype.hasOwnProperty.call(allcloudApiData, 'LeadDetailId') && allcloudApiData.LeadDetailId !== '') {
                                                        AllCloudLoanId = allcloudApiData.LeadDetailId;
                                                    }
                                                }  
                                            }

                                            let allCloudButtonText = 'Send To AllCloud';
                                            if(AllCloudCustomerId !== '' && AllCloudLeadId !== '' && AllCloudLoanId !== '' ){
                                                allCloudButtonText = 'AllCloud Info';
                                            }

                                            return (   
                                            <> 
                                            <tr key={index}>
                                                <td>{item.formatetdID}</td>
                                                <td>{item.mobileNo}</td>
                                                <td>{item.formId}</td>
                                                <td>{item.clientId}</td>
                                                <td>{item.application_status}</td>
                                                <td> {item.lead_name}</td>
                                                <td> {item.created_date}</td>
                                                <td><span
                                                    className={(item.status === '1') ? "badge text-bg-success text-white" : (item.status === '2') ? "badge text-bg-danger text-white" : "badge text-bg-warning text-white"}
                                                >{item.statusName}</span>
                                                </td>
                                                <td>
                                                <div className="btn-group- dropstart" >

                                                { (AllCloudCustomerId !=='') && (                                                    
                                                    <>
                                                    <div className="text-success align-right">Customer is created.</div>
                                                    {AllCloudLeadId==='' ? (<><div className="text-danger align-right">Lead is not created.</div></>) : (<><div className="text-success align-right">Lead is created.</div></>)}
                                                     {AllCloudLoanId==='' ? (<><div className="text-danger align-right">Loan is not created.</div></>) : (<><div className="text-success align-right">Loan is created.</div></>)}
                                                    
                                                    </>
                                                )}

                                                    { /* <NavLink to={`/dashboard/edit-visit-status/${btoa(String(item.id))}`}><i className="bi bi-pen" style={{color: "black"}}/></NavLink> */ }

                                                    { (item.loan_approved_status) ? (
                                                        <>
                                                         { (item.lead_id !==null) ? ( 
                                                            <NavLink className="float-right" to={`/dashboard/send-to-allcloud/${btoa(String(item.lead_id))}/${btoa(String(item.id))}`}>                          
                                                            <button 
                                                                type="button"
                                                                className= "badge text-bg-success text-white float-right"
                                                                > 
                                                            {allCloudButtonText}
                                                            </button></NavLink>
                                                         ) :

                                                         ( 
                                                            <>
                                                            <NavLink className="float-right" to="">                          
                                                            <button 
                                                                type="button"
                                                                className= "badge text-bg-success text-white float-right" disabled
                                                                > 
                                                            {allCloudButtonText}
                                                            </button></NavLink>
                                                            <br/>
                                                            <div className="float-left text-danger ">Application mobile number is not matched with any lead</div>
                                                            </>
                                                         ) }

                                                            
                                                        </>
                                                        
                                                    ) :
                                                    ( 
                                                        <NavLink className= "float-right"  to="">                          
                                                        <button 
                                                            type="button"
                                                            className= "badge text-bg-danger text-white float-right" disabled
                                                            > 
                                                        {allCloudButtonText}
                                                        </button></NavLink>
                                                    ) } 
                                                </div>
                                                </td>
                                            </tr></> );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <nav className='mb-1'>
                                    <ul className="pagination pagination-md justify-content-end">
                                        {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                            <li className="page-item">
                                                <button
                                                    key={page}
                                                    onClick={() => handlePageChange(page)}
                                                    disabled={currentPage === page}
                                                    className="page-link"
                                                >
                                                    {page}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </>
                )}
            </Container>
 
        </>
    );
}

function mapStateToProps(state) {
    return {
        getUserModuleData: state.getUserModuleData,
        masterVisitStatusData: state.masterVisitStatusData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default, beatPlanActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HabileApplications);