import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import $ from 'jquery';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as leadActions from "../../actions/leadActions";
import * as reportActions from "../../actions/reportActions";
import AccessDenied from '../common/AccessDenied';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------


const UserReportPage = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData, sourceData, leadCountData, leadStatusData } = props;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    
    const [allLeadStatusData, setAllLeadStatusData] = useState([]);
    const [allLeadAssignedUserData, setAllLeadAssignedUserData] = useState([]);
    const [allLeadSource, setAllLeadSource] = useState([]);
    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [dynamicSourceColumns, setDynamicSourceColumns] = useState([]);
    const [dynamicStatusColumns, setDynamicStatusColumns] = useState([]);
    const [allLeadSourceCount, setAllLeadSourceCount] = useState(0);
    const [allLeadStatusCount, setAllLeadStatusCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');


    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'User Report')?.view_permission;
                setPermissions(permissionsData);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }

            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);
        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    useEffect(() => {
        // Get the current date
        const today = new Date();

        // Calculate the previous day
        const previousDay = new Date(today);
        previousDay.setDate(today.getDate() - 1);

        // Format the dates in YYYY-MM-DD format
        const previousDayFormatted = formatDate(previousDay);
        const todayFormatted = formatDate(today);
        // Set the initial values in the form without triggering validation
        setValue('start_date', previousDayFormatted);
        setValue('end_date', todayFormatted);

        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': previousDayFormatted, 'end_date': todayFormatted};
        const requestData = encryptData(params);
        const response = actions.getUserReportData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved - leadBreakupCountData", result); 
            if (result.data.success === 1) {
                const results = result.data.data;
                if ('lead_assigned_user' in results) {
                    setAllLeadAssignedUserData(results.lead_assigned_user);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected - leadBreakupCountData", error);
            toast.error(error, {
                position: "top-right",
            });
        });

    }, [setValue, setAllLeadAssignedUserData]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        $("tr").each(function() {
            $(this).find("td.lbs:last").addClass("border-right");
        });
        // setAllSource(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.sourceData(requestData,false);
        response.then((result) => {
            // console.log("Promise resolved -  source data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data;
                const extractedData = results.data.map(item => {
                    return { id: item.id, name: item.source_of_lead };
                });

                const resultArr = extractedData.map(item => ({
                    Header: item.name,
                    accessor: `source_of_lead.${item.name}`,
                }));
                setAllLeadSource(extractedData);
                setDynamicSourceColumns(resultArr);
                setAllLeadSourceCount(extractedData.length);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });

    }, [setAllLeadSource, setDynamicSourceColumns, setAllLeadSourceCount]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.leadStatusData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data;
                const extractedStatusData = results.data.map(item => {
                    return { id: item.id, name: item.lead_status };
                });
    
                const resultStatusArr = extractedStatusData.map(item => ({
                    Header: item.name,
                    accessor: `status.${item.name}`,
                }));
                setAllLeadStatusData(extractedStatusData);
                setDynamicStatusColumns(resultStatusArr);
                setAllLeadStatusCount(extractedStatusData.length);
                
            }
        }).catch((error) => {
            //  console.error("Promise rejected - leadStatusData source data :", error); 
        });
    }, [setAllLeadStatusData, setDynamicStatusColumns, setAllLeadStatusCount]);

    const userReportColumns = [
        {
            Header: 'User',
            accessor: 'user',
        },
        {
            Header: 'Leads',
            accessor: 'assigned',
        },
        {
            Header: 'Source of Lead',
            columns: dynamicSourceColumns,
        },
        {
            Header: 'Status',
            columns: dynamicStatusColumns,
        }
    ];

    const onSubmit = (data) => {
        setAllLeadAssignedUserData([]);
        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': data.start_date, 'end_date': data.end_date };
        const requestData = encryptData(params);
        const response = actions.getUserReportData(requestData, false);
        response.then((result) => {
            if (result.data.success === 1) {
                const results = result.data.data;
                if ('lead_assigned_user' in results) {

                    setAllLeadAssignedUserData(results.lead_assigned_user); // Set new data
                }
            }
        }).catch((error) => {
            toast.error(error, {
                position: "top-right",
            });
        });
    };    

    // console.log("userReportColumns : ", userReportColumns);
    // console.log("allLeadAssignedUserData : ", allLeadAssignedUserData);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = allLeadAssignedUserData.filter(item =>
        item.user.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

    return (
        <>
            <Helmet>
                <title>User Report</title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    User Report
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='main-wrap' className='mb-3 filter'>
                        <div className='row mx-0'>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="start-date">
                                        <span>Start Date</span>
                                        <input type='date' name='start_date' className='form-control' {...register('start_date', { required: true })} />
                                    </label>
                                    {errors.start_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="end-date">
                                        <span>End Date</span>
                                        <input type='date' name='end_date' className='form-control' {...register('end_date', { required: true })} />
                                    </label>
                                    {errors.end_date && <span className="required">This field is required.</span>}
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div className='row'>
                    <div className='col-md-12'>
                        <div id='main-wrap' className='mb-4 search-filter'>
                            <Typography variant="h4" sx={{ mb: 5 }}>
                                Leads Assigned to All Users
                            </Typography>
                            <div className="table-responsive table-wrap">
                                <table className="table leads-assigned table-hover">
                                    <thead>
                                        <tr className='border-top border-bottom'>
                                            <th className='bg-white border-right text-nowrap py-2'> User Name </th>
                                            <th className='bg-white border-right text-nowrap py-2'> #of Active Leads </th>
                                            <th className='bg-white border-right text-nowrap py-2' colSpan={allLeadSourceCount}>Leads by Source</th>
                                            <th className='bg-white border-right text-nowrap py-2' colSpan={allLeadStatusCount}>Leads by Status</th>
                                        </tr>
                                        <tr>
                                            <th className='border-right' />
                                            <th className='border-right' />
                                            {allLeadSource.map(item => (
                                                <th key={item.id} className='lbs'>{item.name}</th>
                                            ))}

                                            {allLeadStatusData.map(item => (
                                                <th key={item.id}>{item.name}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedItems.map((user, index) => (
                                            <tr key={index}>
                                                <td className='border-right'>{user.user}</td>
                                                <td className='border-right'>{user.assigned}</td>
                                                {allLeadSource.map(item => (
                                                    <td key={item.id} className='lbs'>
                                                        {user.source_of_lead && user.source_of_lead[item.name] !== undefined
                                                            ? user.source_of_lead[item.name]
                                                            : "0"}
                                                    </td>
                                                ))}

                                                {allLeadStatusData.map(item => (
                                                    <td key={item.id}>
                                                        {user.status && item.name in user.status ? user.status[item.name] : "0"}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <nav className='mb-1'>
                                <ul className="pagination pagination-md justify-content-end">
                                    {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                                        <li className="page-item">
                                            <button
                                                key={page}
                                                onClick={() => handlePageChange(page)}
                                                disabled={currentPage === page}
                                                className="page-link"
                                            >
                                                {page}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>


                <div className='row hide'>
                    <div className='col-md-12'>
                        <div id='main-wrap' className='mb-4 search-filter'>
                            <h5>Leads Assigned to All Users</h5>

                            {allLeadAssignedUserData.length > 0 ? (
                                <DataTableExtensions
                                    exportHeaders
                                    columns={userReportColumns}
                                    data={allLeadAssignedUserData}
                                >
                                    <DataTable
                                        noHeader
                                        defaultSortField="user"
                                        defaultSortAsc={false}
                                        pagination
                                        highlightOnHover
                                    />
                                </DataTableExtensions>
                            ) : (
                                <p>Loading data...</p>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        leadProfileInfo: state.leadProfileInfo,
        sourceData: state.sourceData,
        leadCountData: state.leadCountData,
        leadStatusData: state.leadStatusData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, leadActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserReportPage);