import { useState, useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Multiselect from 'multiselect-react-dropdown';
import { toast } from "react-toastify";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as beatPlanActions from "../../actions/beatPlanActions";
import * as campaignsActions from "../../actions/campaignsActions";
import Loader from "../../components/Loader";

import AccessDenied from '../common/AccessDenied';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const AddEditBeatPlan = (props) => {
    const { id } = useParams();
    const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);

    const [addModulePermissionStatus, setAddModulePermissionStatus] = useState(false);
    const [editModulePermissionStatus, setEditModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);
    const [permissions, setPermissions] = useState([]);

    const [getAllChildsData, setAllChildsData] = useState([]);
    const [allVisitStatusData, setAllVisitStatusData] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);

    const [allBeatPlans, setAllBeatPlans] = useState([]);

    const [beatPlanId, setBeatPlanId] = useState('');
    const [showBeatPlanDetails, setShowBeatPlanDetails] = useState(false);
    const [selecteduser, setSelectedUser] = useState('');
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [formValues, setFormValues] = useState({
        assigned_user : '',
        company_name : '',
        mobile : '',
        scheduled_time : '',
        contact_name : '',
        visit_type : '',
        remark : '',
        address : '',
        latitude : '',
        longitude : '',
        visit_status : ''
    });

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const beatPlanPermissions = permissionsData.find(permission => permission.module_name === 'Beat Plan');
                const hasAddPermissions = beatPlanPermissions?.add_permission;
                const hasEditPermissions = beatPlanPermissions?.edit_permission;

                setAddModulePermissionStatus(hasAddPermissions);
                setEditModulePermissionStatus(hasEditPermissions);
                setUserHasPermission(id !== undefined ? hasEditPermissions : hasAddPermissions);
            }
        }).catch((error) => {
            console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission]);

    useEffect(() => {
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        actions.usersData(requestData);

        if (usersData.success) {
            setAllUsers(usersData.data);
        }
    }, []);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });


    const fetchBeatPlan = useCallback(() => {
        setShowBeatPlanDetails(false);

        if (id !== undefined && !showBeatPlanDetails) {

            // console.log("fetchBeatPlan id : ", id);

          const decodedId = atob(id);
          setBeatPlanId(decodedId);
    
          const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'beat_plan_id': decodedId };

          const requestData = encryptData(obj);
    
          const response = actions.getBeatPlanData(requestData, false);
          response.then((result) => {
            // console.log("Promise resolved -  getBeatPlanData data:", result);
            if (result.data.success === 1) {
                setShowBeatPlanDetails(true);
                const beatPlanInfo = result.data.data.beat_plan_data;

                // setValue("assigned_user", beatPlanInfo[0].assigned_user);
                // setValue("company_name", beatPlanInfo[0].company_name);
                // setValue("mobile", beatPlanInfo[0].mobile);
                // setValue("scheduled_time", beatPlanInfo[0].scheduled_time);
                // setValue("contact_name", beatPlanInfo[0].contact_name);
                // setValue("visit_type", beatPlanInfo[0].visit_type);
                // setValue("remark", beatPlanInfo[0].remarks);
                // setValue("address", beatPlanInfo[0].address);
                // setValue("latitude", beatPlanInfo[0].latitude);
                // setValue("longitude", beatPlanInfo[0].longitude);
                // setValue("visit_status", beatPlanInfo[0].visit_status);

                const initialFormValues = {
                    assigned_user : beatPlanInfo[0].assigned_user,
                    company_name : beatPlanInfo[0].company_name,
                    mobile : beatPlanInfo[0].mobile,
                    scheduled_time : beatPlanInfo[0].scheduled_time,
                    contact_name : beatPlanInfo[0].contact_name,
                    visit_type : beatPlanInfo[0].visit_type,
                    remark : beatPlanInfo[0].remarks,
                    address : beatPlanInfo[0].address,
                    latitude : beatPlanInfo[0].latitude,
                    longitude : beatPlanInfo[0].longitude,
                    visit_status : beatPlanInfo[0].visit_status
                };
               
                setFormValues(initialFormValues);

                setSelectedUser(beatPlanInfo[0].assigned_user);
                handleAssignedUser({ target: { value: beatPlanInfo[0].assigned_user } });
            }
          }).catch((error) => {
            //  console.error("Promise rejected -  getBeatPlanData data :", error);
          });
    
        }

        if (id === undefined) {
            
            const initialFormValues = {
                assigned_user : '',
                company_name : '',
                mobile : '',
                scheduled_time : '',
                contact_name : '',
                visit_type : '',
                remark : '',
                address : '',
                latitude : '',
                longitude : '',
                visit_status : ''
            };

            setFormValues(initialFormValues);
            setSelectedUser('');

            setAllBeatPlans([]);
        }

    }, [id, setValue, setShowBeatPlanDetails, setFormValues, setSelectedUser]);
    
    

    useEffect(() => {
        // setAllLeadStatusData(false);
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getLoggedinUserChild(requestData, false);

        response.then((result) => {
            // console.log("Promise resolved -  lead Status data:", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                setAllChildsData(results);
            }
        }).catch((error) => {
            //  console.error("Promise rejected -  source data :", error); 
        });
    }, [setAllChildsData]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.campaignData(requestData, false);
        response.then((result) => {
        //   console.log("Promise resolved -  campaignData:", result);
          if (result.data.success === 1) {
            const results = result.data.data.data;
            const resultArr = Object.keys(results).map((key) => results[key]);
            const filteredResultArr = resultArr.filter((item) => item.status === "1");
            const sortedResultArr = filteredResultArr.sort((a, b) =>
                a.campaign_name.localeCompare(b.campaign_name)
            );
            setAllCampaigns(sortedResultArr);
          }
        }).catch((error) => {
          //  console.error("Promise rejected -  campaignData :", error); 
        });
    
    }, [setAllCampaigns]);


    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getVisitStatusData(requestData,false);
        response.then((result) => {
            // console.log("Promise resolved - getVisitStatusData: ", result); 
            if (result.data.success === 1) {
                const results = result.data.data.data;
                const resultArr = Object.keys(results).filter((key) => results[key].status === "1").map((key) => results[key]);
                setAllVisitStatusData(resultArr);
            }
        }).catch((error) => {
            //  console.error("Promise rejected - getVisitStatusData: ", error); 
        });
    }, [setAllVisitStatusData]);

    const onSubmit = (data) => {
        // console.log("data : ", data);
        
        if(selecteduser.trim() === ''){
            toast.error("Assigned to is required.", {
                position: "top-right",
            });
        } else if(formValues.mobile.trim() === ''){
            toast.error("Phone Number is required.", {
                position: "top-right",
            });
        } else if(formValues.scheduled_time.trim() === ''){
            toast.error("Scheduled Time is required.", {
                position: "top-right",
            });
        } else if(formValues.visit_type.trim() === ''){
            toast.error("Visit Type is required.", {
                position: "top-right",
            });
        } else if(formValues.address.trim() === ''){
            toast.error("Address is required.", {
                position: "top-right",
            });
        } else {
            const params = {
                "assigned_user": selecteduser,
                "company_name": formValues.company_name,
                "mobile": formValues.mobile,
                "scheduled_time": formValues.scheduled_time,
                "contact_name": formValues.contact_name,
                "visit_type": formValues.visit_type,
                "remark": formValues.remark,
                "address": formValues.address,
                "latitude": formValues.latitude,
                "longitude": formValues.longitude,
                // "visit_status": formValues.visit_status,
            }

            if (beatPlanId !== undefined && beatPlanId !== '') {
                params.beat_plan_id = beatPlanId;
            }

            const requestData = encryptData(params);
            const response = actions.addEditVisitePlan(requestData);

            response.then((result) => {
                // console.log("Promise resolved - addEditVisitePlan form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setTimeout(() => {
                        navigate('/dashboard/beat-plan');
                    }, 1000);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            }).catch((error) => {
                // console.error("Promise rejected - addEditVisitePlan form:", error);
                toast.error(error, {
                    position: "top-right",
                });
            });

        }
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleAssignedUser = (event) => {

        const selecteduserId = event.target.value;
        setSelectedUser(selecteduserId);
        const today = new Date();
        const todayFormatted = formatDate(today);
        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'assigned_user': selecteduserId, 'today_date': todayFormatted};

        const requestData = encryptData(params);
        const response = actions.getTodayBeatPlanData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getTodayBeatPlanData: ", result);
            if (result.data.success === 1) {
                const results = result.data.data.visit_data;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setAllBeatPlans(resultArr);
            } else {
                setAllBeatPlans([]);
            }
        }).catch((error) => {
            // console.error("Promise rejected -  getTodayBeatPlanData: ", error);
        });
    }

    useEffect(() => {
        fetchBeatPlan();
    },[fetchBeatPlan]);

    const getBeatPlanById = (beatPlanId) => {

        if (beatPlanId !== undefined && beatPlanId !== '') {

            setPreloaderStatus(true);

            setBeatPlanId(beatPlanId);
            const obj = { 'start': '0', 'length': config.MAX_RECORDS, 'beat_plan_id': beatPlanId };

            const requestData = encryptData(obj);

            const response = actions.getBeatPlanData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  getBeatPlanData data:", result);
                if (result.data.success === 1) {
                    setShowBeatPlanDetails(true);
                    setPreloaderStatus(false);
                    const beatPlanInfo = result.data.data.beat_plan_data;

                    const initialFormValues = {
                        assigned_user: beatPlanInfo[0].assigned_user,
                        company_name: beatPlanInfo[0].company_name,
                        mobile: beatPlanInfo[0].mobile,
                        scheduled_time: beatPlanInfo[0].scheduled_time,
                        contact_name: beatPlanInfo[0].contact_name,
                        visit_type: beatPlanInfo[0].visit_type,
                        remark: beatPlanInfo[0].remarks,
                        address: beatPlanInfo[0].address,
                        latitude: beatPlanInfo[0].latitude,
                        longitude: beatPlanInfo[0].longitude,
                        visit_status: beatPlanInfo[0].visit_status
                    };

                    setFormValues(initialFormValues);

                    setSelectedUser(beatPlanInfo[0].assigned_user);
                    handleAssignedUser({ target: { value: beatPlanInfo[0].assigned_user } });
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                //  console.error("Promise rejected -  getBeatPlanData data :", error);
            });
            setPreloaderStatus(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }
    return (
        <>
            <Helmet>
                <title>{showBeatPlanDetails ? "Edit Beat Plan | Ckers " : "Create Beat Plan | Ckers "}</title>
            </Helmet>

            <Container>
                <div className="row mb-4">
                    <div className="col-md-6">
                        <Typography variant="h4" sx={{ mb: 5 }}>
                            {showBeatPlanDetails ? "Edit Beat Plan" : "Create Beat Plan"}
                        </Typography>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div id='main-wrap'>
                                <div className="row">

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="assigned-to">
                                                <span>Assigned to <a style={{ color: "red" }}>*</a></span>
                                                <select id="assigned-to" className="form-select" {...register('assigned_user')} value={selecteduser} onChange={handleAssignedUser}>
                                                    <option value =''>Select User</option>
                                                    {getAllChildsData.length > 0 && getAllChildsData.map((option) => (
                                                        <option value={option.user_id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                            {errors.assigned_user && <span className="required">This field is required.</span>}
                                        </div>
                                    </div> 

                                    <div className='col-12'>
                                        <div className='form-group'>
                                            <label htmlFor="company-name">
                                                <span>Company Name</span>
                                                <input type='text' id='company_name' className='form-control' {...register('company_name')}
                                                    value={formValues.company_name}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            {errors.company_name && <span className="required">This field is required.</span>}
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="Phone-Number">
                                                <span>Phone Number <a style={{ color: "red" }}>*</a></span>
                                                <input type="tel" id="Phone-Number" className="form-control" {...register('mobile')}
                                                    value={formValues.mobile}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            {errors.mobile && <span className="required">This field is required.</span>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="scheduled-time">
                                                <span>Scheduled Time <a style={{ color: "red" }}>*</a></span>
                                                <input type="datetime-local" id="scheduled-time" className="form-control" {...register('scheduled_time')}
                                                    value={formValues.scheduled_time}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            {errors.scheduled_time && <span className="required">This field is required.</span>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="contact-name">
                                                <span>Contact Name</span>
                                                <input type="text" id="contact-name" className="form-control" {...register('contact_name')}
                                                    value={formValues.contact_name}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="visit-type">
                                                <span>Visit Type <a style={{ color: "red" }}>*</a></span>
                                                <select id="visit-type" className="form-select" {...register('visit_type')}
                                                    value={formValues.visit_type}
                                                    onChange={handleChange}
                                                >
                                                    <option value=''>Select Visit Type</option>
                                                    {allCampaigns.map((option) => (
                                                        <option value={option.id}>
                                                            {option.campaign_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                            {errors.campaign_name && <span className="required">This field is required.</span>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="remark">
                                                <span>Remark</span>
                                                <textarea type="text" className="form-control" id="remark" placeholder="Type remark...." {...register('remark')}
                                                    value={formValues.remark}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="address">
                                                <span>Address <a style={{ color: "red" }}>*</a></span>
                                                <textarea type="text" className="form-control" id="address" placeholder="Type address..." {...register('address')}
                                                    value={formValues.address}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            {errors.address && <span className="required">This field is required.</span>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="latitude">
                                                <span>
                                                    Latitude&nbsp;
                                                    <a href="https://maps.google.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'none' }}>Map&nbsp;</a>
                                                    <a href="https://support.google.com/maps/answer/18539?hl=en" target="_blank" rel="noopener noreferrer" data-toggle="tooltip" title="" style={{ color: "red", textDecoration: 'none' }} data-original-title="How to find Latitude">?</a>
                                                    &nbsp;</span>
                                                <input type="text" id="latitude" className="form-control" {...register('latitude')}
                                                    value={formValues.latitude}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            {errors.latitude && <span className="required">This field is required.</span>}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="longitude">
                                                <span>
                                                    Longitude&nbsp;
                                                    <a href="https://maps.google.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'none' }}>Map&nbsp;</a>
                                                    <a href="https://support.google.com/maps/answer/18539?hl=en" target="_blank" rel="noopener noreferrer" data-toggle="tooltip" title="" style={{ color: "red", textDecoration: 'none' }} data-original-title="How to find Longitude">?</a>
                                                    &nbsp;</span>
                                                <input type="text" id="longitude" className="form-control" {...register('longitude')}
                                                    value={formValues.longitude}
                                                    onChange={handleChange}
                                                />
                                            </label>
                                            {errors.longitude && <span className="required">This field is required.</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 d-none">
                                        <div className="form-group">
                                            <label htmlFor="assigned-to">
                                                <span>Visit Status</span>
                                                <select id="assigned-to" className="form-select" {...register('visit_status')}
                                                    value={formValues.visit_status}
                                                    onChange={handleChange}
                                                >
                                                    <option value =''>Select User</option>
                                                    {allVisitStatusData.length > 0 && allVisitStatusData.map((option) => (
                                                        <option value={option.id}>
                                                            {option.visit_status_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                            {errors.visit_status && <span className="required">This field is required.</span>}
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group mb-2">
                                            <button className="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className={allBeatPlans.length === 0 || allBeatPlans === null ? "d-none col-md-6" : "col-md-6"}>
                        <Typography variant="h4" sx={{ mb: 5 }}>
                            Today's Plan
                        </Typography>
                        <div id="main-wrap">
                            {allBeatPlans.length > 0 ? (
                                allBeatPlans.map((beatPlan, index) => (
                                    <div key={index} className="row lead-activity-item mb-2 mx-0">
                                        <div className="col-6 ps-0">
                                            <p className='mb-0 fw-bold'>
                                                {beatPlan.company_name}
                                            </p>
                                        </div>

                                        <div className="col-4 pe-0 text-end">
                                            <p className='mb-0'>
                                            <span
                                            className={(beatPlan.visit_status === '2') ? "badge text-bg-success text-white" : (beatPlan.visit_status === '1') ? "badge text-bg-danger text-white" : "badge text-bg-warning text-white"}
                                            >{beatPlan.visitStatusName}</span>
                                            </p>
                                        </div>

                                        <div className="col-2 pe-0 text-end">
                                            <div className="btn-group dropstart" title="Edit">
                                                <NavLink onClick={() => getBeatPlanById(beatPlan.id)}><i className="bi bi-pen" style={{ color: "black" }} />{}</NavLink>
                                            </div>
                                        </div>

                                        
                                    </div>
                                ))
                            ) : (
                                <p>No data available.</p>
                            )}
                        </div>
                    </div>
                </div>

                {preloaderStatus && <Loader />}


            </Container>
        </>
    );
}

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
        getVisitStatusData: state.getVisitStatusData,
        getUserModuleData: state.getUserModuleData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, beatPlanActions.default, campaignsActions.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditBeatPlan);