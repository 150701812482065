
import react, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Link as RouterLink, NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Typography } from '@mui/material';
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import * as leadActions from "../../actions/leadActions";
import * as masterAction from "../../actions/masterAction";
import * as reportActions from "../../actions/reportActions";


const LoanVersioning = (props) => {
  const { vitem, actions } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const [allEMIsArr, setAllEMIsArr] = useState([]);
  const [allModifiedEMIsArr, setAllModifiedEMIsArr] = useState([]);

  const [activeTabName, setActiveTabName] = useState('schedule');
  const [loanDetailsArr, setLoanDetailsArr] = useState([]);
  const [loanDetailsModifiedArr, setLoanDetailsModifiedArr] = useState([]);
  const [additionalMissingData, setAdditionalMissingData] = useState([]);
  
  const [ preloaderStatus, setPreloaderStatus] = useState(false);

  const [formValues, setFormValues] = useState({
    priority_type: '',
    type_topic: '',
    residence_status: '',
    profile: '',
    msme: '',
    stability: '',
    sector_of_unit: '',
    sub_sector: '',
    business_of_occupation: '',
    turnover: '',
    dl_number: '',
    dl_status: '',
    category: '',
    region: '',
    city_stability: '',
    residence_stability: '',
    business_stability: '',
    driving_experience: '',
    product: '',
    relation_with_applicant: '',
    battery_number: '',
    deployment_of_asset: '',
    warranty_detail: '',
  });

  // Handler for EMIs Schedule tab
  const backToScheduleHandler = () => {
    setActiveTabName('schedule');
  };

  // Handler for Additional Data tab
  const backToAdditionalHandler = () => {
    setActiveTabName('additional');
  };

  useEffect(() => {
    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'RepaymentSchedules')) {
      setAllEMIsArr(vitem.RepaymentSchedules);
    }

    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'comparedEmisDetails')) {

      const comparedEmisDetails = vitem.comparedEmisDetails;
      if (comparedEmisDetails && Object.prototype.hasOwnProperty.call(comparedEmisDetails, 'modifiedJSONInfo')) {
        setAllModifiedEMIsArr(comparedEmisDetails.modifiedJSONInfo);

        console.log("comparedEmisDetails.modifiedJSONInfo = ", comparedEmisDetails.modifiedJSONInfo);
      }
    }

    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'loanDetails')) {
      setLoanDetailsArr(vitem.loanDetails);
    }

    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'compareLoanDetails')) {
      const compareLoanDetails = vitem.compareLoanDetails;
      setLoanDetailsModifiedArr(compareLoanDetails);
    }

    if (vitem && Object.prototype.hasOwnProperty.call(vitem, 'additionalMissingData')) {
      setAdditionalMissingData(vitem.additionalMissingData);
    }


  }, [setAllEMIsArr, setAllModifiedEMIsArr, setLoanDetailsArr, setLoanDetailsModifiedArr, setAdditionalMissingData]);

  const formatDate = (date) => {
    if (date === null || date === '' || date === undefined) {
      return '';
    }

    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;
  };

  const moneyFormatIndia = (number) => {
    if (number !== undefined && number !== '' && !Number.isNaN(Number(number))) {
      number = parseFloat(number);

      number = number.toFixed(2);

      const [integerPart, decimalPart] = number.split('.');

      const lastThreeDigits = integerPart.slice(-3);
      const otherDigits = integerPart.slice(0, -3);

      let formattedIntegerPart;
      if (otherDigits !== '') {
        formattedIntegerPart = `${otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${lastThreeDigits}`;
      } else {
        formattedIntegerPart = lastThreeDigits;
      }

      return `${formattedIntegerPart}.${decimalPart}`;
    }

    return '0.00';
  };

  const formatNumbersInString = (numberString) => {
    // Split the string into an array of number strings
    const numberArray = numberString.split(',').map(num => num.trim());

    // Format each number and join them back into a string
    const formattedNumbers = numberArray.map(num => moneyFormatIndia(num));

    // Join the formatted numbers into a single string
    return formattedNumbers.join(', ');
  };

  const formatDate2 = (dateTime) => {
    if (!dateTime) {
        return "";
    }
    const dateParts = dateTime.split(' ');
    return dateParts.length > 0 ? dateParts[0] : "";
  };

  const [allProfileMaster, setAllProfileMaster] = useState([]);
  const [allSectorOfUnitMaster, setAllSectorOfUnitMaster] = useState([]);
  const [allDlStatusMaster, setAllDlStatusMaster] = useState([]);
  const [allCategoryMaster, setAllCategoryMaster] = useState([]);
  const [allRegionMaster, setAllRegionMaster] = useState([]);
  const [allProductMaster, setAllProductMaster] = useState([]);
  const [allResidenceStatusMaster, setAllResidenceStatusMaster] = useState([]);

  useEffect(() => {
    const obj = { 'start': '0', 'length': config.MAX_RECORDS };
    const requestData = encryptData(obj);
    const response = actions.getAllAdditionalMissingData(requestData,false);
    response.then((result) => {
        // console.log("Promise resolved -  getAllAdditionalMissingData list:", result);
        if (result.data.success === 1) {
                const results = result.data.data;

                if(Object.prototype.hasOwnProperty.call(results, 'profile_master')){
                  setAllProfileMaster(results.profile_master);
                }

                if(Object.prototype.hasOwnProperty.call(results, 'sector_of_unit_master')){
                    setAllSectorOfUnitMaster(results.sector_of_unit_master);
                }

                if(Object.prototype.hasOwnProperty.call(results, 'dl_status_master')){
                    setAllDlStatusMaster(results.dl_status_master);
                }

                if(Object.prototype.hasOwnProperty.call(results, 'category_master')){
                    setAllCategoryMaster(results.category_master);
                }

                if(Object.prototype.hasOwnProperty.call(results, 'region_master')){
                    setAllRegionMaster(results.region_master);
                }

                if(Object.prototype.hasOwnProperty.call(results, 'product_master')){
                    setAllProductMaster(results.product_master);
                }

                if(Object.prototype.hasOwnProperty.call(results, 'residence_status_master')){
                    setAllResidenceStatusMaster(results.residence_status_master);
                }
            }
        }).catch((error) => {
            console.error("Promise rejected -  getAllAdditionalMissingData :", error);
        });   
    
}, [setAllProfileMaster, setAllSectorOfUnitMaster, setAllDlStatusMaster, setAllCategoryMaster, setAllRegionMaster, setAllProductMaster, setAllResidenceStatusMaster]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
        ...formValues,
        [name]: value,
    });

  };
  useEffect(() => {

    if(additionalMissingData){

      setValue("residence_status", additionalMissingData.residence_status);
      setValue("priority_type", additionalMissingData.priority_type);
      setValue("type_topic", additionalMissingData.type_topic);
      setValue("profile", additionalMissingData.profile);
      setValue("msme", additionalMissingData.msme);
      setValue("stability", additionalMissingData.stability);
      setValue("sector_of_unit", additionalMissingData.sector_of_unit);
      setValue("sub_sector", additionalMissingData.sub_sector);
      setValue("business_of_occupation", additionalMissingData.business_of_occupation);
      setValue("turnover", additionalMissingData.turnover);
      setValue("dl_number", additionalMissingData.dl_number);
      setValue("dl_status", additionalMissingData.dl_status);
      setValue("category", additionalMissingData.category);
      setValue("region", additionalMissingData.region);
      setValue("city_stability", additionalMissingData.city_stability);
      setValue("business_stability", additionalMissingData.business_stability);
      setValue("driving_experience", additionalMissingData.driving_experience);
      setValue("product", additionalMissingData.product);
      setValue("relation_with_applicant", additionalMissingData.relation_with_applicant);
      setValue("battery_number", additionalMissingData.battery_number);
      setValue("deployment_of_asset", additionalMissingData.deployment_of_asset);
      setValue("warranty_detail", additionalMissingData.warranty_detail);
      setValue("residence_stability", additionalMissingData.residence_stability);

      setValue("insurance_validity_date", formatDate2(additionalMissingData.insurance_validity_date));
      setValue("sanction_date", formatDate2(additionalMissingData.sanction_date));

      const initialFormValues = {
        priority_type: additionalMissingData.priority_type,
        type_topic: additionalMissingData.type_topic,
        residence_status: additionalMissingData.residence_status,
        profile: additionalMissingData.profile,
        msme: additionalMissingData.msme,
        insurance_validity_date: additionalMissingData.insurance_validity_date,
        sanction_date: additionalMissingData.sanction_date,
        stability: additionalMissingData.stability,
        sector_of_unit: additionalMissingData.sector_of_unit,
        sub_sector: additionalMissingData.sub_sector,
        business_of_occupation: additionalMissingData.business_of_occupation,
        turnover: additionalMissingData.turnover,
        dl_number: additionalMissingData.dl_number,
        dl_status: additionalMissingData.dl_status,
        category: additionalMissingData.category,
        region: additionalMissingData.region,
        city_stability: additionalMissingData.city_stability,
        residence_stability: additionalMissingData.residence_stability,
        business_stability: additionalMissingData.business_stability,
        driving_experience: additionalMissingData.driving_experience,
        product: additionalMissingData.product,
        relation_with_applicant: additionalMissingData.relation_with_applicant,
        battery_number: additionalMissingData.battery_number,
        deployment_of_asset: additionalMissingData.deployment_of_asset,
        warranty_detail: additionalMissingData.warranty_detail,
      };
   
    setFormValues(initialFormValues);
    }
  }, [setValue, additionalMissingData]);

  const onSubmit = (data) => {

    const params = {
      'agreementNo': vitem.AgreementNo,
      "priority_type": formValues.priority_type,
      "type_topic": formValues.type_topic,
      "residence_status": formValues.residence_status,
      "profile": formValues.profile,
      "msme": formValues.msme,
      "insurance_validity_date": data.insurance_validity_date,
      "sanction_date": data.sanction_date,
      "stability": formValues.stability,
      "sector_of_unit": formValues.sector_of_unit,
      "sub_sector": formValues.sub_sector,
      "business_of_occupation": formValues.business_of_occupation,
      "turnover": formValues.turnover,
      "dl_number": formValues.dl_number,
      "dl_status": formValues.dl_status,
      "category": formValues.category,
      "region": formValues.region,
      "city_stability": formValues.city_stability,
      "residence_stability": formValues.residence_stability,
      "business_stability": formValues.business_stability,
      "driving_experience": formValues.driving_experience,
      "product": formValues.product,
      "relation_with_applicant": formValues.relation_with_applicant,
      "battery_number": formValues.battery_number,
      "deployment_of_asset": formValues.deployment_of_asset,
      "warranty_detail": formValues.warranty_detail
    }

    const requestData = encryptData(params);
    const response = actions.addEditAdditionalMissingData(requestData, false);
    response.then((result) => {
      // console.log("Promise resolved -  lead list:", result);
      if (result.data.success === 1) {
        setPreloaderStatus(false);
        toast.success(result.data.message, {
          position: "top-right",
        });
      } else {
        toast.error(result.data.message, {
          position: "top-right",
        });
      }
    }).catch((error) => {
      setPreloaderStatus(false);
      console.error("Promise rejected -  lead list:", error);
    });
  }

  return (
    <>

      <div className="mt-4 portlet light d-none">
        <h6 className="  portlet-title">Loan Details</h6>
        <div className="row row mx-0">
          <div className="col-md-4">
            <div className="">
              <div><b>FinanceId : </b></div>
              {loanDetailsArr && loanDetailsArr.FinanceId !== undefined ? (
                <>{loanDetailsArr.FinanceId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>CompanyRoleId : </b></div>
              {loanDetailsArr && loanDetailsArr.CompanyRoleId !== undefined ? (
                <>{loanDetailsArr.CompanyRoleId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LoanType : </b></div>
              {loanDetailsArr && loanDetailsArr.LoanType !== undefined ? (
                <>{loanDetailsArr.LoanType}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>AssetId : </b></div>
              {loanDetailsArr && loanDetailsArr.AssetId !== undefined ? (
                <>{loanDetailsArr.AssetId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>TotalAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalAmount !== undefined ? (
                <>{loanDetailsArr.TotalAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>Duration : </b></div>
              {loanDetailsArr && loanDetailsArr.Duration !== undefined ? (
                <>{loanDetailsArr.Duration}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>Interest : </b></div>
              {loanDetailsArr && loanDetailsArr.Interest !== undefined ? (
                <>{loanDetailsArr.Interest}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>EMI : </b></div>
              {loanDetailsArr && loanDetailsArr.EMI !== undefined ? (
                <>{loanDetailsArr.EMI}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>YearlyInterest : </b></div>
              {loanDetailsArr && loanDetailsArr.YearlyInterest !== undefined ? (
                <>{loanDetailsArr.YearlyInterest}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>StartDate : </b></div>
              {loanDetailsArr && loanDetailsArr.StartDate !== undefined ? (
                <>{loanDetailsArr.StartDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LoanClosureDate : </b></div>
              {loanDetailsArr && loanDetailsArr.LoanClosureDate !== undefined ? (
                <>{loanDetailsArr.LoanClosureDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>DPDDays : </b></div>
              {loanDetailsArr && loanDetailsArr.DPDDays !== undefined ? (
                <>{loanDetailsArr.DPDDays}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>AgreementNo : </b></div>
              {loanDetailsArr && loanDetailsArr.AgreementNo !== undefined ? (
                <>{loanDetailsArr.AgreementNo}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>FutureValue : </b></div>
              {loanDetailsArr && loanDetailsArr.FutureValue !== undefined ? (
                <>{loanDetailsArr.FutureValue}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>InsuranceAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.InsuranceAmount !== undefined ? (
                <>{loanDetailsArr.InsuranceAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>InsuranceDuration : </b></div>
              {loanDetailsArr && loanDetailsArr.InsuranceDuration !== undefined ? (
                <>{loanDetailsArr.InsuranceDuration}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>IndicativeROI : </b></div>
              {loanDetailsArr && loanDetailsArr.IndicativeROI !== undefined ? (
                <>{loanDetailsArr.IndicativeROI}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>SendSMS : </b></div>
              {loanDetailsArr && loanDetailsArr.SendSMS !== undefined ? (
                <>{loanDetailsArr.SendSMS}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>SchemeId : </b></div>
              {loanDetailsArr && loanDetailsArr.SchemeId !== undefined ? (
                <>{loanDetailsArr.SchemeId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>EMITypeId : </b></div>
              {loanDetailsArr && loanDetailsArr.EMITypeId !== undefined ? (
                <>{loanDetailsArr.EMITypeId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LPCAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.LPCAmount !== undefined ? (
                <>{loanDetailsArr.LPCAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LPCType : </b></div>
              {loanDetailsArr && loanDetailsArr.LPCType !== undefined ? (
                <>{loanDetailsArr.LPCType}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LPCInterest : </b></div>
              {loanDetailsArr && loanDetailsArr.LPCInterest !== undefined ? (
                <>{loanDetailsArr.LPCInterest}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>InstallmentTypeId : </b></div>
              {loanDetailsArr && loanDetailsArr.InstallmentTypeId !== undefined ? (
                <>{loanDetailsArr.InstallmentTypeId}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>ROIDuration : </b></div>
              {loanDetailsArr && loanDetailsArr.ROIDuration !== undefined ? (
                <>{loanDetailsArr.ROIDuration}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>SubLoanTypeForPL : </b></div>
              {loanDetailsArr && loanDetailsArr.SubLoanTypeForPL !== undefined ? (
                <>{loanDetailsArr.SubLoanTypeForPL}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>LoanCategory : </b></div>
              {loanDetailsArr && loanDetailsArr.LoanCategory !== undefined ? (
                <>{loanDetailsArr.LoanCategory}</>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>DownPayment : </b></div>
              {loanDetailsArr && loanDetailsArr.DownPayment !== undefined ? (
                <>{loanDetailsArr.DownPayment}</>
              ) : (
                <></>
              )}

            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>AdvanceEMI : </b></div>
              {loanDetailsArr && loanDetailsArr.AdvanceEMI !== undefined ? (
                <>{loanDetailsArr.AdvanceEMI}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>LoanSegmentId : </b></div>
              {loanDetailsArr && loanDetailsArr.LoanSegmentId !== undefined ? (
                <>{loanDetailsArr.LoanSegmentId}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>UTRNo : </b></div>
              {loanDetailsArr && loanDetailsArr.UTRNo !== undefined ? (
                <>{loanDetailsArr.UTRNo}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>DisbursementStatus : </b></div>
              {loanDetailsArr && loanDetailsArr.DisbursementStatus !== undefined ? (
                <>{loanDetailsArr.DisbursementStatus}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>NachStatusId : </b></div>
              {loanDetailsArr && loanDetailsArr.NachStatusId !== undefined ? (
                <>{loanDetailsArr.NachStatusId}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>UMRNNumber : </b></div>
              {loanDetailsArr && loanDetailsArr.UMRNNumber !== undefined ? (
                <>{loanDetailsArr.UMRNNumber}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>StatusId : </b></div>
              {loanDetailsArr && loanDetailsArr.StatusId !== undefined ? (
                <>{loanDetailsArr.StatusId}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>EMIStartDate : </b></div>
              {loanDetailsArr && loanDetailsArr.EMIStartDate !== undefined ? (
                <>{loanDetailsArr.EMIStartDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>EMIEndDate : </b></div>
              {loanDetailsArr && loanDetailsArr.EMIEndDate !== undefined ? (
                <>{loanDetailsArr.EMIEndDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>NextPaymentDate : </b></div>
              {loanDetailsArr && loanDetailsArr.NextPaymentDate !== undefined ? (
                <>{loanDetailsArr.NextPaymentDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>NextEMIDueDate : </b></div>
              {loanDetailsArr && loanDetailsArr.NextEMIDueDate !== undefined ? (
                <>{loanDetailsArr.NextEMIDueDate}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>ModeOfRePaymentId : </b></div>
              {loanDetailsArr && loanDetailsArr.ModeOfRePaymentId !== undefined ? (
                <>{loanDetailsArr.ModeOfRePaymentId}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>NoOfPaidEMI : </b></div>
              {loanDetailsArr && loanDetailsArr.NoOfPaidEMI !== undefined ? (
                <>{loanDetailsArr.NoOfPaidEMI}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalReceivedAmt : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalReceivedAmt !== undefined ? (
                <>{loanDetailsArr.TotalReceivedAmt}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalPrincipleRecvd : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalPrincipleRecvd !== undefined ? (
                <>{loanDetailsArr.TotalPrincipleRecvd}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalInterestPaid : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalInterestPaid !== undefined ? (
                <>{loanDetailsArr.TotalInterestPaid}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>EMIDueCount : </b></div>
              {loanDetailsArr && loanDetailsArr.EMIDueCount !== undefined ? (
                <>{loanDetailsArr.EMIDueCount}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalPrincipalDue : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalPrincipalDue !== undefined ? (
                <>{loanDetailsArr.TotalPrincipalDue}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalInterestDue : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalInterestDue !== undefined ? (
                <>{loanDetailsArr.TotalInterestDue}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalVASDues : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalVASDues !== undefined ? (
                <>{loanDetailsArr.TotalVASDues}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalDueAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalDueAmount !== undefined ? (
                <>{loanDetailsArr.TotalDueAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>LPIDues : </b></div>
              {loanDetailsArr && loanDetailsArr.LPIDues !== undefined ? (
                <>{loanDetailsArr.LPIDues}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>DefaultChrages : </b></div>
              {loanDetailsArr && loanDetailsArr.DefaultChrages !== undefined ? (
                <>{loanDetailsArr.DefaultChrages}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalEMIOverdueAmount : </b></div>
              {loanDetailsArr && loanDetailsArr.TotalEMIOverdueAmount !== undefined ? (
                <>{loanDetailsArr.TotalEMIOverdueAmount}</>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div><b>TotalLoanOverdueAmount : </b></div>

              {/* JavaScript logic */}
              {(() => {
                let totalLoanOverdueAmountUpdatedStatus = false;
                let totalLoanOverdueAmountDeletedStatus = false;
                let totalLoanOverdueAmountObj = {};

                if (loanDetailsModifiedArr && Object.prototype.hasOwnProperty.call(loanDetailsModifiedArr, 'TotalLoanOverdueAmount')) {
                  totalLoanOverdueAmountObj = loanDetailsModifiedArr.TotalLoanOverdueAmount;

                  if (totalLoanOverdueAmountObj && Object.prototype.hasOwnProperty.call(totalLoanOverdueAmountObj, 'old') && Object.prototype.hasOwnProperty.call(totalLoanOverdueAmountObj, 'new')) {
                    totalLoanOverdueAmountUpdatedStatus = true;
                  }

                  if (totalLoanOverdueAmountObj && Object.prototype.hasOwnProperty.call(totalLoanOverdueAmountObj, 'delete')) {
                    totalLoanOverdueAmountDeletedStatus = totalLoanOverdueAmountObj.delete;
                  }
                }

                return (
                  <>
                    {!totalLoanOverdueAmountDeletedStatus && totalLoanOverdueAmountUpdatedStatus ? (
                      <>
                        <div className="text-danger">Old:{(loanDetailsModifiedArr.old !== null && loanDetailsModifiedArr.old !== '') ? moneyFormatIndia(loanDetailsModifiedArr.old) : ''}</div>
                        <div className="text-success">New:{(loanDetailsModifiedArr.new !== null && loanDetailsModifiedArr.new !== '') ? moneyFormatIndia(loanDetailsModifiedArr.new) : ''}</div>
                      </>
                    ) : (loanDetailsArr && loanDetailsArr.TotalLoanOverdueAmount !== undefined ? (
                      <>{loanDetailsArr.TotalLoanOverdueAmount}</>
                    ) : (
                      <><div className='deletedRow'>Deleted</div></>
                    ))}
                  </>
                );
              })()}
            </div>
          </div>

          <div className="col-md-4">
            <div className="">
              <div><b>loanTotalDue : </b></div>

              {/* JavaScript logic */}
              {(() => {
                let loanTotalDueUpdatedStatus = false;
                let loanTotalDueDeletedStatus = false;
                let loanTotalDueObj = {};

                // console.log("loanDetailsModifiedArr : ", loanDetailsModifiedArr);

                if (loanDetailsModifiedArr && Object.prototype.hasOwnProperty.call(loanDetailsModifiedArr, 'LoanTotalDue')) {
                  loanTotalDueObj = loanDetailsModifiedArr.LoanTotalDue;

                  if (loanTotalDueObj && Object.prototype.hasOwnProperty.call(loanTotalDueObj, 'old') && Object.prototype.hasOwnProperty.call(loanTotalDueObj, 'new')) {
                    loanTotalDueUpdatedStatus = true;
                  }

                  if (loanTotalDueObj && Object.prototype.hasOwnProperty.call(loanTotalDueObj, 'delete')) {
                    loanTotalDueDeletedStatus = loanTotalDueObj.delete;
                  }
                }

                return (
                  <>
                    {loanTotalDueDeletedStatus ? (
                      <div className='deletedRow'>Deleted</div>
                    ) : loanTotalDueUpdatedStatus ? (
                      <>
                        <div className="text-danger">Old:{(loanDetailsModifiedArr.LoanTotalDue.old !== null && loanDetailsModifiedArr.LoanTotalDue.old !== '') ? moneyFormatIndia(loanDetailsModifiedArr.LoanTotalDue.old) : ''}</div>
                        <div className="text-success">New:{(loanDetailsModifiedArr.LoanTotalDue.new !== null && loanDetailsModifiedArr.LoanTotalDue.new !== '') ? moneyFormatIndia(loanDetailsModifiedArr.LoanTotalDue.new) : ''}</div>
                      </>
                    ) : (
                      <>{(loanDetailsArr.loanTotalDue !== null && loanDetailsArr.loanTotalDue !== '') ? moneyFormatIndia(loanDetailsArr.loanTotalDue) : ''}</>
                    )}
                  </>
                );
              })()}
            </div>
          </div>

        </div>
      </div>
      
      <div className="tab-content" id="pills-tabContent">
        <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={activeTabName === 'schedule' ? "nav-link active" : "nav-link"}
              id="pills-schedule-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-schedule"
              type="button"
              role="tab"
              aria-controls="pills-schedule"
              aria-selected={activeTabName === 'schedule' ? "true" : "false"}
              onClick={backToScheduleHandler}
            >
              EMIs Schedule
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={activeTabName === 'additional' ? "nav-link active" : "nav-link"}
              id="pills-additional-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-additional"
              type="button"
              role="tab"
              aria-controls="pills-additional"
              aria-selected={activeTabName === 'additional' ? "true" : "false"}
              onClick={backToAdditionalHandler}
            >
              Additional Data
            </button>
          </li>
        </ul>

        {/* Tab Content */}
        <div className={activeTabName === 'schedule' ? 'tab-pane fade show active' : 'tab-pane fade'} id="pills-schedule" role="tabpanel">
          <div className="mt-4 portlet light">
            <div className='table-wrap table-responsive'>
              <table className="table table-hover mt-4" id="exportLoansReportExcelFile" width="100%">
                <thead>
                  <tr>
                    <th className="text-nowrap">No</th>
                    <th className="text-nowrap">Due Amount</th>
                    <th className="text-nowrap">Principal</th>
                    <th className="text-nowrap">Due Date</th>
                    <th className="text-nowrap">Payment Status</th>
                    <th className="text-right">Paid Amount</th>
                    <th className="text-right">Payment Date</th>

                    <th className="text-nowrap">Interest</th>
                    <th className="text-nowrap">Principal OS</th>
                    <th className="text-nowrap">Pending Amount</th>
                    <th className="text-nowrap">Total Interest</th>
                    <th className="text-nowrap">Principal Round</th>
                    <th className="text-nowrap">Interest Round</th>

                  </tr>
                </thead>
                <tbody>
                  {allEMIsArr.map((item, index) => {

                    let deletedEmiStatus = false;
                    if (
                      allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'InstallmentNo')
                    ) {
                      const installmentObj = allModifiedEMIsArr[index].InstallmentNo;
                      if (
                        installmentObj &&
                        Object.prototype.hasOwnProperty.call(installmentObj, 'delete')
                      ) {
                        deletedEmiStatus = installmentObj.delete;
                      }

                    }


                    let principleUpdatedStatus = false;
                    let principleDeletedStatus = false;
                    let principalObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'Principal')) {
                      principalObj = allModifiedEMIsArr[index].Principal;
                      if (principalObj && Object.prototype.hasOwnProperty.call(principalObj, 'old') && Object.prototype.hasOwnProperty.call(principalObj, 'new')) {
                        principleUpdatedStatus = true;
                      }

                      if (principalObj && Object.prototype.hasOwnProperty.call(principalObj, 'delete')) {
                        principleDeletedStatus = principalObj.delete;
                      }
                    }



                    let dueAmountUpdatedStatus = false;
                    let dueAmountDeletedStatus = false;
                    let dueAmountObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'DueAmount')) {
                      dueAmountObj = allModifiedEMIsArr[index].DueAmount;
                      if (dueAmountObj && Object.prototype.hasOwnProperty.call(dueAmountObj, 'old') && Object.prototype.hasOwnProperty.call(dueAmountObj, 'new')) {
                        dueAmountUpdatedStatus = true;
                      }

                      if (dueAmountObj && Object.prototype.hasOwnProperty.call(dueAmountObj, 'delete')) {
                        dueAmountDeletedStatus = dueAmountObj.delete;
                      }
                    }

                    let paymentStatusUpdatedStatus = false;
                    let paymentStatusDeletedStatus = false;
                    let paymentStatusObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'PaymentStatus')) {
                      paymentStatusObj = allModifiedEMIsArr[index].PaymentStatus;
                      if (paymentStatusObj && Object.prototype.hasOwnProperty.call(paymentStatusObj, 'old') && Object.prototype.hasOwnProperty.call(paymentStatusObj, 'new')) {
                        paymentStatusUpdatedStatus = true;
                      }

                      if (paymentStatusObj && Object.prototype.hasOwnProperty.call(paymentStatusObj, 'delete')) {
                        paymentStatusDeletedStatus = paymentStatusObj.delete;
                      }
                    }


                    let paidAmountUpdatedStatus = false;
                    let paidAmountDeletedStatus = false;
                    let paidAmountObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'PaidAmount')) {
                      paidAmountObj = allModifiedEMIsArr[index].PaidAmount;
                      if (paidAmountObj && Object.prototype.hasOwnProperty.call(paidAmountObj, 'old') && Object.prototype.hasOwnProperty.call(paidAmountObj, 'new')) {
                        paidAmountUpdatedStatus = true;
                      }

                      if (paidAmountObj && Object.prototype.hasOwnProperty.call(paidAmountObj, 'delete')) {
                        paidAmountDeletedStatus = paidAmountObj.delete;
                      }
                    }

                    let interestUpdatedStatus = false;
                    let interestDeletedStatus = false;
                    let interestObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'Interest')) {
                      interestObj = allModifiedEMIsArr[index].Interest;
                      if (interestObj && Object.prototype.hasOwnProperty.call(interestObj, 'old') && Object.prototype.hasOwnProperty.call(interestObj, 'new')) {
                        interestUpdatedStatus = true;
                      }

                      if (interestObj && Object.prototype.hasOwnProperty.call(interestObj, 'delete')) {
                        interestDeletedStatus = interestObj.delete;
                      }
                    }

                    let principalOSUpdatedStatus = false;
                    let principalOSDeletedStatus = false;
                    let principalOSObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'PrincipalOS')) {
                      principalOSObj = allModifiedEMIsArr[index].PrincipalOS;
                      if (principalOSObj && Object.prototype.hasOwnProperty.call(principalOSObj, 'old') && Object.prototype.hasOwnProperty.call(principalOSObj, 'new')) {
                        principalOSUpdatedStatus = true;
                      }

                      if (principalOSObj && Object.prototype.hasOwnProperty.call(principalOSObj, 'delete')) {
                        principalOSDeletedStatus = principalOSObj.delete;
                      }
                    }

                    let pendingAmountUpdatedStatus = false;
                    let pendingAmountDeletedStatus = false;
                    let pendingAmountObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'PendingAmount')) {
                      pendingAmountObj = allModifiedEMIsArr[index].PendingAmount;
                      if (pendingAmountObj && Object.prototype.hasOwnProperty.call(pendingAmountObj, 'old') && Object.prototype.hasOwnProperty.call(pendingAmountObj, 'new')) {
                        pendingAmountUpdatedStatus = true;
                      }

                      if (pendingAmountObj && Object.prototype.hasOwnProperty.call(pendingAmountObj, 'delete')) {
                        pendingAmountDeletedStatus = pendingAmountObj.delete;
                      }
                    }

                    let totalInterestUpdatedStatus = false;
                    let totalInterestDeletedStatus = false;
                    let totalInterestObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'TotalInterest')) {
                      totalInterestObj = allModifiedEMIsArr[index].TotalInterest;
                      if (totalInterestObj && Object.prototype.hasOwnProperty.call(totalInterestObj, 'old') && Object.prototype.hasOwnProperty.call(totalInterestObj, 'new')) {
                        totalInterestUpdatedStatus = true;
                      }

                      if (totalInterestObj && Object.prototype.hasOwnProperty.call(totalInterestObj, 'delete')) {
                        totalInterestDeletedStatus = totalInterestObj.delete;
                      }
                    }

                    let principalRoundUpdatedStatus = false;
                    let principalRoundDeletedStatus = false;
                    let principalRoundObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'PrincipalRound')) {
                      principalRoundObj = allModifiedEMIsArr[index].PrincipalRound;
                      if (principalRoundObj && Object.prototype.hasOwnProperty.call(principalRoundObj, 'old') && Object.prototype.hasOwnProperty.call(principalRoundObj, 'new')) {
                        principalRoundUpdatedStatus = true;
                      }

                      if (principalRoundObj && Object.prototype.hasOwnProperty.call(principalRoundObj, 'delete')) {
                        principalRoundDeletedStatus = principalRoundObj.delete;
                      }
                    }

                    let interestRoundUpdatedStatus = false;
                    let interestRoundDeletedStatus = false;
                    let interestRoundObj = {};
                    if (allModifiedEMIsArr && allModifiedEMIsArr[index] && Object.prototype.hasOwnProperty.call(allModifiedEMIsArr[index], 'InterestRound')) {
                      interestRoundObj = allModifiedEMIsArr[index].InterestRound;
                      if (interestRoundObj && Object.prototype.hasOwnProperty.call(interestRoundObj, 'old') && Object.prototype.hasOwnProperty.call(interestRoundObj, 'new')) {
                        interestRoundUpdatedStatus = true;
                      }

                      if (interestRoundObj && Object.prototype.hasOwnProperty.call(interestRoundObj, 'delete')) {
                        interestRoundDeletedStatus = interestRoundObj.delete;
                      }
                    }

                    return (
                      <>
                        {deletedEmiStatus ? (<>
                          <tr key={index} className={deletedEmiStatus ? 'deletedRow' : ''}>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>Deleted</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                            <td className={deletedEmiStatus ? 'deletedRow' : ''}>&nbsp;</td>
                          </tr>
                        </>) : (<></>)}

                        <tr key={index}>
                          <td>{item.InstallmentNo}</td>
                          <td>
                            {dueAmountUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(dueAmountObj.old !== null && dueAmountObj.old !== '') ? moneyFormatIndia(dueAmountObj.old) : ''}</div>
                                <div className="text-success">New:{(dueAmountObj.new !== null && dueAmountObj.new !== '') ? moneyFormatIndia(dueAmountObj.new) : ''}</div>
                              </>
                            ) : (<>
                              {(item.DueAmount !== null && item.DueAmount !== '') ? <div dangerouslySetInnerHTML={{ __html: item.DueAmount }} /> : ''}
                            </>)}

                          </td>
                          <td>
                            {principleUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(principalObj.old !== null && principalObj.old !== '') ? moneyFormatIndia(principalObj.old) : ''}</div>
                                <div className="text-success">New:{(principalObj.new !== null && principalObj.new !== '') ? moneyFormatIndia(principalObj.new) : ''}</div>
                              </>
                            ) : (<>
                              {(item.Principal !== null && item.Principal !== '') ? <div dangerouslySetInnerHTML={{ __html: item.Principal }} /> : ''}
                            </>)}


                          </td>

                          <td className="text-nowrap">{formatDate(item.DueDate)}</td>
                          <td>
                            {paymentStatusUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(paymentStatusObj.old !== null && paymentStatusObj.old !== '') ? paymentStatusObj.old : ''}</div>
                                <div className="text-success">New:{(paymentStatusObj.new !== null && paymentStatusObj.new !== '') ? paymentStatusObj.new : ''}</div>
                              </>
                            ) : (<>
                              {(item.PaymentStatus !== null && item.PaymentStatus !== '') ? (
                                <div dangerouslySetInnerHTML={{ __html: item.PaymentStatus }} />
                              ) : (
                                ''
                              )}
                            </>)}
                          </td>
                          <td>
                            {paidAmountUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(paidAmountObj.old !== null && paidAmountObj.old !== '') ? formatNumbersInString(paidAmountObj.old) : ''}</div>
                                <div className="text-success">New:{(paidAmountObj.new !== null && paidAmountObj.new !== '') ? formatNumbersInString(paidAmountObj.new) : ''}</div>
                              </>
                            ) : (<>
                              {(item.PaidAmount !== null && item.PaidAmount !== '') ? <div dangerouslySetInnerHTML={{ __html: item.PaidAmount }} /> : ''}
                            </>)}

                          </td>
                          <td className="text-nowrap">{(item.PaymentDate !== null && item.PaymentDate !== '') ? <div dangerouslySetInnerHTML={{ __html: item.PaymentDate }} /> : ''}</td>

                          <td>
                            {interestUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(interestObj.old !== null && interestObj.old !== '') ? moneyFormatIndia(interestObj.old) : ''}</div>
                                <div className="text-success">New:{(interestObj.new !== null && interestObj.new !== '') ? moneyFormatIndia(interestObj.new) : ''}</div>
                              </>
                            ) : (<>
                              {(item.Interest !== null && item.Interest !== '') ? <div dangerouslySetInnerHTML={{ __html: item.Interest }} /> : ''}
                            </>)}

                          </td>
                          <td>

                            {principalOSUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(principalOSObj.old !== null && principalOSObj.old !== '') ? moneyFormatIndia(principalOSObj.old) : ''}</div>
                                <div className="text-success">New:{(principalOSObj.new !== null && principalOSObj.new !== '') ? moneyFormatIndia(principalOSObj.new) : ''}</div>
                              </>
                            ) : (<>
                              {(item.PrincipalOS !== null && item.PrincipalOS !== '') ? <div dangerouslySetInnerHTML={{ __html: item.PrincipalOS }} /> : ''}
                            </>)}

                          </td>
                          <td >
                            {pendingAmountUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(pendingAmountObj.old !== null && pendingAmountObj.old !== '') ? moneyFormatIndia(pendingAmountObj.old) : ''}</div>
                                <div className="text-success">New:{(pendingAmountObj.new !== null && pendingAmountObj.new !== '') ? moneyFormatIndia(pendingAmountObj.new) : ''}</div>
                              </>
                            ) : (<>
                              {(item.PendingAmount !== null && item.PendingAmount !== '') ? <div dangerouslySetInnerHTML={{ __html: item.PendingAmount }} /> : ''}
                            </>)}

                          </td>
                          <td >

                            {totalInterestUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(totalInterestObj.old !== null && totalInterestObj.old !== '') ? moneyFormatIndia(totalInterestObj.old) : ''}</div>
                                <div className="text-success">New:{(totalInterestObj.new !== null && totalInterestObj.new !== '') ? moneyFormatIndia(totalInterestObj.new) : ''}</div>
                              </>
                            ) : (<>
                              {(item.TotalInterest !== null && item.TotalInterest !== '') ? <div dangerouslySetInnerHTML={{ __html: item.TotalInterest }} /> : ''}
                            </>)}

                          </td>
                          <td >
                            {principalRoundUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(principalRoundObj.old !== null && principalRoundObj.old !== '') ? moneyFormatIndia(principalRoundObj.old) : ''}</div>
                                <div className="text-success">New:{(principalRoundObj.new !== null && principalRoundObj.new !== '') ? moneyFormatIndia(principalRoundObj.new) : ''}</div>
                              </>
                            ) : (<>
                              {(item.PrincipalRound !== null && item.PrincipalRound !== '') ? <div dangerouslySetInnerHTML={{ __html: item.PrincipalRound }} /> : ''}
                            </>)}

                          </td>
                          <td >

                            {interestRoundUpdatedStatus ? (
                              <>
                                <div className="text-danger">Old:{(interestRoundObj.old !== null && interestRoundObj.old !== '') ? moneyFormatIndia(interestRoundObj.old) : ''}</div>
                                <div className="text-success">New:{(interestRoundObj.new !== null && interestRoundObj.new !== '') ? moneyFormatIndia(interestRoundObj.new) : ''}</div>
                              </>
                            ) : (<>
                              {(item.InterestRound !== null && item.InterestRound !== '') ? <div dangerouslySetInnerHTML={{ __html: item.InterestRound }} /> : ''}
                            </>)}

                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={activeTabName === 'additional' ? 'tab-pane fade show active' : 'tab-pane fade'} id="pills-additional" role="tabpanel">
          <div className='mb-4 px-4'>
            <div id='main-wrap' className='mb-4 px-4'>
              <h5 className='fw-bold mb-3'>Additional Data and Information</h5>
              <div className='row'>
                <div className='col-md-12'>
                  <form onSubmit={handleSubmit(onSubmit)} className="row" encType="multipart/form-data">
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="residence_status">
                          <span>Residence Status</span>
                          <select name='residence_status' className="form-select" {...register('residence_status')}
                            value={formValues.residence_status}
                            onChange={handleChange}
                          >
                            <option value="">Select Residence Status</option>
                            {allResidenceStatusMaster.length > 0 && allResidenceStatusMaster.map((option) => (
                                <option value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                          </select>
                        </label>
                        {errors.residence_status && <span className="required">This field is required.</span>}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="profile">
                          <span>Profile</span>
                          <select name='profile' className="form-select" {...register('profile')} 
                            value={formValues.profile}
                            onChange={handleChange}
                          >
                            <option value="">Select Profile</option>
                            {allProfileMaster.length > 0 && allProfileMaster.map((option) => (
                                <option value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                          </select>
                        </label>
                        {errors.profile && <span className="required">This field is required.</span>}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="msme">
                          <span>MSME</span>
                          <input type='text' name='msme' className='form-control' {...register('msme')}
                            value={formValues.msme}
                            onChange={handleChange}
                          />
                        </label>
                        {errors.msme && <span className="required">This field is required.</span>}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="insurance_validity_date">
                          <span>Insurance Validity Date</span>
                          <input type="date" id="insurance_validity_date" name="insurance_validity_date" className="form-control" {...register('insurance_validity_date')} />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="sanction_date">
                          <span>Sanction Date</span>
                          <input type="date" id="sanction_date" name="sanction_date" className="form-control" {...register('sanction_date')} />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="stability">
                          <span>Stability</span>
                          <input type='text' name='stability' className='form-control' {...register('stability')}
                            value={formValues.stability}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="sector_of_unit">
                          <span>Sector of Unit</span>
                          <select name='sector_of_unit' className="form-select" {...register('sector_of_unit')}
                            value={formValues.sector_of_unit}
                            onChange={handleChange}
                          >
                            <option value="">Select Sector of Unit</option>
                            {allSectorOfUnitMaster.length > 0 && allSectorOfUnitMaster.map((option) => (
                                <option value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="sub_sector">
                          <span>Sub Sector</span>
                          <input type='text' name='sub_sector' className='form-control' {...register('sub_sector')} 
                            value={formValues.sub_sector}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="business_of_occupation">
                          <span>Business of Occupation</span>
                          <input type='text' name='business_of_occupation' className='form-control' {...register('business_of_occupation')}
                            value={formValues.business_of_occupation}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="turnover">
                          <span>Turnover</span>
                          <input type='text' name='turnover' className='form-control' {...register('turnover')}
                            value={formValues.turnover}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="dl_number">
                          <span>DL Number</span>
                          <input type='text' name='dl_number' className='form-control' {...register('dl_number')}
                            value={formValues.dl_number}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="dl_status">
                          <span>DL Status</span>
                          <select name='dl_status' className="form-select" {...register('dl_status')}
                            value={formValues.dl_status}
                            onChange={handleChange}
                          >
                            <option value="">Select DL Status</option>
                            {allDlStatusMaster.length > 0 && allDlStatusMaster.map((option) => (
                                <option value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="category">
                          <span>Category</span>
                          <select name='category' className="form-select" {...register('category')}
                            value={formValues.category}
                            onChange={handleChange}
                          >
                            <option value="">Select Category</option>
                            {allCategoryMaster.length > 0 && allCategoryMaster.map((option) => (
                                <option value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="region">
                          <span>Region</span>
                          <select name='region' className="form-select" {...register('region')}
                            value={formValues.region}
                            onChange={handleChange}
                          >
                            <option value="">Select Region</option>
                            {allRegionMaster.length > 0 && allRegionMaster.map((option) => (
                                <option value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="city_stability">
                          <span>City Stability</span>
                          <input type='text' name='city_stability' className='form-control' {...register('city_stability')}
                            value={formValues.city_stability}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="residence_stability">
                          <span>Residence Stability</span>
                          <input type='text' name='residence_stability' className='form-control' {...register('residence_stability')}
                            value={formValues.residence_stability}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="business_stability">
                          <span>Business Stability</span>
                          <input type='text' name='business_stability' className='form-control' {...register('business_stability')}
                            value={formValues.business_stability}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="driving_experience">
                          <span>Driving Experience</span>
                          <input type='text' name='driving_experience' className='form-control' {...register('driving_experience')}
                            value={formValues.driving_experience}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="product">
                          <span>Product</span>
                          <select name='product' className="form-select" {...register('product')}
                            value={formValues.product}
                            onChange={handleChange}
                          >
                            <option value="">Select Product</option>
                            {allProductMaster.length > 0 && allProductMaster.map((option) => (
                                <option value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="relation_with_applicant">
                          <span>Relation With Applicant</span>
                          <input type='text' name='relation_with_applicant' className='form-control' {...register('relation_with_applicant')}
                            value={formValues.relation_with_applicant}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="battery_number">
                          <span>Battery Number</span>
                          <input type='text' name='battery_number' className='form-control' {...register('battery_number')}
                            value={formValues.battery_number}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor="deployment_of_asset">
                          <span>Deployment of the Asset</span>
                          <input type='text' name='deployment_of_asset' className='form-control' {...register('deployment_of_asset')}
                            value={formValues.deployment_of_asset}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor="query">
                          <span>Warranty Detailn</span>
                          <textarea id='warranty_detail' name='warranty_detail' className='form-control' {...register('warranty_detail')}
                            value={formValues.warranty_detail}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <div className='form-group mb-2'>
                        <button type="submit" className='btn btn-primary'>Submit</button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </>
  );
}


function mapStateToProps(state) {
  return {
    getUserModuleData: state.getUserModuleData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(leadActions.default, reportActions.default, masterAction.default), dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoanVersioning);
