import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet-async';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
import * as XLSX from 'xlsx';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import config from '../../config';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../common.scss';
import { encryptData } from '../../utils/cryptoUtils';
import * as userActions from '../../actions/userActions';
import * as leadActions from '../../actions/leadActions';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LeadChecklistDynamicScore = ({ props, allLeadCheckListDynamicScore, setOpen, leadId, actions, allLeadCheckLists, userRoleType, creditAllLeadCheckLists, scoreCard, allLeadCheckListScore }) => {

    const [allLeadScore, setAllLeadScore] = useState([]);
    const [leadTotalScore, setLeadTotalScore] = useState('Not Found');
    const [leadIdValue, setLeadIdValue] = useState('');
    const [scoreData, setScoreData] = useState([]);
    const [scoreDataFormate, setScoreDataFormate] = useState(false);
    const [exportExcelFileButtonVisible, setExportExcelFileButtonVisible] = useState(false);
    const [fiSheetCheckId, setFiSheetCheckId] = useState(config.FI_SHEET_CHECK_ID);
    const [creditScoreCard, setCreditScoreCard] = useState("");
    const [allCreditLeadScore, setAllCreditLeadScore] = useState([]);
    const [leadCreditTotalScore, setLeadCreditTotalScore] = useState('Not Found');

    const [lastUpdateBy, setLastUpdateBy] = useState('Not Found');
    const [lastUpdateDate, setLastUpdateDate] = useState('Not Found');
    const [showSalesTotalScoreValue, setSalesShowTotalScoreValue] = useState(false);
    const [showCreditTotalScoreValue, setCreditShowTotalScoreValue] = useState(false);

    const storedScoreData = {};

    useEffect(() => {
        // console.log("check allLeadCheckLists = ", allLeadCheckLists);
        setFiSheetCheckId(config.FI_SHEET_CHECK_ID)
        setLeadIdValue(leadId);

        setCreditScoreCard(scoreCard);

        let scoreParams = '';

        if(scoreCard === "1"){
            setLeadCreditTotalScore(allLeadCheckListDynamicScore.total_score_crdit_lead);
            setLeadTotalScore(allLeadCheckListDynamicScore.total_score_lead);
            setAllCreditLeadScore(allLeadCheckListDynamicScore.lead_score);

            scoreParams = {"lead_id": leadId, "section_id": leadId, "section_name" : "lead", "total_score": allLeadCheckListDynamicScore.total_score_crdit_lead}
        } else {
            setAllLeadScore(allLeadCheckListScore.lead_score);
            setLeadTotalScore(allLeadCheckListScore.total_score);

            scoreParams = {"lead_id": leadId, "section_id": leadId, "section_name" : "lead", "total_score": allLeadCheckListDynamicScore.total_score}
        }

        if(scoreParams !== ""){
            // console.log("scoreParams : ", scoreParams);
            const requestData = encryptData(scoreParams);
            const response = actions.storeScoreData(requestData);
            response.then((result) => {
                console.log("result : ", result);
            }).catch((error) => {
                console.log("error : ", error);
            });
        }

        if (allLeadCheckLists && allLeadCheckLists.length > 0) {
            setLastUpdateBy(allLeadCheckLists[0].modifiedByName);
            setLastUpdateDate(allLeadCheckLists[0].modified_date);
        }

        if (allLeadCheckLists && allLeadCheckLists.length > 0) {
            const checklist = allLeadCheckLists.find(item => item.checklist_id === fiSheetCheckId);
            if (checklist) {
                setExportExcelFileButtonVisible(true);
            }

            try {
                const params = { lead_id: leadId, checklist_id: fiSheetCheckId };

                const requestData = encryptData(params);
                const result = actions.exportLeadScoreCard(requestData);

                result.then((result) => {
                    // console.log("Promise resolved -  exportLeadScoreCard:", result);
                    if (result.data.success === 1) {
                        const results = result.data.data;
                        if (results.scoreData) {
                            setScoreData(results.scoreData);
                        }
                    }
                }).catch((error) => {
                    //  console.error("Promise rejected -  exportLeadScoreCard :", error); 
                });
            } catch (error) {
                console.log("message = ", error.message);
            }
        }

        let isAllLeadChecklistComplete = false;
        let isCreditAllLeadChecklistComplete = false;

        if (allLeadCheckLists && allLeadCheckLists.length > 0) {
            const totalAnsweredQuestions = allLeadCheckLists[0].total_answered_questions;
            const totalRequiredQuestions = allLeadCheckLists[0].total_required_questions;
            const totalRequiredAnsweredQuestions = allLeadCheckLists[0].total_required_answered_questions;

            isAllLeadChecklistComplete =
                totalAnsweredQuestions > 0 &&
                totalRequiredQuestions >= 0 &&
                totalRequiredAnsweredQuestions === totalRequiredQuestions;
        }

        setSalesShowTotalScoreValue(isAllLeadChecklistComplete)

        if (creditAllLeadCheckLists && creditAllLeadCheckLists.length > 0) {
            const totalAnsweredQuestions = creditAllLeadCheckLists[0].total_answered_questions;
            const totalRequiredQuestions = creditAllLeadCheckLists[0].total_required_questions;
            const totalRequiredAnsweredQuestions = creditAllLeadCheckLists[0].total_required_answered_questions;

            isCreditAllLeadChecklistComplete =
                totalAnsweredQuestions > 0 &&
                totalRequiredQuestions >= 0 &&
                totalRequiredAnsweredQuestions === totalRequiredQuestions;
        }

        // Set the score value based on either condition being true
        setCreditShowTotalScoreValue(isCreditAllLeadChecklistComplete);

    }, [allLeadCheckListDynamicScore, setAllLeadScore, setLeadTotalScore, setLeadIdValue, allLeadCheckLists, setCreditScoreCard, setLastUpdateBy, setLastUpdateDate, setCreditShowTotalScoreValue, setSalesShowTotalScoreValue, creditAllLeadCheckLists]);

    const exportFile = async () => {
        try {
            const params = { lead_id: leadIdValue, checklist_id: fiSheetCheckId };
            const requestData = encryptData(params);
            const result = await actions.exportLeadScoreCard(requestData);

            if (result.data.success === 1) {
                const resResult = result.data.data;

                if (resResult.scoreData) {

                    setScoreData(resResult.scoreData);

                    if (!scoreDataFormate) {
                        $(document).ready(() => {
                            const thirdRow = $('.data_table tr:eq(1)');
                            const fourthRow = $('.data_table tr:eq(2)');
                            const fiththRow = $('.data_table tr:eq(3)');

                            const cellsToMove1 = thirdRow.find('td').add(fourthRow.find('td')).add(fiththRow.find('td'));
                            const append1 = $('.data_table tr:eq(0)');

                            cellsToMove1.each((index, cell) => {
                                append1.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const seventhRow = $('.data_table tr:eq(5)');
                            const eightthRow = $('.data_table tr:eq(6)');
                            const ninethRow = $('.data_table tr:eq(7)');
                            const tenthRow = $('.data_table tr:eq(8)');

                            const cellsToMove2 = seventhRow
                                .find('td')
                                .add(eightthRow.find('td'))
                                .add(ninethRow.find('td'))
                                .add(tenthRow.find('td'));
                            const append2 = $('.data_table tr:eq(4)');

                            cellsToMove2.each((index, cell) => {
                                append2.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const elevenRow = $('.data_table tr:eq(10)');
                            const twelvthRow = $('.data_table tr:eq(11)');

                            const cellsToMove3 = elevenRow.find('td').add(twelvthRow.find('td'));
                            const append3 = $('.data_table tr:eq(9)');

                            cellsToMove3.each((index, cell) => {
                                append3.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const thirdteenRow = $('.data_table tr:eq(13)');
                            const fourteenRow = $('.data_table tr:eq(14)');
                            const fithteenRow = $('.data_table tr:eq(15)');
                            const sixteenRow = $('.data_table tr:eq(16)');

                            const cellsToMove4 = thirdteenRow
                                .find('td')
                                .add(fourteenRow.find('td'))
                                .add(fithteenRow.find('td'))
                                .add(sixteenRow.find('td'));
                            const append4 = $('.data_table tr:eq(12)');

                            cellsToMove4.each((index, cell) => {
                                append4.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });



                            const eightteenRow = $('.data_table tr:eq(18)');
                            const nineteenRow = $('.data_table tr:eq(19)');

                            const cellsToMove5 = eightteenRow.find('td').add(nineteenRow.find('td'));
                            const append5 = $('.data_table tr:eq(17)');

                            cellsToMove5.each((index, cell) => {
                                append5.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const twentyOneRow = $('.data_table tr:eq(21)');
                            const twentyTwoRow = $('.data_table tr:eq(22)');

                            const cellsToMove6 = twentyOneRow
                                .find('td')
                                .add(twentyTwoRow.find('td'));
                            const append6 = $('.data_table tr:eq(20)');

                            cellsToMove6.each((index, cell) => {
                                append6.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });



                            const twentyFiveRow = $('.data_table tr:eq(25)');
                            const twentySixRow = $('.data_table tr:eq(26)');
                            const twentySevenRow = $('.data_table tr:eq(27)');

                            const cellsToMove7 = twentyFiveRow
                                .find('td')
                                .add(twentySixRow.find('td'))
                                .add(twentySevenRow.find('td'));
                            const append7 = $('.data_table tr:eq(24)');

                            cellsToMove7.each((index, cell) => {
                                append7.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });



                            const twentyNine = $('.data_table tr:eq(29)');

                            const cellsToMove8 = twentyNine.find('td');
                            const append8 = $('.data_table tr:eq(28)');

                            cellsToMove8.each((index, cell) => {
                                append8.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });



                            const thirtyTwoRow = $('.data_table tr:eq(32)');
                            const thirtyThreeRow = $('.data_table tr:eq(33)');

                            const cellsToMove9 = thirtyTwoRow
                                .find('td')
                                .add(thirtyThreeRow.find('td'));
                            const append9 = $('.data_table tr:eq(31)');

                            cellsToMove9.each((index, cell) => {
                                append9.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });



                            const thirtyFiveRow = $('.data_table tr:eq(35)');
                            const thirtySixRow = $('.data_table tr:eq(36)');

                            const cellsToMove10 = thirtyFiveRow.find('td').add(thirtySixRow.find('td'));
                            const append10 = $('.data_table tr:eq(34)');

                            cellsToMove10.each((index, cell) => {
                                append10.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });



                            const FourtyRow = $('.data_table tr:eq(40)');
                            const FourtyOneRow = $('.data_table tr:eq(41)');
                            const FourtyTwoRow = $('.data_table tr:eq(42)');

                            const cellsToMove11 = FourtyRow.find('td').add(FourtyOneRow.find('td')).add(FourtyTwoRow.find('td'));
                            const append11 = $('.data_table tr:eq(39)');

                            cellsToMove11.each((index, cell) => {
                                append11.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });



                            const FourtyEightRow = $('.data_table tr:eq(48)');
                            const cellsToMove12 = FourtyEightRow.find('td');
                            const append12 = $('.data_table tr:eq(45)');

                            cellsToMove12.each((index, cell) => {
                                append12.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const FourtyNineRow = $('.data_table tr:eq(49)');
                            const cellsToMove13 = FourtyNineRow.find('td');
                            const append13 = $('.data_table tr:eq(46)');

                            cellsToMove13.each((index, cell) => {
                                append13.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const FiftyRow = $('.data_table tr:eq(50)');
                            const cellsToMove14 = FiftyRow.find('td');
                            const append14 = $('.data_table tr:eq(47)');

                            cellsToMove14.each((index, cell) => {
                                append14.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const FiftyTwoRow = $('.data_table tr:eq(52)');
                            const cellsToMove15 = FiftyTwoRow.find('td');
                            const append15 = $('.data_table tr:eq(51)');

                            cellsToMove15.each((index, cell) => {
                                append15.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });



                            const FiftyFourRow = $('.data_table tr:eq(54)');
                            const cellsToMove17 = FiftyFourRow.find('td');
                            const append17 = $('.data_table tr:eq(53)');

                            cellsToMove17.each((index, cell) => {
                                append17.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const FiftySixRow = $('.data_table tr:eq(56)');
                            const FiftySevenRow = $('.data_table tr:eq(57)');
                            const cellsToMove18 = FiftySixRow.find('td').add(FiftySevenRow.find('td'));
                            const append18 = $('.data_table tr:eq(55)');

                            cellsToMove18.each((index, cell) => {
                                append18.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const FiftyNineRow = $('.data_table tr:eq(59)');
                            const SixtyRow = $('.data_table tr:eq(60)');
                            const cellsToMove19 = FiftyNineRow.find('td').add(SixtyRow.find('td'));
                            const append19 = $('.data_table tr:eq(58)');

                            cellsToMove19.each((index, cell) => {
                                append19.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const SixtyTwoRow = $('.data_table tr:eq(62)');
                            const SixtyThreeRow = $('.data_table tr:eq(63)');
                            const cellsToMove20 = SixtyTwoRow.find('td').add(SixtyThreeRow.find('td'));
                            const append20 = $('.data_table tr:eq(61)');

                            cellsToMove20.each((index, cell) => {
                                append20.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const SixtySixRow = $('.data_table tr:eq(66)');
                            const SixtySevenRow = $('.data_table tr:eq(67)');
                            const SixtyEightRow = $('.data_table tr:eq(68)');
                            const cellsToMove21 = SixtySixRow.find('td').add(SixtySevenRow.find('td')).add(SixtyEightRow.find('td'));
                            const append21 = $('.data_table tr:eq(65)');

                            cellsToMove21.each((index, cell) => {
                                append21.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const SeventyFiveRow = $('.data_table tr:eq(76)');
                            const cellsToMove22 = SeventyFiveRow.find('td');
                            const append22 = $('.data_table tr:eq(72)');

                            cellsToMove22.each((index, cell) => {
                                append22.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const SeventySevenRow = $('.data_table tr:eq(77)');
                            const cellsToMove23 = SeventySevenRow.find('td');
                            const append23 = $('.data_table tr:eq(73)');

                            cellsToMove23.each((index, cell) => {
                                append23.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const SeventyEightRow = $('.data_table tr:eq(78)');
                            const cellsToMove24 = SeventyEightRow.find('td');
                            const append24 = $('.data_table tr:eq(74)');

                            cellsToMove24.each((index, cell) => {
                                append24.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            const SeventyNineRow = $('.data_table tr:eq(79)');
                            const cellsToMove25 = SeventyNineRow.find('td');
                            const append25 = $('.data_table tr:eq(75)');

                            cellsToMove25.each((index, cell) => {
                                append25.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const EightyOneRow = $('.data_table tr:eq(81)');
                            const EightyTwoRow = $('.data_table tr:eq(82)');
                            const EightyThreeRow = $('.data_table tr:eq(83)');
                            const cellsToMove26 = EightyOneRow.find('td').add(EightyTwoRow.find('td')).add(EightyThreeRow.find('td'));
                            const append26 = $('.data_table tr:eq(80)');

                            cellsToMove26.each((index, cell) => {
                                append26.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const EightyFiveRow = $('.data_table tr:eq(85)');
                            const EightySixRow = $('.data_table tr:eq(86)');
                            const cellsToMove27 = EightyFiveRow.find('td').add(EightySixRow.find('td'));
                            const append27 = $('.data_table tr:eq(84)');

                            cellsToMove27.each((index, cell) => {
                                append27.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });

                            const NinetyRow = $('.data_table tr:eq(90)');
                            const NinetyOneRow = $('.data_table tr:eq(91)');
                            const NinetyTwoRow = $('.data_table tr:eq(92)');
                            const NinetyThreeRow = $('.data_table tr:eq(93)');
                            const cellsToMove28 = NinetyRow.find('td').add(NinetyOneRow.find('td')).add(NinetyTwoRow.find('td')).add(NinetyThreeRow.find('td'));
                            const append28 = $('.data_table tr:eq(89)');

                            cellsToMove28.each((index, cell) => {
                                append28.append(`<td data-b-a-s='thin' data-b-a-c='000' data-f-sz='10'>${$(cell).html()}</td>`);
                            });


                            SixtyTwoRow.remove();
                            SixtyThreeRow.remove();
                            SixtySixRow.remove();
                            SixtySevenRow.remove();
                            SixtyEightRow.remove();
                            SeventyFiveRow.remove();
                            SeventySevenRow.remove();
                            SeventyEightRow.remove();
                            SeventyNineRow.remove();
                            EightyOneRow.remove();
                            EightyTwoRow.remove();
                            EightyThreeRow.remove();
                            EightySixRow.remove();
                            EightyFiveRow.remove();
                            NinetyRow.remove();
                            NinetyOneRow.remove();
                            NinetyTwoRow.remove();
                            NinetyThreeRow.remove();

                            thirdRow.remove();
                            fourthRow.remove();
                            fiththRow.remove();
                            seventhRow.remove();
                            eightthRow.remove();
                            ninethRow.remove();
                            tenthRow.remove();
                            elevenRow.remove();
                            twelvthRow.remove();
                            thirdteenRow.remove();
                            fourteenRow.remove();
                            fithteenRow.remove();
                            sixteenRow.remove();
                            eightteenRow.remove();
                            nineteenRow.remove();
                            twentyOneRow.remove();
                            twentyTwoRow.remove();
                            twentyFiveRow.remove();
                            twentySixRow.remove();
                            twentySevenRow.remove();
                            twentyNine.remove();
                            thirtyTwoRow.remove();
                            thirtyThreeRow.remove();
                            thirtyFiveRow.remove();
                            thirtySixRow.remove();
                            FourtyRow.remove();
                            FourtyOneRow.remove();
                            FourtyTwoRow.remove();
                            FourtyEightRow.remove();
                            FourtyNineRow.remove();
                            FiftyRow.remove();
                            FiftyTwoRow.remove();
                            FiftyFourRow.remove();
                            FiftySixRow.remove();
                            FiftySevenRow.remove();
                            FiftyNineRow.remove();
                            SixtyRow.remove();
                        });

                        setScoreDataFormate(true);
                    }

                    toast.success(result.data.message, {
                        position: 'top-right',
                    });

                    adjustTableAndExport();
                }
            } else {
                toast.error(result.data.message, {
                    position: 'top-right',
                });
            }
        } catch (error) {
            toast.error(error.message, {
                position: 'top-right',
            });
        }
    };

    const adjustTableAndExport = () => {
        $(document).ready(() => {
            $(this).addClass('');
            $('.data_table tr:eq(0) td:eq(0)').attr('data-f-bold', 'true');
            $('.data_table tr:eq(0) td:eq(1)').attr('colSpan', '3');
            $('.data_table tr:eq(0) td:eq(5)').attr('colSpan', '3');
            $('.data_table tr:eq(1) td:eq(1)').attr('colSpan', '3');
            $('.data_table tr:eq(2) td:eq(1)').attr('colSpan', '3');
            $('.data_table tr:eq(2) td:eq(5)').attr('colSpan', '5');
            $('.data_table tr:eq(3) td:eq(1)').attr('colSpan', '2');
            $('.data_table tr:eq(3) td:eq(7)').attr('colSpan', '2');
            $('.data_table tr:eq(4) td:eq(1)').attr('colSpan', '4');
            $('.data_table tr:eq(4) td:eq(3)').attr('colSpan', '4');
            $('.data_table tr:eq(5) td:eq(1)').attr('colSpan', '3');
            $('.data_table tr:eq(5) td:eq(3)').attr('colSpan', '3');
            $('.data_table tr:eq(5) td:eq(5)').attr('colSpan', '3');
            $('.data_table tr:eq(6) td:eq(1)').attr('colSpan', '11');
            $('.data_table tr:eq(7) td:eq(1)').attr('colSpan', '3');
            $('.data_table tr:eq(7) td:eq(5)').attr('colSpan', '3');
            $('.data_table tr:eq(8) td:eq(1)').attr('colSpan', '5');
            $('.data_table tr:eq(8) td:eq(3)').attr('colSpan', '5');
            $('.data_table tr:eq(9) td:eq(1)').attr('colSpan', '11');
            $('.data_table tr:eq(10) td:eq(1)').attr('colSpan', '2');
            $('.data_table tr:eq(10) td:eq(3)').attr('colSpan', '2');
            $('.data_table tr:eq(10) td:eq(5)').attr('colSpan', '5');
            $('.data_table tr:eq(11) td:eq(1)').attr('colSpan', '2');
            $('.data_table tr:eq(11) td:eq(2)').attr('colSpan', '3');
            $('.data_table tr:eq(11) td:eq(2)').attr('colSpan', '3');
            $('.data_table tr:eq(11) td:eq(5)').attr('colSpan', '4');
            $('.data_table tr:eq(12) td:eq(1)').attr('colSpan', '11');
            $('.data_table tr:eq(13) td:eq(1)').attr('colSpan', '11');
            $('.data_table tr:eq(14) td:eq(3)').attr('colSpan', '2');
            $('.data_table tr:eq(14) td:eq(7)').attr('colSpan', '4');
            $('.data_table tr:eq(15) td:eq(1)').attr('colSpan', '11');
            $('.data_table tr:eq(16) td:eq(1)').attr('colSpan', '11');
            $('.data_table tr:eq(17) td:eq(1)').attr('colSpan', '5');
            $('.data_table tr:eq(17) td:eq(3)').attr('colSpan', '5');
            $('.data_table tr:eq(18) td:eq(1)').attr('colSpan', '5');
            $('.data_table tr:eq(18) td:eq(3)').attr('colSpan', '5');
            $('.data_table tr:eq(19) td:eq(1)').attr('colSpan', '5');
            $('.data_table tr:eq(19) td:eq(3)').attr('colSpan', '5');
            $('.data_table tr:eq(20) td:eq(1)').attr('colSpan', '5');
            $('.data_table tr:eq(20) td:eq(3)').attr('colSpan', '5');
            $('.data_table tr:eq(21) td:eq(1)').attr('colSpan', '5');
            $('.data_table tr:eq(21) td:eq(3)').attr('colSpan', '5');

            $('.data_table tr:eq(22) td:eq(1)').attr('colspan', '3');
            $('.data_table tr:eq(22) td:eq(3)').attr('colspan', '3');
            $('.data_table tr:eq(22) td:eq(5)').attr('colspan', '3');

            $('.data_table tr:eq(23) td:eq(1)').attr('colspan', '3');
            $('.data_table tr:eq(23) td:eq(3)').attr('colspan', '3');
            $('.data_table tr:eq(23) td:eq(5)').attr('colspan', '3');

            $('.data_table tr:eq(24) td:eq(3)').attr('colspan', '2');
            $('.data_table tr:eq(24) td:eq(5)').attr('colspan', '6');

            $('.data_table tr:eq(25) td:eq(1)').attr('colspan', '11');

            $('.data_table tr:eq(26) td:eq(3)').attr('colspan', '2');
            $('.data_table tr:eq(26) td:eq(7)').attr('colspan', '4');

            $('.data_table tr:eq(27) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(28) td:eq(1)').attr('colspan', '11');

            $('.data_table tr:eq(29) td:eq(1)').attr('colspan', '11');

            $('.data_table tr:eq(30) td:eq(1)').attr('colspan', '5');
            $('.data_table tr:eq(30) td:eq(3)').attr('colspan', '5');

            $('.data_table tr:eq(31) td:eq(1)').attr('colspan', '5');
            $('.data_table tr:eq(31) td:eq(3)').attr('colspan', '5');
            $('.data_table tr:eq(32) td:eq(1)').attr('colspan', '5');
            $('.data_table tr:eq(32) td:eq(3)').attr('colspan', '5');
            $('.data_table tr:eq(33) td:eq(1)').attr('colspan', '5');
            $('.data_table tr:eq(33) td:eq(3)').attr('colspan', '5');
            $('.data_table tr:eq(33) td:eq(3)').attr('colspan', '5');

            $('.data_table tr:eq(34) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(34) td:eq(3)').attr('colspan', '2');
            $('.data_table tr:eq(34) td:eq(7)').attr('colspan', '3');

            $('.data_table tr:eq(35) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(35) td:eq(3)').attr('colspan', '2');
            $('.data_table tr:eq(35) td:eq(5)').attr('colspan', '5');

            $('.data_table tr:eq(36) td:eq(1)').attr('colspan', '11');
            $('.data_table tr:eq(37) td:eq(1)').attr('colspan', '11');

            $('.data_table tr:eq(38) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(38) td:eq(5)').attr('colspan', '2');
            $('.data_table tr:eq(38) td:eq(1)').attr('colspan', '2');
            $('.data_table tr:eq(38) td:eq(1)').attr('colspan', '2');

            TableToExcel.convert(document.getElementById('ExportExcelFile'), {
                name: 'FI Report.xlsx',
                sheet: {
                    name: 'FI Report',
                },
            });
        });
    };

    if (scoreData.length > 0) {
        scoreData.forEach((item, index) => {
            const key = Object.keys(item)[0];
            storedScoreData[key] = item[key];
        });
    }

    const downloadExcel = () => {
        const table = document.getElementById('DynamicExcelFile');
        const ws = XLSX.utils.table_to_sheet(table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const fileName = 'New FI Report.xlsx';
        XLSX.writeFile(wb, fileName);
    };

    return (
        <>
            <div id="main-wrap">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row justify-content-between">
                            <div className="col-md-6">
                                <h5 className="fw-bold mb-3">Scorecard</h5>
                            </div>
                            <div className="col-md-6 text-end d--none">
                                <div className="form-group mb-3">
                                    {/* <button className={exportExcelFileButtonVisible ? "btn btn-info text-white" : " btn btn-info text-white d-none"} onClick={() => exportFile()}>
                                        Export Excel File
                                    </button> */}

                                    <button className="btn btn-info text-white" onClick={() => downloadExcel()}>Export Excel File</button>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-between">
                            <div className="col-md-12 text-end d--none">
                                <div className="form-group mb-3">
                                    <span>Last answers given by : {lastUpdateBy}</span>
                                    <span>&nbsp;</span>
                                    <span>On {lastUpdateDate}</span>
                                </div>
                            </div>
                        </div>

                        <div className={creditScoreCard === "0" ? "table-wrap" : " table-wrap d-none"}>
                            <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>Question</th>
                                        <th>Answer</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(allLeadScore) && allLeadScore.length > 0 ? (
                                        allLeadScore.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.questionText}</td>
                                                <td>{item.userAnswers}</td>
                                                <td>{item.score}</td>
                                            </tr>
                                        ))
                                        
                                    ) : (
                                        <p className="mt-3">No score found.</p>
                                    )}

                                    <tr className={Array.isArray(allLeadScore) && allLeadScore.length === 0 ? 'hide' : 'show'}>
                                        <th colSpan={3} className="text-end border-bottom-0">
                                            {showCreditTotalScoreValue || showSalesTotalScoreValue ? (
                                                <h6 className="fw-bold pt-2 mb-0 mt-1">Total Score: {leadTotalScore}</h6>
                                            ) : (
                                                "Incomplete"
                                            )}
                                        </th>
                                    </tr>

                                    
                                </tbody>
                            </table>
                        </div>


                        <div className={creditScoreCard === "1" ? "table-wrap" : " table-wrap d-none"}>
                            <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>Question</th>
                                        <th>Sales Answer</th>
                                        <th>Sales Score</th>
                                        <th>Credit Answer</th>
                                        <th>Credit Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allCreditLeadScore.length > 0 ? (
                                        allCreditLeadScore.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.questionText}</td>
                                                <td>{item.old_userAnswers}</td>
                                                <td>{item.lead_score}</td>
                                                <td>{item.new_userAnswers}</td>
                                                <td>{item.credit_lead_score}</td>
                                            </tr>
                                        ))
                                    ) : (<p className="mt-3">No score found.</p>)}
                                    
                                    <tr className={allLeadCheckListDynamicScore.length === 0 ? 'hide' : 'show'}>
                                        <th>&nbsp;</th>
                                        <th colSpan={2} className="text-end border-bottom-0">
                                            {!showSalesTotalScoreValue ? "Incomplete" : <h6 className="fw-bold pt-2 mb-0 mt-1">Sales Total: {allLeadCheckListDynamicScore.total_score_lead}</h6>}
                                        </th>
                                        <th colSpan={2} className="text-end border-bottom-0">
                                            {!showCreditTotalScoreValue ? "Incomplete" : <h6 className="fw-bold pt-2 mb-0 mt-1">Credit Total: {allLeadCheckListDynamicScore.total_score_crdit_lead}</h6>}
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* {allLeadScore.map((leadCheckListScore) => (
                            <div key={leadCheckListScore.id} className='lead-activity-item'>
                                <p className='mb-2'><b>Queryuestion Text:</b> {leadCheckListScore.questionText}</p>
                                <p className='mb-2'><b>Answers:</b> {leadCheckListScore.userAnswers}</p>
                                <p className='mb-0'><b>Score:</b> {leadCheckListScore.score}</p>
                            </div>
                        ))} */}
                    </div>

                    <div className="table--wrap table-responsive">
                        <table
                            className="table data_table d-none table-bordered mt-5 table-hover mb-0"
                            id="ExportExcelFile"
                            data-cols-width="22,16,16,16,16,16,16,16,16,16" data-fill-color="#fff"
                        >
                            <tbody>
                                {scoreData.map((item, index) => (
                                    <tr key={index}>
                                        <td className='p-2' data-b-a-s="thin" data-a-wrap="true" data-b-a-c="000" data-f-sz="10">
                                            {item.questionText}
                                        </td>
                                        <td className='p-2' data-b-a-s="thin" data-a-wrap="true" data-b-a-c="000" data-f-sz="10">
                                            {item.userAnswers}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="table--wrap table-responsive d-none">
                        <table className="table data_table table-bordered mt-5 table-hover mb-0" id="DynamicExcelFile"
                            data-cols-width="22,16,16,16,16,16,16,16,16,16" data-fill-color="#fff">
                            <tbody>
                                <tr>
                                    <th colSpan="13" style={{ textAlign: 'center' }}>Field Investigation Details: Individual</th>
                                </tr>
                                <tr>
                                    <td colSpan="1">Name of FI Manager</td>
                                    <td colSpan="2">{storedScoreData["Name of FI Manager"] || ''}</td>
                                    <td colSpan="1">Employee code</td>
                                    <td colSpan="2">{storedScoreData["Employee code"] || ''}</td>
                                    <td colSpan="1">Date of FI</td>
                                    <td colSpan="3">{storedScoreData["Date of FI"] || ''}</td>
                                    <td colSpan="1">Time</td>
                                    <td colSpan="2">{storedScoreData.Time || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Applicant Name</td>
                                    <td colSpan="2">{storedScoreData["Applicant Name"] || ''}</td>
                                    <td colSpan="1">Phone</td>
                                    <td colSpan="2">{storedScoreData.Phone || ''}</td>
                                    <td colSpan="1">Father Name</td>
                                    <td colSpan="1">{storedScoreData["Father Name"] || ''}</td>
                                    <td colSpan="1">Applicant Type</td>
                                    <td colSpan="1">{storedScoreData["Applicant Type"] || ''}</td>
                                    <td colSpan="1">Location</td>
                                    <td colSpan="2">{storedScoreData.Location || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Gender</td>
                                    <td colSpan="1">{storedScoreData.Gender || ''}</td>
                                    <td colSpan="1">Date of Birth</td>
                                    <td colSpan="1">{storedScoreData["Date of Birth"] || ''}</td>
                                    <td colSpan="1">Highest Education Level</td>
                                    <td colSpan="2">{storedScoreData["Highest Education Level"] || ''}</td>
                                    <td colSpan="1">Marital Status</td>
                                    <td colSpan="1">{storedScoreData["Marital Status"] || ''}</td>
                                    <td colSpan="2">&nbsp;</td>
                                    <td colSpan="2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Person Met</td>
                                    <td colSpan="1">{storedScoreData["Person Met"] || ''}</td>
                                    <td colSpan="1">Phone</td>
                                    <td colSpan="1">{storedScoreData.Phone || ''}</td>
                                    <td colSpan="1">Relation with Hirer</td>
                                    <td colSpan="2">{storedScoreData["Relation with Hirer"] || ''}</td>
                                    <td colSpan="1">Stability in the city</td>
                                    <td colSpan="1">{storedScoreData["Stability in the city"] || ''}</td>
                                    <td colSpan="3">Distance from Dealers Branch/Service Centre</td>
                                    <td colSpan="1">{storedScoreData["Distance from Dealers Branch/Service Centre"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1" >Property Ownership Check</td>
                                    <td colSpan="3" >{storedScoreData["Property Ownership Check"] || ''}</td>
                                    <td colSpan="1" >Accomodation</td>
                                    <td colSpan="3" >{storedScoreData.Accomodation || ''}</td>
                                    <td colSpan="2" >Area</td>
                                    <td colSpan="3" >{storedScoreData.Area || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Residence Locality & Traceability(Should not be in negative area and ease of access)</td>
                                    <td colSpan="2">{storedScoreData["Residence Locality & Traceability(Should not be in negative area and ease of access)"] || ''}</td>
                                    <td colSpan="1">Standard of Living</td>
                                    <td colSpan="2">{storedScoreData["Standard of Living"] || ''}</td>
                                    <td colSpan="2">Commercial Driving Experience</td>
                                    <td colSpan="5">{storedScoreData["Commercial Driving Experience"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Current Address
                                    </td>
                                    <td colSpan="12">{storedScoreData["Current Address"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Landmark</td>
                                    <td colSpan="2">{storedScoreData.Landmark || ''}</td>
                                    <td colSpan="1">City</td>
                                    <td colSpan="2">{storedScoreData.City || ''}</td>
                                    <td colSpan="1">State</td>
                                    <td colSpan="2">{storedScoreData.State || ''}</td>
                                    <td colSpan="1">Postal Code</td>
                                    <td colSpan="3">{storedScoreData["Postal Code"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Business Details</td>
                                    <td colSpan="1">Firm/Co. Name:</td>
                                    <td colSpan="4">{storedScoreData["Firm/Co. Name:"] || ''}</td>
                                    <td colSpan="1">Business Stability</td>
                                    <td colSpan="6">{storedScoreData["Business Stability"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Business/Office Address</td>
                                    <td colSpan="12">{storedScoreData["Business/Office Address"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Business Experience of Hirer</td>
                                    <td colSpan="1">{storedScoreData["Business Experience of Hirer"] || ''}</td>
                                    <td colSpan="1">Segment</td>
                                    <td colSpan="3">{storedScoreData.Segment || ''}</td>
                                    <td colSpan="1">Premisess Owned by</td>
                                    <td colSpan="6">{storedScoreData["Premisess Owned by"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Premisess Owned by</td>
                                    <td colSpan="7">{storedScoreData["Premisess Owned by"] || ''}</td>
                                    <td colSpan="1">Others Details</td>
                                    <td colSpan="4">{storedScoreData["Others Details"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Premisess Area</td>
                                    <td colSpan="1">{storedScoreData["Premisess Area"] || ''}</td>
                                    <td colSpan="4">Bulit/Non-Built</td>
                                    <td colSpan="1">Property Worth</td>
                                    <td colSpan="6">{storedScoreData["Property Worth"] || ''}</td>
                                </tr>
                                <tr>
                                    <th colSpan="13" style={{ textAlign: 'center' }}>Profile Feedback</th>
                                </tr>
                                <tr>
                                    <th colSpan="13">{storedScoreData["Profile Feedback"] || ''}</th>
                                </tr>
                                <tr>
                                    <th colSpan="13" style={{ textAlign: 'center' }}>Current Vehicle  Details</th>
                                </tr>
                                <tr>
                                    <td colSpan="1">Total Vehicle</td>
                                    <td colSpan="12">{storedScoreData["Total Vehicle"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Vehicle no</td>
                                    <td colSpan="1">Asset Discription</td>
                                    <td colSpan="2">{storedScoreData["Asset Discription"] || ''}</td>
                                    <td colSpan="1">Finance/Free</td>
                                    <td colSpan="1">{storedScoreData["Finance/Free"] || ''}</td>
                                    <td colSpan="1">EMI-Paid/Total Tenure</td>
                                    <td colSpan="1">{storedScoreData["EMI-Paid/Total Tenure"] || ''}</td>
                                    <td colSpan="2">&nbsp;</td>
                                    <td colSpan="3">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Any other Income
                                    </td>
                                    <td colSpan="12">{storedScoreData["Any other Income"] || ''}</td>
                                </tr>
                                <tr>
                                    <th colSpan="13" style={{ textAlign: 'center' }}>Neighbour  Reference Check:</th>
                                </tr>
                                <tr>
                                    <td colSpan="1">Neighbour Name</td>
                                    <td colSpan="5">{storedScoreData["Neighbour Name"] || ''}</td>
                                    <td colSpan="1">Neighbour Name</td>
                                    <td colSpan="6">{storedScoreData["Neighbour Name"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Neighbour Contact
                                        No.</td>
                                    <td colSpan="5">{storedScoreData["Neighbour Contact"] || ''}</td>
                                    <td colSpan="1">Neighbour Contact No.</td>
                                    <td colSpan="6">{storedScoreData["Neighbour Contact"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Neighbour Feedback
                                    </td>
                                    <td colSpan="5">{storedScoreData["Neighbour Feedback"] || ''}</td>
                                    <td colSpan="1">Neighbour Feedback</td>
                                    <td colSpan="6">{storedScoreData["Neighbour Feedback"] || ''}</td>
                                </tr>
                                <tr>
                                    <th colSpan="13" style={{ textAlign: 'center' }}>Guarantor Details:</th>
                                </tr>
                                <tr>
                                    <td colSpan="1">Name</td>
                                    <td colSpan="3">{storedScoreData["Guarantor's Name"] || ''}</td>
                                    <td colSpan="2">&nbsp;</td>
                                    <td colSpan="1">Contact No</td>
                                    <td colSpan="2">{storedScoreData["Guarantor's Contact No"] || ''}</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Residence Address</td>
                                    <td colSpan="5">{storedScoreData["Guarantor's Residence Address"] || ''}</td>
                                    <td colSpan="1">Stability in the city</td>
                                    <td colSpan="2">{storedScoreData["Guarantor's Stability in the city"] || ''}</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan="1" >Property Ownership Check</td>
                                    <td colSpan="3" >{storedScoreData["Guarantor's Property Ownership Check"] || ''}</td>
                                    <td colSpan="1" >Accomodation</td>
                                    <td colSpan="3" >{storedScoreData["Guarantor's Accomodation"] || ''}</td>
                                    <td colSpan="2" >Distance from Dealers Branch/Service centre</td>
                                    <td colSpan="3" >{storedScoreData["Guarantor's Distance from Dealers Branch/Service Centre"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Residence Locality & Traceability(Should not be in negative area and ease of access)</td>
                                    <td colSpan="2">{storedScoreData["Guarantor's Residence Locality & Traceability(Should not be in negative area and ease of access)"] || ''}</td>
                                    <td colSpan="1">Standard of Living</td>
                                    <td colSpan="2">{storedScoreData["Guarantor's Standard of Living"] || ''}</td>
                                    <td colSpan="2">Commercial Driving Experience</td>
                                    <td colSpan="5">{storedScoreData["Guarantor's Commercial Driving Experience"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Association with hirer</td>
                                    <td colSpan="1">Relationship</td>
                                    <td colSpan="1">{storedScoreData["Guarantor's Association with hirer"] || ''}</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">Duration</td>
                                    <td colSpan="2">{storedScoreData["Guarantor's Duration"] || ''}</td>
                                    <td colSpan="2">&nbsp;</td>
                                    <td colSpan="2">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                </tr>
                                <tr>
                                    <th colSpan="13" style={{ textAlign: 'center' }}>Current Vehicle  Details</th>
                                </tr>
                                <tr>
                                    <td colSpan="1">Total Vehicle</td>
                                    <td colSpan="12">{storedScoreData["Guarantor's Total Vehicle"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Vehicle no</td>
                                    <td colSpan="1">Asset Discription</td>
                                    <td colSpan="2">{storedScoreData["Guarantor's Asset Discription"] || ''}</td>
                                    <td colSpan="1">Finance/Free</td>
                                    <td colSpan="1">{storedScoreData["Guarantor's Finance/Free"] || ''}</td>
                                    <td colSpan="1">EMI-Paid/Total Tenure</td>
                                    <td colSpan="1">{storedScoreData["Guarantor's EMI-Paid/Total Tenure"] || ''}</td>
                                    <td colSpan="2">&nbsp;</td>
                                    <td colSpan="3">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Any other Income
                                    </td>
                                    <td colSpan="12">{storedScoreData["Guarantor's Any other Income"] || ''}</td>
                                </tr>
                                <tr>
                                    <th colSpan="13" style={{ textAlign: 'center' }}>Neighbour  Reference Check:</th>
                                </tr>
                                <tr>
                                    <td colSpan="1">Neighbour Name</td>
                                    <td colSpan="5">{storedScoreData["Guarantor's Neighbour Name"] || ''}</td>
                                    <td colSpan="1">Neighbour Name</td>
                                    <td colSpan="6">{storedScoreData["Guarantor's Neighbour Name"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Neighbour Contact
                                        No.</td>
                                    <td colSpan="5">{storedScoreData["Guarantor's Neighbour Contact"] || ''}</td>
                                    <td colSpan="1">Neighbour Contact No.</td>
                                    <td colSpan="6">{storedScoreData["Guarantor's Neighbour Contact"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Neighbour Feedback
                                    </td>
                                    <td colSpan="5">{storedScoreData["Guarantor's Neighbour Feedback"] || ''}</td>
                                    <td colSpan="1">Neighbour Feedback</td>
                                    <td colSpan="6">{storedScoreData["Guarantor's Neighbour Feedback"] || ''}</td>
                                </tr>

                                <tr>
                                    <th colSpan="12" style={{ textAlign: 'center' }}>Business Details (Only for Captive Borrower):</th>
                                </tr>
                                <tr>
                                    <td colSpan="1">Sign Board of Office Seen</td>
                                    <td colSpan="2">{storedScoreData["Sign Board of Office Seen"] || ''}</td>
                                    <td colSpan="2">Stock of finished goods(value appx)</td>
                                    <td colSpan="2">{storedScoreData["Stock of finished goods(value appx)"] || ''}</td>
                                    <td colSpan="1">Resi cum office</td>
                                    <td colSpan="1">{storedScoreData["Resi cum office"] || ''}</td>
                                    <td colSpan="1">No. of attached vehicles (PM)</td>
                                    <td colSpan="1">{storedScoreData["No. of attached vehicles (PM)"] || ''}</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Business Activity seen which carried out the business</td>
                                    <td colSpan="1">{storedScoreData["Business Activity seen which carried out the business"] || ''}</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="2">No. of employee seen</td>
                                    <td colSpan="2">{storedScoreData["No. of employee seen"] || ''}</td>
                                    <td colSpan="1">Freight paid (PM)</td>
                                    <td colSpan="1">{storedScoreData["Freight paid (PM)"] || ''}</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="2">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                </tr>
                                <tr>
                                    <th colSpan="13" style={{ textAlign: 'center' }}>Income Assessement</th>
                                </tr>
                                <tr>
                                    <td colSpan="13">{storedScoreData["Guarantor's Income Assessement"] || ''}</td>
                                </tr>

                                <tr>
                                    <th colSpan="13" style={{ textAlign: 'center' }}>Route MAP</th>
                                </tr>
                                <tr>
                                    <td colSpan="13">{storedScoreData["Route MAP"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1" rowSpan="2">Final Recommendation by FI Manager</td>
                                    <td colSpan="1">IRR</td>
                                    <td colSpan="1">{storedScoreData.IRR || ''}</td>
                                    <td colSpan="1">PF</td>
                                    <td colSpan="1">{storedScoreData.PF || ''}</td>
                                    <td colSpan="1">TENURE</td>
                                    <td colSpan="1">{storedScoreData.TENURE || ''}</td>
                                    <td colSpan="1">AMT</td>
                                    <td colSpan="1">{storedScoreData.AMT || ''}</td>
                                    <td colSpan="1">Employee Name</td>
                                    <td colSpan="3">{storedScoreData["Employee Name"] || ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="1">&nbsp;</td>
                                    <td colSpan="3">&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>

                </div>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return {
        usersData: state.usersData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(userActions.default, leadActions.default), dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadChecklistDynamicScore);
